@keyframes appearSuccess {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.icon {
  position: relative;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  cursor: copy;
}

.successCheckAnimation {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-name: appearSuccess;
}

.checkIcon {
  opacity: 0;
  position: absolute;
  top: 0px;
  right: -20px;
  display: inline-block;
  transform: rotate(43deg);
  height: 14px;
  width: 8px;
  border-bottom: 4px solid #765ee3;
  border-right: 4px solid #765ee3;
}

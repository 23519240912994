.emptyList {
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  padding: 40px 10px 250px;
  overflow-y: auto;
}
.emptyListContent {
  border-radius: 5px;
  background: #fefefe;
  width: 360px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.12);
}
.emptyListHeader {
  text-align: center;
  padding: 30px;
  color: #999;
  font-size: 1.2rem;
}
.emptyListIcon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  padding: 40px 0 20px;
  height: 380px;
}
.emptyListIcon img {
  height: 320px;
  min-width: fit-content;
  position: absolute;
  right: -50px;
}
.emptyListVariantB {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
}
.emptyListVariantB img {
  width: 180px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.emptyListVariantB h4 {
  font-size: 1.3rem;
}

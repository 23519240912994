.listWrapper {
  padding: 15px;
  overflow: auto;
  height: calc(100vh - 70px);
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}
.listGroup {
  position: relative;
}
.listGroup:before {
  content: "";
  position: absolute;
  z-index: 0;
  width: 8px;
  top: 10px;
  bottom: 0;
  left: 4px;
  background: #dde5e9;
  z-index: -2;
}

.pageMainContainer {
  display: flex;
  height: 100vh;
}

.navLeft {
  display: flex;
  width: 300px;
  height: 100vh;
  max-height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  background: #242227;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.2);
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}

.navLeftClosed {
  width: 60px;
}
.navLeftClosed .logoSection {
  padding: 8px 10px;
}
.navLeftClosed .moduleName,
.navLeftClosed .linkName,
.navLeftClosed .logoSection .logo {
  display: none;
}
.navLeftClosed .logoSection,
.navLeftClosed .moduleSection,
.navLeftClosed .link {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 0;
  align-self: stretch;
}

.navLeftInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.navLeftHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
  font-family: "Sofia Sans", sans-serif;
}

.logoSection {
  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  min-height: 42px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3);
}
.logoSection .logo {
  display: flex;
  height: 26px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.logoSection .logo img {
  display: flex;
  width: 54px;
  align-items: center;
  gap: 6px;
}

.moduleSection {
  display: flex;
  padding: 8px 10px;
  align-items: center;
  align-self: stretch;
  gap: 12px;
}

.moduleIconTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.moduleIcon {
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}
.moduleName {
  color: #1a1a1a;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.navLeftBody {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding-bottom: 30px;
  padding-top: 10px;
}

.filterDrawer {
  position: fixed;
  z-index: 5;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #242227;
  width: var(--filter-drawer-width);
  min-width: var(--filter-drawer-width);
  max-width: var(--filter-drawer-width);
}

.filtersBody {
  width: 100%;
  margin-top: 5px;
}

.filtersBodyTitle {
  padding: 6px 15px;
  padding-bottom: 0;
  padding-top: 10px;
}

.startViewDropdown {
  position: absolute;
  z-index: 1;
  top: 0px;
  right: -65px;
  border-radius: 8px;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}

.startViewBtn {
  border-radius: 0;
  font-family: "Sofia Sans", sans-serif;
}

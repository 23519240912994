.callStatus {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15) transparent;
  background: transparent;
  overflow: hidden;
}

.callStatusSmall {
  height: 28px;
  font-size: 14px;
  border: 1px solid transparent;
}

.callStatus:hover {
  border: 1px rgba(0, 0, 0, 0.15) solid;
}

.dateBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  gap: 10px;
  width: 100%;
}
.dateColumn {
  padding: 4px 10px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.03);
  width: 540px;
  min-width: 540px;
  gap: 10px;
}
.dateColumn span {
  color: #808080;
}
.dateColumnHoliday {
  background: rgba(133, 106, 254, 0.15);
}
.dateColumnHoliday strong {
  color: #856afe;
}
.dateColumnSaturday {
  background: rgba(213, 126, 1, 0.15);
}
.dateColumnSaturday strong {
  color: #d57e01;
}
.dateColumnSunday {
  background: rgba(232, 112, 118, 0.15);
}
.dateColumnSunday strong {
  color: #e87076;
}

.dateName {
  color: #808080;
  font-size: 12px;
  letter-spacing: normal;
}

.separator {
  display: inline-flex;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 4px;
}
.date {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #000;
}

.modalContainer {
  width: max-content;
  min-width: 480px;
  background: #fff;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  max-height: 90vh;
  overflow: scroll;
  padding: 0;
}
.modalContainer .inputSmall {
  max-width: unset;
  width: 130px;
}
.modalContainer .list {
  height: unset;
}
.modalContainer * {
  letter-spacing: -0.03rem;
  font-family: "Sofia Sans";
}
.modalContainer h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.wrapper {
  overflow-x: auto;
  box-shadow: 0px 32px 32px -12px #00000040;
  background-color: var(--neutralBlack100);
  max-height: var(--filter-bar-height);
  min-height: var(--filter-bar-height);
  height: var(--filter-bar-height);
}

.border {
  height: 38px;
  width: 1px;
  min-width: 1px;
  display: flex;
  background-color: var(--neutralWhite16);
}

.deleteButton {
  width: 14px !important;
  height: 14px !important;
}

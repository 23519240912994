.tableRowWHNearestUnloading,
.tableHeaderWHNearestUnloading {
  grid-template-columns: 20px minmax(110px, 130px) minmax(80px, 1fr) 40px 50px 95px 75px;
  font-size: 12px;
  width: fit-content;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
  justify-content: unset;
}

.signature {
  min-width: 95px;
}

.avatarCover {
  margin-right: -4px;
}

.wrapper {
  position: relative;
  border-radius: 4px;
  padding: 4px 5px;
  background: transparent;
  border: 1px solid #ffffff26;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.inputWrapper {
  position: relative;
  flex: 1;
  border-radius: 4px;
  height: 28px;
  background: transparent;
  width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
}

.inputWrapper img {
  height: 16px;
  width: 16px;
}

.input {
  background: transparent;
  width: 100%;
  height: 100%;
  font-size: 14px;
  outline: none;
  border: none;
  color: #fff;
}

.input:focus .inputWrapper {
  border: 1px solid #8c73ff;
  outline: none;
  color: #fff;
}

.input:focus::placeholder {
  color: #fff;
  opacity: 1;
}

.list {
  position: absolute;
  top: 38px;
  overflow: auto;
  max-height: 200px;
  width: 100%;
  border-radius: 4px;
  padding: 10px 0;
  z-index: 99;
  background-color: #fff;
}
.hidden {
  display: none;
}

.listItem:hover {
  background: #0000000d;
}

.selected {
  background: #0000001a;
}

.listItem {
  padding: 5px 15px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
}

.selectedItem:hover {
  opacity: 0.8;
}

.selectedItem:last-child {
  margin-bottom: 8px;
}
.selectedItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px 1px 2px 4px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-size: 13px;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 4px;
  margin-right: 4px;
}

.selectedItem img {
  margin-left: 4px;
  height: 16px;
  width: 16px;
}

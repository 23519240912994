.tableHeaderRoutePoint {
  grid-template-columns: 15px 1.5fr 100px 1fr 75px 95px 34px 60px;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
  height: 30px;
  display: grid;
  align-items: flex-end;
}

.tableRowRoutePoint {
  grid-template-columns: 15px 1.5fr 100px 1fr 75px 95px 34px 60px;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
  display: grid;
}

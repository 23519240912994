.scrollableContent {
  height: calc(100vh - 41px);
  overflow: scroll;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
  margin-bottom: 1rem;
}

.emptyList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 320px;
}
.emptyList .icon {
  height: 160px;
}
.emptyList .body14 {
  max-width: 600px;
  line-height: 18px;
}

.emptyListTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01rem;
  margin: 0;
  color: rgb(33, 37, 41);
}

.actionBtn {
  height: 26px;
}

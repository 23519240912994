.tooltip {
  max-width: 331px;
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  text-transform: none;
  z-index: 30;
  gap: 6px;
  font-family: "Sofia Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  box-shadow: 0px 6px 16px -6px rgba(17, 12, 34, 0.1);
}

.light {
  background: var(--neutralBlack88);
  color: var(--neutralWhite100);
}

.dark {
  background: var(--neutralWhite100);
  color: var(--neutralBlack100);
}

.lightArrow {
  fill: var(--neutralBlack100);
}

.darkArrow svg {
  fill: var(--neutralWhite100);
}

.alreadyOrdered {
  background-image: linear-gradient(
    -45deg,
    var(--neutralBlack4) 5.56%,
    var(--neutralWhite100) 5.56%,
    var(--neutralWhite100) 50%,
    var(--neutralBlack4) 50%,
    var(--neutralBlack4) 55.56%,
    var(--neutralWhite100) 55.56%,
    var(--neutralWhite100) 100%
  );
  background-size: 12.73px 12.73px;
  opacity: 0.6;
}

.disabledManufacturerSelect {
  background: var(--neutralWhite100);
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f6f8f9;
  min-height: 100vh;
  position: relative;
  font-family: "Cabin", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
}

.loaderContent {
  width: 100%;
  max-width: 420px;
  padding: 0 20px;
}

.firmLogo .logoImage {
  display: block;
  margin: 0 auto;
}

.loadingBar {
  margin-top: 50px;
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

@keyframes bar-effect {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(344px);
  }
  100% {
    transform: translateX(0);
  }
}

.loaderContent .bar {
  position: relative;
  width: 42px;
  height: 3px;
  background-color: #5c10c8;
  padding: 0 20px;
  animation: bar-effect 2s linear 0s infinite normal;
}

.loadingText p {
  font-size: 12px;
  line-height: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase;
  margin-top: 10px !important;
  text-align: center;
  color: #9b9b9b;
}

.loadingText h4 {
  font-size: 22px;
  line-height: 18px !important;
  margin: 28px auto 10px !important;

  font-weight: 500 !important;
  text-align: center;
  color: #555;
}

.prioritizedList {
  max-height: 390px;
}

.prioritizedListRow {
  margin-bottom: 2px;
}

.criticalUnit {
  border: 1px solid var(--danger600) !important;
}

.activeUnit {
  background-color: var(--deepPurple75);
}

.lastOpenedUnit {
  background-color: var(--blue25);
}

.previousStageIcon {
  background: var(--neutralWhite48);
  border-radius: 7px;
  gap: 10px;
  height: 12px;
  width: 12px;
}

.listWrapper {
  display: flex;
  flex: 1;
  overflow: auto;
}

.listBox {
  display: flex;
  min-height: min-content;
  overflow-y: scroll;
}

.criticalRow {
  box-shadow: 0px 2px 4px -2px #0000001f;
  box-shadow: 0px 0px 0px 1px #f03d3d inset;
}

.complaintRow {
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.12);
  background: var(--magenta12);
}

.modalFilterSection {
  max-height: 46px;
}

.manufacturerSelect {
  width: 140px;
  height: 26px;
}

.modalManufacturerSelect {
  width: 150px;
  height: 26px;
}

.inputTextLabel {
  font-size: 11px;
  padding: 1px 2px;
}

.manufacturerTextLabel {
  font-size: 11px
}

.modalInputTextLabel {
  font-size: 11px;
  padding: 0px 2px;
}

.paginationFormSize {
  height: 28px;
  font-size: 14px;
  max-width: 60px;
}

.modalPaginationFormSize {
  height: 28px;
  font-size: 14px;
  max-width: 70px;
}

.packageFormSize {
  height: 28px;
  font-size: 14px;
  width: 120px;
}

.modalPackageFormSize {
  height: 28px;
  font-size: 14px;
  width: 130px;
}

.monthsSubtitle {
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: normal;
}

.searchBtn {
  min-height: 26px;
  max-height: 26px;
}

.labels {
  margin-bottom: 0px;
  margin-right: 3px;
  font-size: 12px;
}

.modalLabels {
  margin-bottom: 0px;
  margin-right: 3px;
  font-size: 12px;
  cursor: not-allowed;
}

.radioMark {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 2px 6px;
}

.content-wrapper {
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
  height: 100%;
}
.map {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.details-panel {
  position: absolute;
  width: 380px;
  max-height: 100vh;
  margin: 1rem;
  background: #fefefe;
  border-radius: 8px;
  box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.03), 0px 3px 5px 2px rgba(0, 0, 0, 0.06);
  overflow: auto;
}
.status {
  display: inline-flex;
  width: fit-content;
  width: -moz-fit-content;
  padding: 2px 5px;
  border-radius: 4px;
  background: #01c9d5;
  color: #fff;
  font-weight: 400;
  font-size: 0.85rem;
}
.logo {
  background: #eee;
}
.map-point-cloud {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 470px;
  background: #fff;
}
h1 {
  font-size: 1.3rem;
  color: #333;
  margin-left: 28px;
  margin-bottom: 22px;
}
.data-value {
  margin-left: 28px;
  font-size: 1rem;
  color: #333;
}
.group-label {
  color: #808080;
  font-weight: 500;
  font-size: 0.75rem;
}
.group-label span {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .map {
    justify-content: flex-end;
  }
  .details-panel {
    width: 100%;
    max-height: 75vh;
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .map {
    justify-content: flex-end;
  }
  .details-panel {
    width: 320px;
    max-height: 70vh;
    margin: 0;
  }
}

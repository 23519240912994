.item {
  padding: 0;
  width: fit-content;
  border-radius: 50%;
  margin: 10px;
  background: #dedede;
}
.item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.item img {
  width: 54px;
  height: 54px;
  white-space: nowrap;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #f9f9f9;
}
.item input:checked ~ img {
  box-shadow: 0 0 0 2px #f9f9f9, 0 0 0 4px #765ee3;
}

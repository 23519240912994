.container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.titleWrapper {
  margin: 20px 15px;
}
.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #000000;
}

.header {
  background: #f5f5f5;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0;
  margin-right: 10px;
  background-color: #856afe;
}

.name {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.signature {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 5.5px 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #333333;
}

.emailList {
  overflow: auto;
  padding: 20px 15px 20px 15px;
  flex-grow: 1;
}

/* EmailMessage */
.item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.reversed {
  display: flex;
  align-items: flex-end;
}

.reversed .msgNameInfo {
  display: flex;
  flex-direction: row-reverse;
}

.reversed .avatar {
  margin-right: 0;
  margin-left: 10px;
}

.reversed .content {
  display: flex;
  flex-direction: column;
}
.reversed .body {
  border-radius: 15px 15px 2px 15px;
  background: #f6f8f9;
}
.reversed .msgNameInfo {
  display: flex;
  align-items: center;
}
.content {
  max-width: 100%;
}

.date {
  line-height: 16px;
  margin-bottom: 6px;
  color: #808080;
  display: flex;
  justify-content: space-between;
}

.readStatus {
  font-family: "Cabin", monospace;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
}
.readStatusText {
  color: #01c9d5;
}

.sentStatus {
  display: flex;
  justify-content: flex-end;
}
.sentDate {
  font-family: "Cabin", monospace;
  font-weight: 700;
  font-size: 12px;
}
.expandBtn {
  font-size: 12px;
  font-weight: 700;
}

.error .date span {
  color: #e87076;
}
.error .body {
  background: rgba(232, 112, 118, 0.35);
  color: #fff;
}

.errorMsg {
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  padding: 4px;
  color: #ce656b;
  background: rgba(232, 112, 118, 0.35);
  border-radius: 4px;
  margin-bottom: 6px;
}
.errorMsg img {
  margin-right: 6px;
}

.error .errorMsg {
  display: inline-flex;
}

.inputIcon {
  cursor: pointer;
}
.msgNameInfo {
  display: flex;
  align-items: center;
}

.msgName {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  color: #333333;
}

.emailAddress {
  color: #808080;
  font-family: "Cabin";
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.body {
  background: #f6f8f9;
  border-radius: 15px 15px 15px 2px;
  padding: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 6px;
  max-width: 100%;
}

.noContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}
.noContent div {
  font-size: 16px;
  line-height: 19px;

  color: #808080;
}
.noContent img {
  margin-bottom: 10px;
}

/* Collapse email body */
.bodyCollapse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collaborators {
  display: flex;
  align-items: flex-start !important;
}

.suppliers {
  max-width: 400px;
}

.packageOrderCell {
  width: 200px;
}

.finishedItem {
  border: 1px solid var(--success400);
}

.inProgressItem {
  border: 1px solid var(--yellow400);
}

.notFinishedItem {
  border: 1px solid var(--danger400);
}

.datePickerInput {
  max-height: 28px;
  font-weight: 700;
  max-width: 134px;
}

.timePickerInput {
  max-height: 28px;
}

.datePickerPopup {
  z-index: 22;
}

.timeLabelContainer {
  width: 140px;
}

.collaborators {
  display: flex;
  align-items: flex-start !important;
}

.changeMany {
  position: absolute;
  z-index: 25;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 4px;
  background: #020202;
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  letter-spacing: -0.03rem;
  animation: slideInUp 0.5s forwards;
}
.changeMany img {
  filter: invert(1);
}

.changeMany button {
  border-radius: 4px;
}

.changeMany button:hover {
  border: 1px solid var(--white-15, rgba(255, 255, 255, 0.15));
}

@media (max-width: 1500px) {
  .changeMany {
    left: 35.3%;
  }
}
@media (min-width: 1510px) {
  .changeMany {
    left: 37.2%;
  }
}
@media (min-width: 1920px) {
  .changeMany {
    left: 40.3%;
  }
}

@keyframes slideInUp {
  from {
    transform: translate(0, 250%);
    visibility: visible;
  }

  to {
    transform: translate(0, 0);
  }
}

.slideInUp {
  animation: slideInUp 0.5s forwards;
}

@keyframes slideOutUp {
  from {
    transform: translate(0, 0);
  }

  to {
    visibility: hidden;
    transform: translate(0, 250%);
  }
}

.slideOutUp {
  animation: slideOutUp 0.5s forwards;
}

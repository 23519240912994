.shelfContainer {
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  height: fit-content;
  min-height: 53px;
  max-height: calc(100vh - 64px);
  width: calc(100vw - 50px);
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 0px 0px;
  font-family: 'Sofia Sans', sans-serif;
  letter-spacing: -0.48px;
  resize: none;
}
.shelfHeader {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 53px;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.shelfContainer:hover:after,
.shelfContainer:hover:before {
  display: block;
  cursor: move;
}

.resize {
  width: 100%;
  height: 4px;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
}
.resize:hover {
  cursor: ns-resize;
}

.separator {
  display: block;
  width: 1px;
  background: #ddd;
  height: 44px;
  margin: 0 10px;
}

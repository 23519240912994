.label {
  font-size: 0.8rem;
  color: #757575;
}
.table {
  margin: 0 0 15px;
}
.summaryTable .tableRow {
  display: grid;
}
.tableBody {
  max-height: 40vh;
  overflow: auto;
}
.summaryTable .tableBody,
.summaryTable .tableRow {
  grid-template-areas: "a a a a";
  grid-auto-columns: minmax(max-content, 1.5fr) minmax(90px, 1fr) minmax(90px, 1fr)
    minmax(90px, 1fr);
}
.table h5 {
  font-size: 0.9rem;
}
.tableRow {
  padding: 12px 0;
  border-top: 1px solid #ddd;
}
.tableCell {
  display: flex;
  align-items: center;
}
.name {
  font-size: 1.1rem;
}
.name img {
  width: 22px;
  height: 22px;
}
.name span {
  color: #808080;
  font-size: 0.85rem;
}

.container {
  max-height: 60vh;
  min-height: 200px;
}
.tableHeader {
  margin-bottom: 5px;
  border-top: none;
}

.date {
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 38px;
  border: 1px solid #ddd;
  margin: 0 0 20px;
  padding: 0 0 0 5px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: listbox;
  width: 140px;
}
.date:focus {
  box-shadow: #765ee3 0px 0px 0px 1px;
  outline: none;
}
.dateLabel {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 12px;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  background: #fff;
  z-index: 1;
}

.accountLabel {
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: fit-content;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 3px;
  margin-left: 6px;
  margin-bottom: 6px;
  cursor: pointer;
  background: #ddd;
  color: #333;
}
.accountLabelBox input {
  position: absolute;
  visibility: hidden;
}
.accountLabelBox input:checked ~ .accountLabel {
  background: #5b3fdd;
  color: #fff;
}

.datePickerPopup {
  z-index: 22;
}

.package {
  width: 236px;
  border: 1px solid var(--neutralBlack12);
  border-radius: 4px;
}

.wrapper {
  max-height: 62vh;
  overflow: auto;
}

.level {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border: 1px solid rgb(0 0 0 / 12%);
  font-size: 14px;
  box-sizing: border-box;
}

.levelPointed {
  background: var(--neutralBlack100);
  color: var(--neutralWhite100);
}

.rackLevel {
  position: relative;
  z-index: 1;
  width: 200px;
  opacity: 0.75;
}
.rackLevel.onClick {
  z-index: 2;
  opacity: 1;
}
.rackLevel.onClick .rackCell {
  border-color: #000;
  box-shadow: inset 0 0 0 2px #000;
  font-weight: 700;
  font-size: 18px;
}
.rackLevelsInactive {
  background-color: var(--neutralBlack-12, rgba(0, 0, 0, 0.12));
}

.rackCell {
  width: 100%;
  padding: 10px 16px;
  height: 44px;
  border: 1px solid rgb(0 0 0 / 12%);
  box-sizing: border-box;
  color: #000;
}
.rackCell:hover {
  background: var(--neutralWhite-24, rgba(255, 255, 255, 0.24));
  z-index: 2;
  opacity: 1;
  cursor: pointer;
}
.rackLevelsInactive .rackCell:hover {
  background: none;
  cursor: not-allowed;
}

.emptyFill {
  width: 244px;
}

.shelfSchemeInactive,
.rackLevelsInactive {
  opacity: 0.5;
}
.rackLevelsInactive .rackCell:hover {
  background: none;
  cursor: not-allowed;
}

.modal {
  min-height: 30vh;
  padding: 15px;
  max-width: 30vw;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.today {
  width: 100%;
  background: #01C9D559;
  border-radius: 8px;
  padding: 5px;
  font-size: 1.25rem;
  color: #009099;
  cursor: pointer;
}

.todayActive {
  border: 1px solid #009099;
  background: #01979f59;
}

.todayInPlan {
  border: 1px solid #009099;
  background: #01979f59;
  cursor: not-allowed;
}

.submitBtnContainer {
  border-top: 1px solid #ddd;
}

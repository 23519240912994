.container {
  height: 28px;
}
.date {
  border-radius: 4px;
  height: 28px;
  padding: 4px 5px;
  background: transparent;
  cursor: pointer;
  border: 1px solid #ffffff26;
  width: 100%;
  font-size: 14px;
  color: #fff;
}
.date:hover {
  border: 1px solid #ffffff26;
}
.date:focus {
  border: 1px solid #8c73ff;
  outline: none;
  color: #fff;
}
.date:focus::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}

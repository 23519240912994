.rejected {
  background: var(--warning25);
}

.confirmed {
  background: var(--success25);
}

.selected {
  background-color: #edf0f9;
}

.datePickerInput {
  max-height: 28px;
  font-weight: 700;
  font-size: 14px;
  max-width: 134px;
}
.datePickerPopup {
  z-index: 22;
}
.equalWithDeparture {
  color: var(--warning500);
}
.departureEarlierThanPickup {
  color: var(--danger500);
}

.card {
  background: var(--grey800) !important;
  border: 1px solid var(--grey800);
  border-radius: 8px;
  box-shadow: 0px 18px 45px 0px #00000033;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
  width: 430px;
}

.cardTitle {
  border-bottom: 1px solid var(--grey800);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  height: 40px;
  padding: 16px 16px 0px 16px;
}

.cardBody {
  border-radius: 0px 0px 8px 8px;
  max-height: 210px;
  padding: 8px 0px;
  overflow: auto;
}

.darkRow {
  background: var(--grey800) !important;
  border: 1px solid var(--neutralWhite16);
}

.darkHeader {
  background: var(--grey800) !important;
}

.noWrap {
  flex-wrap: nowrap !important;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.noWrap::-webkit-scrollbar {
  display: none;
}

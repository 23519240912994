.searchBar {
  display: flex;
  align-items: center;
  min-height: 32px;
  position: relative;
  flex-wrap: nowrap;
}
.search {
  font-size: 1rem;
  position: relative;
  z-index: 1;
  box-shadow: none;
  height: 32px;
  transition: all 0s ease-in-out;
  padding: 0 5px 0 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: transparent;
  width: 100%;
  min-width: 280px;
}
.search:focus {
  outline: 0;
  border-color: #765ee3;
}

.search::-webkit-input-placeholder {
  font-size: 1rem;
}
.search:-moz-placeholder {
  font-size: 1rem;
}
.search::-moz-placeholder {
  font-size: 1rem;
}
.search:-ms-input-placeholder {
  font-size: 1rem;
}
.btnSearch {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 0 20px 20px 0;
  background: rgba(0, 0, 0, 0.06);
  align-items: center;
  justify-content: center;
  position: relative;
  right: 0;
  z-index: 2;
  border: 0;
  display: inline-flex;
}
.btnSearch img {
  margin: 0 4px;
}
.searchSuggestionList {
  position: absolute;
  width: 100%;
  border-radius: 0 0 5px 5px;
  background: #fff;
  top: 100%;
  z-index: 20;
  /* border-top: 1px solid #efeeec; */
  max-height: 288px;
  overflow-y: auto;
}
.searchSuggestionItem {
  position: relative;
  cursor: pointer;
  padding: 8px 14px;
  font-size: 1rem;
  z-index: 11;
}
.searchSuggestionItem:hover {
  background: #eee;
}
.searchSuggestionItem span {
  color: #777;
}

.chip {
  display: inline-flex;
  align-items: center;
  padding: 0 2px 0 12px;
  border-radius: 20px;
  border: 1px solid #765ee3;
  width: fit-content;
  width: -moz-fit-content;
  color: #333;
  margin-right: 4px;
  margin-bottom: 4px;
}
.chip span {
  font-size: 0.81rem;
  color: #808080;
  margin-right: 0.5rem;
}
.chipImage {
  height: 36px;
  min-width: 36px;
  width: 36px;
  border-radius: 4px;
  border: 1px solid #ddd;
  overflow: hidden;
  display: inline-flex;
  margin-right: 0.5rem;
}
.chipImage img {
  display: block;
  max-width: 100%;
}
input[type="search"]::-moz-clear,
input[type="search"]::-moz-clear {
  opacity: 0.0001;
}
input[type="search"]::-webkit-clear-button,
input[type="search"]::-webkit-clear-button {
  opacity: 0.0001;
}
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
input[type="search"]::-moz-clear {
  display: none;
}

.menuInvisible {
  display: none;
}

.menuShadow {
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
}

.content,
.wrapper {
  height: 100vh;
  background-color: #11181f;
}
.content h1 {
  font-size: 1.9rem;
  color: #fdfdfd;
}
.content h2 {
  font-size: 1.2rem;
  color: #765ee3;
}

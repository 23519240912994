.option {
  display: block;
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.95rem;
  user-select: none;
  border-radius: 4px;
}
.input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.input {
  width: auto;
}
.option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.radioMark {
  margin-right: 10px;
  position: relative;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 3px;
  background: #ffffff26;
}
.radioMark {
  border-radius: 50%;
}
.radioMark:after {
  content: "";
  position: absolute;
  display: none;
}
.option input:checked + div .radioMark {
  background-color: #8c73ff;
}

.option:hover input + div .radioMark {
  background-color: #97adff;
}
.option input:checked + div .radioMark:after {
  display: block;
}
.option--inactive input:checked + div .radioMark,
.option--inactive .radioMark,
.option--inactive,
.option--inactive:hover,
.option--inactive:focus {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent;
}
.option .radioMark:after {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
}
.radioMark:after {
  content: "";
  position: absolute;
  display: none;
}
.label {
  font-size: 14px;
  font-weight: 700;
  color: #bdbdbd;
}
.option input:checked + div .label {
  color: #fff;
}

.labelInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.labelInput:checked ~ .labelRadiomark {
  background: #8c73ff;
  color: #fff;
}

.labelRadiomark:hover {
  opacity: 0.8;
}

.labelRadiomark {
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 4px;
  font-size: 10px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  background: #ffffff1a;
  color: #fff;
  position: static;
}

.labelOption {
  position: relative;
  cursor: pointer;
  margin-bottom: 4px;
}

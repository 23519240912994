.avatarCover {
  margin-right: -4px;
}

.progressBarContainer {
  width: 140px;
}

.active {
  background: rgba(94, 124, 213, 0.3);
}

.lastOpened {
  background-color: #a1a1a11c;
}
.selected {
  background-color: #edf0f9;
}

.datePicker {
  z-index: 52;
}

.cancelledRow {
  background-image: linear-gradient(
    -45deg,
    var(--warning32) 5.56%,
    var(--neutralWhite100) 5.56%,
    var(--neutralWhite100) 50%,
    var(--warning32) 50%,
    var(--warning32) 55.56%,
    var(--neutralWhite100) 55.56%,
    var(--neutralWhite100) 100%
  );
  background-size: 12.73px 12.73px;
  opacity: 0.8;
}

.date {
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  border: 1px solid #ddd;
  margin: 0 0 20px;
  padding: 0 0 0 5px;
  background: transparent;
  cursor: pointer;
  appearance: listbox;
  width: 100%;
}

.date:focus {
  box-shadow: #765ee3 0px 0px 0px 1px;
  outline: none;
}

.changeDeliveryDateBtn {
  width: 100%;
}

.executionStatusContainer {
  min-width: 150px;
}

.courierLogo {
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.courierLogoWrapper {
  display: flex;
  align-items: center;
  padding: 6px 0;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
}

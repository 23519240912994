.tooltip {
  display: none;
  position: absolute !important;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  min-width: max-content;
  max-width: 350px;
  padding: 3px;
  background: #1a1a1a;
  border-radius: 5px;
  color: #fff;
  font-size: 0.75rem;
  text-align: center;
  text-transform: none !important;
}

.hoverable:hover .tooltip {
  display: flex;
}

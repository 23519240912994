.container {
  position: relative;
  padding: 3px 0;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  margin-right: 2px;
  z-index: 5;
}

.optionsList {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 0 0 5px 5px;
  overflow-y: auto;
  display: none;
  width: 280px;
}
.optionsItem {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  font-size: 0.81rem;
  color: #343f40;
  width: 100%;
  text-align: left;
}

.optionsItem:hover {
  background: #ddd;
  cursor: pointer;
}

.imgContainer {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}
.imgContainer img {
  width: 16px;
  height: 16px;
}

.label {
  padding: 10px 15px 0 15px;
  margin-bottom: 10px;
}
.openButton {
  border: 1px solid transparent;
  padding: 3px 2px;
  margin-right: 10px;
}
.openButtonActive {
  border: 1px solid #765ee3;
  border-radius: 4px;
}
.openButtonActive .car {
  background: #765ee3;
}

.openButtonActive .car img {
  filter: contrast(0%) brightness(200%);
}

.openButton:hover {
  border: 1px solid #765ee3;
  border-radius: 4px;
}

.openButton:hover .car {
  background: #765ee3;
}

.openButton:hover .car img {
  filter: contrast(0%) brightness(200%);
}

.arrow {
  transform: rotate(90deg);
}

.car {
  background: #ddd;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.car img {
  width: 16px;
  height: 16px;
}
.disabled {
  cursor: not-allowed;
}

.inputBox {
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
}

.editIcon {
  display: inline-flex;
  visibility: hidden;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}

.inputBox:hover .editIcon {
  visibility: visible;
}

:global(.disabled):hover .editIcon {
  visibility: hidden;
}

.modal {
  padding: 15px;
  max-width: 50vw;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.buttonBox {
  gap: 8px;
}

.optionBox {
  gap: 8px;
}

.option {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 4px;
  gap: 4px;
  cursor: pointer;
}

.optionActive {
  border: 2px solid #765ee3;
}

.removeLabelIcon {
  margin-bottom: 2px;
}

.divider {
  width: 4px;
  height: 4px;
  background: #9a9a9a;
  border-radius: 4px;
  margin: 0 5px;
}

.selectHeight {
  height: 38px;
  margin-bottom: 5px;
  margin-left: 10px;
}

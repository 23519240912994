.modalContainer {
  max-width: max-content;
  width: 690px;
  min-width: 690px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  max-height: 95vh;
  overflow: scroll;
  margin: 2.5vh auto;
}

.modalContainer .scrollableList {
  max-height: 700px;
  overflow: scroll;
}

.reception {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.reception:hover {
  border-color: var(--Deep-Purple-5, #8c73ff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.receptionSelected {
  background: var(--Deep-Purple-1, #e7e1ff);
}

.tableHeaderPZ {
  grid-template-columns: 15px 2fr 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 20px;
  font-size: 12px;
  color: #808080;
}

.tableRowPZ {
  grid-template-columns: 15px 2fr 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 700;
}

.stripesBgOrange {
  background-image: linear-gradient(
    45deg,
    #f8eae7 5.56%,
    #ffffff 5.56%,
    #ffffff 50%,
    #f8eae7 50%,
    #f8eae7 55.56%,
    #ffffff 55.56%,
    #ffffff 100%
  );
  background-size: 12.73px 12.73px;
}

.inaccessible {
  opacity: 0.7;
}
.inaccessible:hover {
  background: inherit;
  cursor: not-allowed;
}

.rowHighlight {
  background: var(--Lime-2, #f1f3c8) !important;
}

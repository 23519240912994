.container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input {
  display: none;
  opacity: 0;
  cursor: pointer;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  min-height: 19px;
  height: 100%;
}

.checkMark {
  color: #333;
  border: 1px solid var(--black-5, rgba(0, 0, 0, 0.15));
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  position: relative;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  width: 20px;
  border-radius: 3px;
}
.checkMark:hover {
  background: var(--Teal-2, #bbdedb);
}
.checkMark[disabled]:hover {
  background: var(--teal-4, #8fe1c3);
}

.checkMarkActive {
  background: #6de5b9;
}
.active {
  top: -20px;
}
.label {
  font-size: 0.9rem;
  margin-left: 8px;
  cursor: pointer;
}
.disabled .checkMark:hover {
  cursor: not-allowed;
}

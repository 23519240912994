.carouselContainer {
  position: relative;
  max-width: 760px;
  transition: all 0.3s ease;
  align-items: flex-end;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
}

.tabWrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.tabContainer {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  transition: all 0.3s ease;
  align-items: flex-end;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  margin: 0;
}

.tab {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  border-bottom: 2px solid transparent;
  color: var(--black-35, rgba(0, 0, 0, 0.5));
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
.tab:hover {
  color: #333;
  border-bottom: 2px solid #808080;
}
.tabActive {
  border-bottom: 2px solid var(--black, #000);
  color: var(--black, #000);
  font-weight: 700;
}

.carouselButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: var(--grey25) !important;
  border: none;
  z-index: 10;
}

.leftButton {
  left: 7px;
}

.rightButton {
  right: 2px;
}

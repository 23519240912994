.label {
  display: inline-flex;
  padding: 3px 0px 3px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatarSmall {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}
.avatar img {
  display: block;
  max-width: 100%;
}

.approvalStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
}
.approvalStatus img {
  display: block;
  width: 16px;
  min-width: 16px;
}

.name {
  letter-spacing: -0.03rem;
}

.jumbotron {
  background-color: var(--color-raisin-black);
}
.textarea {
  border: 1px solid #ccc;
}
.backBtn {
  padding: 10px;
  border-radius: 8px;
  background-color: var(--color-dark-charcoal);
  display: inline-flex;
  width: auto;
}
.backBtn:hover {
  background-color: var(--color-eerie-black);
  color: var(--color-white);
}
.backBtn:focus {
  background-color: var(--color-granite-gray);
  color: var(--color-white);
}

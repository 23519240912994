.stageStatusLabel {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 2px 0px 4px;
  font-size: 0.7rem;
  line-height: 0.75rem;
  width: fit-content;
  min-width: fit-content;
  height: 16px;
  border-radius: 4px;
  font-weight: 700;
  overflow: hidden;
  text-transform: uppercase;
  border: none;
}

.tooltip {
  display: none;
  position: absolute !important;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  min-width: max-content;
  max-width: 350px;
  padding: 3px;
  background: #1a1a1a;
  border-radius: 5px;
  color: #fff;
  font-size: 0.75rem;
  text-align: center;
  text-transform: none !important;
}
.showTooltip:hover .tooltip {
  display: flex;
}
.fixedWidth {
  max-width: 75px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 1px;
  width: 100%;
  height: 100%;
}
.mainContentInner {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.narrowLetters {
  letter-spacing: -0.03rem;
}

.option {
  display: flex;
  padding: 5px 15px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
}
.option:hover {
  background: var(--neutralBlack12);
}

.activeOption {
  background: var(--neutralBlack12);
  outline: 0;
}

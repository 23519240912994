.box {
  display: block;
  position: relative;
  padding: 4px 10px 4px 0;
  padding-left: 30px;
  margin-bottom: 0;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.95rem;
  user-select: none;
  border-radius: 4px;
}
.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 3px;
  background: rgba(155, 155, 155, 0.6);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 2px;
  width: 6px;
  height: 13px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.input:checked ~ .checkmark {
  background-color: #5a3fb5;
}
.input:checked ~ .checkmark:after {
  display: block;
}
.label {
  font-size: 0.9rem;
}

.correctionSignature {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.signatureLink {
  color: var(--blue-5, #2b8efa);
  font-size: 12px;
  font-weight: 700;
}
.signatureLink:hover {
  color: var(--blue-5, #2b8efa);
  opacity: 0.8;
}

.date {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #808080;
}

.reason {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

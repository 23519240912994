.emptySection {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tableRowPaymentsReturn {
  background-image: linear-gradient(
    45deg,
    #fbf4d6 5.56%,
    #ffffff 5.56%,
    #ffffff 50%,
    #fbf4d6 50%,
    #fbf4d6 55.56%,
    #ffffff 55.56%,
    #ffffff 100%
  );
  background-size: 12.73px 12.73px;
}

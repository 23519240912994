.rightBtn {
  background: url(~assets/images/18.svg) no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
  transition: box-shadow 200ms;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-width: 200px;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 1rem;
  min-height: 28px;
}
.rightBtn:focus {
  box-shadow: #765ee3 0px 0px 0px 1px;
  outline: none;
}

.sizeRegular {
  height: 44px;
}

.sizeSmall {
  height: 34px;
}

.dropdownList {
  background-color: #fff;
  list-style-type: none;
  overflow: auto;
  position: absolute;
  max-height: 200px;
  z-index: 3; /* incremented to 3 because it was covered by followed input label */
  right: 0;
  left: 0;
  border-radius: 0 0 5px 5px;
  width: 100%;
}
.dropdownList.open {
  padding: 5px 0;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
}
.dropdownListElement {
  padding: 5px 10px;
  font-size: 1rem;
  cursor: default;
}
.selected {
  font-weight: bold;
  opacity: 0.5;
}
.highlighted {
  background-color: #efeeec;
}

.editable {
  border: 1px solid transparent !important;
  background-image: none !important;
}
.editable:hover {
  border: 1px solid #ccc !important;
  background: url(~assets/images/18.svg) no-repeat !important;
  background-position-x: calc(100% - 10px) !important;
  background-position-y: center !important;
}
.placeholder {
  color: #808080;
}
.inputValue {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  max-width: calc(100% - 20px);
}
.inputValueWithClearEnabled {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  max-width: calc(100% - 40px);
}
.label {
  font-size: 12px;
  color: #808080;
  background: #fff;
  display: inline-flex;
  padding: 1px 2px;
  position: absolute;
  left: 5px;
  top: -10px;
  z-index: 1;
  width: fit-content;
  width: moz-fit-content;
}

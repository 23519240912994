.orderDetails {
  width: -webkit-fill-available;
  width: -moz-available;
  min-width: fit-content;
  min-width: -moz-fit-content;
}
.order {
  flex-direction: row;
  align-items: center;
}
.signature {
  font-size: 1.1rem;
  white-space: nowrap;
}
.address,
.client {
  font-size: 0.95rem;
  margin-top: 4px;
  font-weight: 500;
  color: #666;
  line-height: normal;
}
.client {
  color: #444;
}
.client em {
  font-size: 0.81rem;
  color: #808080;
}
.orderDate {
  font-size: 0.75rem;
  color: #808080;
  font-style: italic;
}
.orderDateWeb {
  display: none;
}
.orderDateMobile {
  display: inline-flex;
}
.label {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  height: 20px;
  line-height: 20px;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 0 3px;
  min-width: fit-content;
  color: #eee;
  font-size: 0.75rem;
}
.divider {
  width: 4px;
  height: 4px;
  background: #9a9a9a;
  border-radius: 4px;
  margin: 0 5px;
}
.statuses {
  width: 130px;
  min-width: 130px;
}

.timeCounter {
  width: 68px;
  min-width: 68px;
}
.assignee {
  width: 26px;
  min-width: 26px;
}
.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.09);
  margin: 9px 0;
  overflow: hidden;
}
.avatar img {
  display: block;
  max-width: 100%;
}

.readyMark {
  display: inline-flex;
  position: relative;
  padding: 0;
  padding-left: 30px;
  margin-bottom: 2px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  width: 20px;
  height: 20px;
}

.readyMark input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.readyMarkCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 3px solid #9a9a9a;
  border-radius: 50%;
}

.readyMarkCheckmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 7px;
  top: -4px;
  width: 7px;
  height: 15px;
  border: solid #9a9a9a;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.readyMark input:checked ~ .readyMarkCheckmark:after {
  border-color: #01c9d5;
}
.readyMark input:checked ~ .readyMarkCheckmark {
  border-color: #01c9d5;
}

.indexName {
  max-width: 650px;
}
@media (max-width: 1279px) {
  .orderDelivery {
    max-width: 100%;
  }
  .statuses,
  .timeCounter {
    margin-left: 0;
    margin-top: 4px;
  }
}
@media (min-width: 1280px) {
  .statuses,
  .timeCounter {
    margin-left: 0;
  }
}
@media (min-width: 1280px) and (max-width: 1535px) {
  .orderDelivery {
    max-width: 320px;
  }
  .client,
  .address {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .client {
    -webkit-line-clamp: 1;
  }
}
@media (min-width: 1280px) and (max-width: 1365px) {
  .label {
    font-size: 0.75rem;
  }
  .signature {
    font-size: 0.9rem;
  }
  .orderDelivery {
    max-width: 320px;
  }
  .client,
  .address {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.85rem;
  }
  .client {
    -webkit-line-clamp: 1;
  }
}
@media (min-width: 1366px) and (max-width: 1536px) {
  .orderDelivery {
    max-width: 360px;
  }
}
@media (min-width: 1440px) {
  .indexName {
    max-width: 700px;
  }
  .orderDateWeb {
    display: inline-flex;
  }
  .orderDateMobile {
    display: none;
  }
}
@media (min-width: 1536px) {
  .indexName {
    max-width: 900px;
  }
  .orderDelivery {
    max-width: 100%;
  }
  .address,
  .client {
    font-size: 0.85rem;
  }
  .signature {
    font-size: 0.95rem;
  }
}
@media (min-width: 1920px) {
  .indexName {
    max-width: 1300px;
  }
  .address,
  .client {
    font-size: 0.95rem;
  }
  .label {
    font-size: 0.81rem;
  }
  .signature {
    font-size: 1.1rem;
  }
}
.notSeen {
  border-left: 4px solid #42c9d4;
  background-color: #fff;
}
.notSeen .signature,
.notSeen .client,
.notSeen .client span,
.notSeen .address,
.notSeen .orderDate {
  color: #333;
}
.contact {
  width: 70px;
  min-width: 70px;
}
.contactStatus {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}
.required {
  background: #ec7d84;
}
.waiting {
  background: #efac49;
}
.required img,
.contactStatus img {
  display: block;
  width: 12px;
  filter: brightness(1) contrast(4);
}
.externalServiceStatusDot {
  width: 10px;
  height: 10px;
  background-color: #01c9d5;
  border: 1px solid #fff;
  border-radius: 10px;
  margin: auto;
  margin-left: 3px;
  margin-right: 2px;
}
.routeSignature {
  color: #212529;
  font-size: 1.1rem;
}
.orderGroupName {
  font-size: 0.81rem;
  color: #777;
  text-transform: lowercase;
}
.noTime {
  margin-right: 6px;
  font-size: 0.9rem;
  color: #777;
}
.noData {
  color: #777;
  font-size: 0.9rem;
}
.orderGroupDate {
  color: #212529;
  font-size: 0.9rem;
  font-weight: 700;
}
.paid {
  background-color: #01c9d5;
  padding: 2px;
}
.paid img {
  filter: contrast(0%) brightness(200%);
}
.labelContainer {
  width: 150px;
  max-width: 150px;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.date {
  font-style: italic;
  width: 90px;
  min-width: 90px;
  margin-right: 25px;
  font-size: 0.81rem;
  color: #808080;
}
.productionPlanDate {
  font-size: 0.9rem;
  margin: 0 3px;
}
.productionPlanLabel {
  font-size: 0.95rem;
}

.appearBtn {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  min-width: 26px;
  padding: 4px;
}

.appearBtn img {
  display: block;
  width: 100%;
}

.appearBtn:hover {
  background: rgba(0, 0, 0, 0.05);
}

.iconLabel {
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 4px;
}

.iconLabel img {
  filter: contrast(0%) brightness(200%);
}

.invoiceTitle {
  white-space: nowrap;
  font-size: 0.88rem;
  font-weight: 500;
  color: #666;
  line-height: normal;
}
.invoiceContainer {
  width: 100px;
}
.whiteImage {
  width: 22px;
  height: 22px;
  margin-right: 6px;
}

.whiteImage img {
  filter: contrast(0%) brightness(200%);
}
.iconContainer {
  width: 100px;
}

.plannedProductionDate {
  margin-left: auto;
  display: flex;
  align-items: center;
  width: 110px;
}
.materialSent {
  background-color: #e0b689 !important;
}

.materialReceived {
  background-color: #87decd !important;
}

.materialSent:hover {
  background-color: #d1c2b0 !important;
}

.materialReceived:hover {
  background-color: #b7ddd9 !important;
}

.materialSent:global(.active) {
  background-color: #b7b9c9 !important;
}

.materialReceived:global(.active) {
  background-color: #accadc !important;
}

.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  color: #fff;
  margin: 9px 0;
  overflow: hidden;
  font-size: 0.8rem;
  font-weight: bold;
  background: rgb(162 162 162 / 30%) url(~assets/images/3.svg) no-repeat center;
  backdrop-filter: contrast(0) brightness(1.85);
}
.avatar img {
  display: block;
  max-width: 100%;
}
.notes {
  font-size: 14px;
}
.smsSentContainer,
.deliveryDateContainer {
  width: 250px;
}
.smsContainer {
  display: flex;
  margin: 0 10px;
  align-items: center;
}
.containerWrapped {
  flex-wrap: wrap;
  margin: 5px;
}
.deliveryDateConfirmed {
  padding: 5px;
  border-radius: 4px;
}
.confirmed {
  background: rgba(1, 201, 213, 0.3);
}
.notConfirmed {
  background: rgba(232, 112, 118, 0.3);
}
.receiptSection {
  min-width: 170px;
}

.channelIcon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.isOrderConfirmed {
  width: 150px;
}

.messageToSeller {
  background: var(--deepOrange12);
  max-width: 380px;
}

.messageToSellerText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.courierLogo {
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.courierLogoWrapper {
  border: 2px solid var(--neutralBlack16);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 2px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--neutralWhite100);
}

.counter input {
  height: 26px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 5px;
  text-align: center;
  margin: 0 4px;
  cursor: pointer;
}

.counter input:hover {
  border-color: #ddd;
  outline: 0;
}
.counter input:focus {
  border-color: #765ee3;
  outline: 0;
}
.input3 {
  width: 40px;
}
.input4 {
  width: 50px;
}
.input5 {
  width: 55px;
}

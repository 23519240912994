.logo {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  margin-right: 10px;
}

.bank {
  cursor: pointer;
  padding: 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 5px;
  height: 56px;
  display: flex;
  align-items: center;
}

.bank:hover {
  background: rgba(0, 0, 0, 0.05);
}

.container {
  width: 50%;
  overflow: auto;
  max-height: 69vh;
  min-height: 69vh;
  height: 69vh;
  margin-bottom: 15px;
}

.section {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
  margin: 15px;
  padding: 25px 15px 20px;
  max-width: 1280px;
  height: 100%;
  flex: 1;
}

.radio {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #c4c4c4;
}

.radio-selected {
  background: linear-gradient(0deg, #765ee3, #765ee3), #765ee3;
  position: relative;
}

.radio-selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}

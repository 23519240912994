.container {
  max-width: 75vw;
}

.indexName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.colBackground {
  background-color: rgb(243, 241, 241);
}

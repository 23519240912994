.map-point-cloud {
  min-width: 400px;
  max-width: 550px;
  background: #fff;
  font-family: "Cabin", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4;
  min-height: 400px;
  width: fit-content;
}
.map-point-cloud hr {
  margin: 0;
}
.point-image {
  width: 25px;
  height: 25px;
}
.point-image img {
  display: block;
  width: 100%;
  height: auto;
}
.map-cloud_data-group {
  width: 100%;
}
.map-cloud_group-label {
  color: #808080;
  font-weight: 500;
  font-size: 0.75rem;
  display: flex;
}
.map-cloud_group-label span {
  margin-left: 10px;
}
.map-cloud_group-label-red {
  width: 100%;
  font-weight: 500;
  font-size: 0.75rem;
  display: flex;
  color: #ce656b;
}
.map-cloud_group-label-red span {
  margin-left: 10px;
}

.map-cloud_group-data {
  margin-left: 28px;
  max-width: 400px;
  max-height: 200px;
  overflow-y: auto;
  font-size: 1rem;
  color: #333;
}
.xIcon {
  width: 12px;
}
.hasComment {
  color: #ff6e75;
}
.noComment {
  color: #808080;
}
.usersComment {
  font-size: 0.75rem;
  margin: 2px 0 8px;
}
.usersComment span,
.usersComment strong {
  display: inline-flex;
}
.usersComment span {
  color: #808080;
}
.hasProducedItems {
  font-size: 0.81rem;
  color: mediumturquoise;
  margin-bottom: 10px;
}
.createdDate {
  color: #808080;
}

.attribute {
  padding: 2px 4px 1px;
  border: 1px solid #ddd;
  background-color: #d0d0d6;
  border-radius: 3px;
  margin: 3px 2px 3px 0;
  font-size: 12px;
  display: inline-flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: min-content;
}
.attribute span {
  margin-right: 2px;
  white-space: nowrap;
  font-weight: normal;
  color: #444;
}
.attribute strong {
  font-weight: 700;
  white-space: nowrap;
}
.collectDateText {
  font-size: 0.8rem;
}
.smsNotification {
  height: 24px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  padding: 5px;
}
.smsNotification img {
  filter: contrast(0%) brightness(200%);
}
.signature {
  color: #333;
}
.signature:hover {
  color: #01c9d5;
}

.avatar {
  height: 16px;
  min-width: 16px;
  width: 16px;
  margin: 0;
}

.midAvatar {
  height: 20px;
  min-width: 20px;
  width: 20px;
  margin: 0;
}

.correctConfirmationRange {
  color: var(--Teal-6, #55bca6);
}

.invalidConfirmationRange {
  color: var(--Red-4, #e26b77);
}

.hideRoutePointBtn {
  width: 70px;
}

.changeRoutePointHideStatusBtn {
  max-height: 15px;
}

.hideRoutePointTypography {
  letter-spacing: -0.03rem;
}

.dayCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  width: 540px;
  min-width: 540px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  gap: 10px;
}
.dayCardInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 96px;
  width: 100%;
  height: -webkit-fill-available;
  padding: 5px 10px 0;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.dayCardOff {
  box-shadow: 0px 0px 0px 2px #e8bd0e;
  background: rgba(232, 189, 14, 0.15);
}
.dayCardOff .dayCardInner {
  background: #fffcf1;
}
.dayCardHoliday {
  background: rgba(133, 106, 254, 0.15);
}
.dayCardHoliday .dayCardInner {
  background: #f7f6ff;
}
.dayCardSaturday {
  background: rgba(213, 126, 1, 0.15);
}
.dayCardSaturday .dayCardInner {
  background: #fff7eb;
}
.dayCardSunday {
  background: rgba(232, 112, 118, 0.15);
}
.dayCardSunday .dayCardInner {
  background: #fff9f9;
}
.manufacturingItems {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  overflow: scroll;
  max-height: 450px;
  padding-bottom: 10px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.manufacturingItems::-webkit-scrollbar {
  display: none;
}

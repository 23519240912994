.imageCell {
  width: 68px;
  min-width: 68px;
  height: 68px;
}

.imageCell img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.nameCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start;
}

.seen span {
  color: #808080 !important;
}

.iban {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  display: flex;
  align-items: center;
}

.transaction {
  padding: 8px 15px;
  display: grid;
  grid-template-areas: "a a a a";
  grid-auto-columns: minmax(0, 1.27fr) minmax(0, 1fr) minmax(0, 150px) minmax(0, 200px);
  border-bottom: 1px solid #ccc;
  position: relative;
}

.transaction:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05) !important;
}

.bankAccount img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
}

.status {
  width: 95px;
  display: flex;
  justify-content: flex-end;
}

.moreLabel {
  background: rgba(0, 0, 0, 0.05);
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectBtn {
  max-width: 120px !important;
  min-width: 120px !important;
  border: none;
}
.selectBtn:hover {
  border: 1px solid #ccc;
}

.rotateArrow {
  transform: rotate(180deg);
}

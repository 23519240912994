.mapFilters {
  height: 75vh;
  overflow: auto;
}

.map-points-filters {
  background: #fff;
  box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.03), 0px 3px 5px 2px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 4px;
  width: 40px;
}

.version-box-option {
  font-family: "Cabin", sans-serif;
  display: block;
  position: relative;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.95rem;
  user-select: none;
  padding: 0;
  width: fit-content;
  border-radius: 50%;
  margin: 5px auto;
}

.version-box-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.version-box-option img {
  width: 24px;
  height: 24px;
  white-space: nowrap;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #f9f9f9;
}

.version-box-option input[type="checkbox"]:checked ~ img {
  box-shadow: 0 0 0 2px #f9f9f9, 0 0 0 4px #765ee3;
}

.version-box-option input[type="checkbox"]:checked ~ div {
  box-shadow: 0 0 0 2px #f9f9f9, 0 0 0 4px #765ee3;
}

@media screen and (min-width: 768px) {
  .mapFilters {
    height: 70vh;
  }
}

@media screen and (min-width: 1920px) {
  .mapFilters {
    height: 75vh;
  }
}

.rightBar {
  width: 180px;
  background: #0000;
  border: 0;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  padding: 0;
  right: 10px;
  top: 120px;
  flex-direction: column;
  flex-wrap: wrap;
}

.tag {
  cursor: pointer;
  width: 20px;
  height: 20px;
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
}

.tag:hover {
  opacity: 0.6;
}

.header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  width: 100%;
  min-height: 64px;
  background: #1a1a1a;
  color: #fff;
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.48px;
}
.link {
  display: flex;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.link:hover {
  color: var(--blue-5, #007bff);
  background-color: transparent;
  opacity: 0.8;
}
.link span {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.btnReady {
  background-color: #01c9d5;
  color: #fff;
}

.inputSmall {
  padding: 0 10px;
  height: auto;
}

.inputSmall {
  max-width: 110px;
  display: flex;
  height: 28px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: 700;
  background: none;
}

.inputOnDarkBg {
  border: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 14px;
  color: #fff;
}

.borderTransparent {
  border-color: transparent !important;
}

.input:hover,
.inputSmall:hover {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--deep-purple-4, #9f8aff) !important;
}
.input:focus,
.inputSmall:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--deep-purple-5, #8c73ff) !important;
}

.nameInputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 200px;
}

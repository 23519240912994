.textareaWrapper {
  position: relative;
  margin-top: 20px;
}
.textarea {
  display: flex;
  width: 100%;
  height: 90px;
  line-height: 22px;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #f9f9f9;
  padding: 10px;
  overflow: auto;
  resize: vertical;
  margin-bottom: 10px;
}
.textarea:focus {
  border-color: #765ee3;
  outline: 0;
}
.label {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 12px;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  background: linear-gradient(to bottom, #fff 62%, #fff 55%, rgba(255, 255, 255, 0.2) 85%);
  z-index: 2;
}
@-webkit-keyframes autofill {
  to {
    color: #333;
    background: transparent;
  }
}

.textarea:-webkit-autofill {
  -webkit-transition-delay: 99999s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tag {
  display: flex;
  margin-right: 10px;
  margin-bottom: 5px;
}
.tag:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.tagLabel {
  background: rgba(0, 0, 0, 0.12);
  color: #212529;
  margin-right: 5px;
}
.tagValue {
  font-style: 0.9rem;
  margin-left: 5px;
  color: #808080;
}

.optionBox {
  gap: 8px;
}

.option {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 4px;
  gap: 4px;
  cursor: pointer;
}

.optionActive {
  border: 2px solid #765ee3;
}

.removeLabelIcon {
  margin-bottom: 2px;
}

.divider {
  width: 4px;
  height: 4px;
  background: #9a9a9a;
  border-radius: 4px;
  margin: 0 5px;
}

.container {
  position: relative;
  height: 22px;
}
.row {
  display: flex;
  align-items: center;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 32px;
}
.checkMark {
  margin: 5px 0;
  width: 26px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 0px 0px 1px 2px rgba(0, 0, 0, 0.03);
  transition: 0.2s;
  border-radius: 25px;
  background: #999d9e;
}
.checkMark:before {
  bottom: 2px;
  content: "";
  height: 18px;
  left: 0px;
  position: absolute;
  transition: 0.2s;
  width: 18px;
  border-radius: 50%;
  background-color: #fff;
  border: 5px solid #b3b8b9;
  box-shadow: inset 0px 0px 1px 2px rgba(0, 0, 0, 0.03);
}
.activeMark {
  background: #5b3fdd;
}
.activeMark:before {
  transform: translateX(9px);
  box-shadow: none;
  border-color: #765ee3;
}
.error {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 20px;
  font-size: 0.75rem;
  color: red;
}
.label {
  font-size: 0.9rem;
  white-space: nowrap;
  flex-wrap: nowrap;
  margin: 0 0 0 8px;
  cursor: pointer;
}
.disabled.activeMark:before {
  border-color: #9887e6;
}
[disabled] + .label {
  opacity: 0.7;
}
.active {
  top: -20px;
}

.white.checkMark {
  background: rgba(28, 31, 31, 0.45);
}
.white.checkMark:before {
  background-color: #765ee3;
  border-color: rgba(0, 0, 0, 0.25);
}
.white.activeMark {
  background: #c3c3c3;
}
.white.activeMark:before {
  transform: translateX(9px);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-color: #fff;
}
.white.disabled.activeMark:before {
  border-color: #ddd;
}
.white ~ .label {
  color: #fff;
}
.disabled,
[disabled],
.disabled * {
  cursor: not-allowed !important;
}

.moreDataCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 2px;
  gap: 10px;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #d0c7fa;
  border: 1px solid #ffffff;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #856afe;
  flex: none;
  order: 4;
  flex-grow: 0;
  z-index: 1;
}
.moreDataCounterWithTooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 2px;
  position: relative;
  width: 20px;
  height: 20px;
  right: 0px;
  background: #d0c7fa;
  border: 1px solid #ffffff;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #856afe;
  flex-grow: 0;
  z-index: 4;
}

.tooltip {
  display: none;
  position: absolute !important;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  min-width: max-content;
  max-width: 350px;
  padding: 3px;
  background: #1a1a1a;
  border-radius: 5px;
  color: #fff;
  font-size: 0.75rem;
  text-align: center;
  text-transform: none !important;
}
.moreDataCounterWithTooltip:hover {
  cursor: pointer;
}
.moreDataCounterWithTooltip:hover .tooltip {
  display: flex;
}

.primary {
  color: var(--neutralBlack88);
  background: var(--neutralWhite100);
  border: 1px solid var(--neutralBlack16);
}
.primary svg {
  fill: var(--neutralBlack88) !important;
}
.primary:hover {
  color: var(--deepPurple500);
  background: var(--deepPurple12);
}
.primary:hover svg {
  fill: var(--deepPurple500) !important;
}
.primary:active {
  color: var(--deepPurple500);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple12);
}
.primary:active svg {
  fill: var(--deepPurple500) !important;
}

.primary-selected {
  color: var(--deepPurple500);
  background: var(--deepPurple75);
}
.primary-selected:hover {
  color: var(--deepPurple500);
  background: var(--deepPurple12);
}
.primary-selected:active {
  color: var(--deepPurple500);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple12);
}
.primary-selected svg {
  fill: var(--deepPurple500) !important;
}

.secondary {
  color: var(--neutralBlack88);
  background: var(--neutralWhite100);
  border: 1px solid var(--neutralBlack16);
}
.secondary svg {
  fill: var(--neutralBlack88) !important;
}
.secondary:hover {
  color: var(--deepPurple500);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple32);
}
.secondary:hover svg {
  fill: var(--deepPurple500) !important;
}
.secondary:active {
  color: var(--deepPurple500);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple12);
}
.secondary:active svg {
  fill: var(--deepPurple500) !important;
}


.secondary-selected {
  color: var(--deepPurple500);
  border: 1px solid var(--deepPurple500);
}
.secondary-selected:hover {
  color: var(--deepPurple500);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple32);
} 
.secondary-selected:active {
  color: var(--deepPurple500);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple12);
}
.secondary svg {
  fill: var(--deepPurple500) !important;
}

.disabled {
  color: var(--neutralBlack48);
  background: var(--neutralBlack4);
  cursor: not-allowed;
}
.disabled svg {
  fill: var(--neutralBlack48);
}

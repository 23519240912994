.input {
  width: 320px;
  margin-bottom: 8px;
}

.amountDisplay {
  width: 100%;
  justify-content: end !important;
  text-align: right;
}

.map-drawer {
  min-width: 375px;
  max-width: 375px;
  padding: 11px 0px 11px 11px;
  background: #f5f4ef;
  overflow: hidden;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  position: absolute;
  max-height: 100vh;
  height: 100%;
  right: 0;
  top: 0;
  transition: 0.2s ease-in-out;
  z-index: 3;
}
.clearButton {
  margin-left: 6px;
}
.drawer_inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
}

.drawer_sections {
  padding: 5px 15px 5px 0px;
}

.drawer_bottom,
.drawer_section {
  padding: 3px 10px;
}

.closed {
  right: -100%;
}
.drawer_section h6 {
  color: #808080;
  font-size: 0.75rem;
}
.timeInputBox,
.dateInputBox {
  width: 48%;
  max-width: 200px;
}

.input {
  color: #333;
  width: 100%;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 0 -5px;
  padding: 4px 5px;
  background: transparent;
  cursor: pointer;
  height: 34px;
}
.inputContainer {
  width: 100%;
}

.input:focus {
  border-color: #2101d5;
  outline: 0;
}
.input[type="datetime-local"]::-webkit-clear-button {
  opacity: 0.0001;
}
.input[type="datetime-local"]::-webkit-clear-button {
  opacity: 0.0001;
}

.input[type="datetime-local"]::-moz-clear {
  opacity: 0.0001;
}

.input[type="datetime-local"] {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
.input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  opacity: 0.0001;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.inputLabel {
  font-size: 0.75rem;
  color: #808080;
  background: #f5f4ef;
  display: inline-flex;
  padding: 2px;
  position: relative;
  left: -2px;
  top: 10px;
  z-index: 1;
  width: fit-content;
}

.accountLabel {
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: fit-content;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 3px;
  opacity: 0.7;
  margin-right: 6px;
  margin-bottom: 6px;
}
.accountLabelBox input {
  position: absolute;
  visibility: hidden;
}
.accountLabelBox input:checked ~ .accountLabel {
  opacity: 1;
  box-shadow: 0 0 0 2px #5b3fdd;
}
.datePickerPopup {
  left: -130px !important;
}
.leftDatePickerPopup {
  left: -4px !important;
}

.toggleButton {
  background-color: #fff;
  box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.03), 0px 3px 5px 2px rgba(0, 0, 0, 0.06);
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 11px;
  border-radius: 50%;
}

.toggleButton:hover {
  background-color: #ddd;
}
.searchInput {
  border: 1px solid #ddd;
  min-width: 220px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  padding: 0 27px 0 10px;
  background: transparent;
  width: 100%;
  border-radius: 20px;
}
.searchInput:hover {
  outline: 0;
  box-shadow: 0 0 1px 1px #ccc;
}
.searchInput:focus {
  outline: 0;
  box-shadow: 0 0 1px 1px #765ee3;
}

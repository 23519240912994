.cell {
  padding: 5px;
}
.bt {
  border-top: 1px solid #ddd;
}
.bb {
  border-bottom: 1px solid #ddd;
}
.bl {
  border-left: 1px solid #ddd;
}
.br {
  border-right: 1px solid #ddd;
}
.grid {
  display:grid;
  overflow-x: auto;
  border: 1px solid #ddd;
}
.btnWrapper {
  max-width: 1280px;
}

.separator {
  display: inline-flex;
  width: 4px;
  min-width: 4px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 4px;
  margin: 2px 8px;
}

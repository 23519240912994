.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mainContainer {
  max-width: 100%;
}
.label {
  font-size: 0.8rem;
  color: #757575;
}
.leftList {
  border-right: 1px solid #ddd;
}
.spanInfo {
  color: #757575;
}
.titleRow {
  border-bottom: 1px solid #ddd;
}
.productName {
  color: #333333
}
.externalId {
  background-color:  #f1ecec;
  border-radius: 3px;
  padding: 1px 2px 1px 2px;
}
.listBorderTop {
  border-top: 1px solid #ddd;
}
.adjustedFontSize {
  font-size: 0.79em;
}
.wrapText {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.productNameFontSize {
  font-size: 0.85em;
}

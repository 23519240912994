.tabBody {
  padding: 16px 0px;
}

.unpaidInvoiceSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 0px 8px 0px;
}

.turnOffMiloDateContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 72px;
  padding: 8px 0px 0px 28px;
}

.datePickerInput {
  height: 28px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  max-width: 154px;
}
.datePickerPopup {
  z-index: 22;
}

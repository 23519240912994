.boxSpinner {
  position: relative;
  display: flex;
  justify-content: center;
  height: 38px;
  width: 38px;
}
.text {
  position: absolute;
  bottom: -22px;
  width: fit-content;
  width: moz-fit-content;
  padding: 0.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.65rem;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 1;
}
.positionAbsolute {
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 150px);
}
.spinner {
  width: 38px;
  height: 38px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 100;
}
.extraSmall.spinner {
  width: 24px;
  height: 24px;
}
.big.spinner {
  width: 90px;
  height: 90px;
}
.big.spinner ~ .text {
  bottom: -44px;
}
.spinner::before,
.spinner::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.spinner::before {
  background: #fff;
  -webkit-animation: pulse 2s ease-in-out infinite;
  animation: pulse 2s ease-in-out infinite;
}
.spinner.blue::before {
  background: #765de3;
}
.spinner.blue::after {
  background: #765de3;
}
.spinner::after {
  background: #fff;
  -webkit-animation: pulse 2s 1s ease-in-out infinite;
  animation: pulse 2s 1s ease-in-out infinite;
}
.spinner.blue ~ .text {
  color: #777;
}
@-webkit-keyframes pulse {
  0%,
  100% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
@keyframes pulse {
  0%,
  100% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.fixedSingleRouteRow {
  height: 29px;
}

.miniFormBox {
  border-radius: 8px;
  box-shadow: 0px -6px 24px -10px rgba(17, 12, 34, 0.1),
    0px 20px 24px -10px rgba(17, 12, 34, 0.1);
}

.customStyleButton {
  width: 125px;
  min-width: 100px;
  font-size: 14px;
}
.customStyleList li {
  white-space: nowrap;
  font-weight: normal;
  font-size: 14px;
}

.employeeNumberInput input {
  max-width: 85px;
  width: 85px;
  min-width: 80px;
}

.sectionEmpty {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.sectionEmpty .icon {
  opacity: 0.5;
}
.sectionEmptyTitle {
  letter-spacing: normal;
  padding-top: 8px;
}

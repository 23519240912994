.container {
  position: relative;
}
.container input {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 32px;
  opacity: 0;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
}

.checkMark {
  position: relative;
  width: 26px;
  height: 14px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}
.checkMark:before {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.2s;
}

.activeMark {
  background: var(--Deep-Purple-5, #8c73ff);
}
.activeMark:before {
  transform: translateX(12px);
  border-color: var(--Deep-Purple-5, #8c73ff);
  box-shadow: 0px 0px 0 4px rgb(87 33 225 / 30%);
}

.label {
  font-size: 12px;
  white-space: nowrap;
  flex-wrap: nowrap;
  margin: 0 0 0 8px;
  cursor: pointer;
  font-weight: 700;
}

.disabled.activeMark:before {
  border-color: #9887e6;
}
[disabled] + .label {
  opacity: 0.7;
}

.disabled,
[disabled],
.disabled * {
  cursor: not-allowed !important;
}

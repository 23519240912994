.loaderBarWrapper {
  z-index: 1;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
}
.loaderBar {
  width: 100%;
  background-color: #e0e0e0;
}
.loaderBarFill {
  height: 3px;
  animation: animate 2.5s ease;
  animation-fill-mode: none;
}
@keyframes animate {
  0% {
    width: 0%;
    opacity: 1;
  }
  45% {
    width: 54%;
    opacity: 1;
  }
  50% {
    width: 65%;
    opacity: 1;
  }
  76% {
    width: 74%;
    opacity: 1;
  }
  88% {
    width: 82%;
    opacity: 1;
  }
  92% {
    width: 96%;
    opacity: 1;
  }
  99% {
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 0%;
    opacity: 0;
  }
}

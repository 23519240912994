.toolbar {
  border: 0;
  min-width: 50%;
  padding: 0;
  width: 50%;
  height: 40px;
}

.toolbarHeader {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.filtersLabel {
  font-size: 12px;
  color: #808080;
}

.searchInput {
  height: 28px !important;
  font-size: 14px;
  border-radius: 4px !important;
  width: 400px !important;
  min-width: 400px !important;
}

.searchWrapper {
  width: 400px !important;
  min-width: 400px !important;
}

.shelfContent {
  background: #f4f6f7;
  overflow: auto;
}
.shelfItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 510px;
  border-right: 1px solid #ddd;
}
.shelfItemHeader,
.shelfItemHeader div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.contentHeight {
  height: 100%;
}

.stopover-point {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0;
  margin-left: 23px;
  width: calc(100% - 23px);
  text-align: left;
  padding: 0 5px 0 5px;
}

.day-stopover-point {
  background: #323237;
  color: #fff;
  border-radius: 5px;
  padding: 8px 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.day-stopover-point:hover {
  background: #492c8c;
  filter: grayscale(1);
  cursor: pointer;
}
.day-stopover-point:hover .timeRange {
  visibility: hidden;
}

.label {
  color: #bbb;
  font-weight: 500;
  font-size: 10px;
}

.editForm {
  padding: 8px 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #323237;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputBox {
  color: #fff;
  height: 22px;
  width: 65px;
}

.dragImg {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.stopover-point:hover .dragImg {
  opacity: 1;
}

.hoverIconContainer {
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.hoverIconContainer img {
  visibility: hidden;
}

.hoverIconContainer:hover img {
  visibility: visible;
}
.buttons {
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.23);
}

.dotContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.greyDot {
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 4px solid #ccc;
  z-index: 2;
  background: #333;
  margin-left: 2px;
}
.dragging {
  border: 1px solid #765ee3;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.avatar {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  font-family: "Sofia Sans", sans-serif;
}

.avatar img {
  border-radius: 30px;
}

.xs {
  height: 18px;
  width: 18px;
  border-width: 1px;
}
.sm {
  height: 24px;
  width: 24px;
  border-width: 1px;
}
.md {
  height: 30px;
  width: 30px;
  border-width: 2px;
}
.lg {
  height: 34px;
  width: 34px;
  border-width: 2px;
}
.xl {
  height: 50px;
  width: 50px;
  border-width: 2px;
}

.light {
  border-color: var(--neutralWhite100);
}
.lightWithOnDelete {
  border-color: var(--deepPurple500);
}
.dark {
  border-color: var(--neutralBlack64);
}
.darkWithOnDelete {
  border-color: var(--deepPurple200);
}

.lightPlaceholder {
  border: 1px dashed var(--neutralBlack32);
}
.darkPlaceholder {
  border: 1px dashed var(--neutralWhite32);
}

.onDelete {
  display: flex;
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  position: absolute;
  cursor: pointer;
}

.onDeleteInner {
  display: flex;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
}

.lightOnDelete {
  background: var(--neutralBlack100);
}
.darkOnDelete {
  background: var(--neutralWhite100);
}

.xsOnDeletePosition {
  right: -6px;
  top: -6px;
}
.smOnDeletePosition {
  right: -6px;
  top: -6px;
}
.mdOnDeletePosition {
  right: -3px;
  top: -3px;
}
.lgOnDeletePosition {
  right: -3px;
  top: -3px;
}
.xlOnDeletePosition {
  right: 0px;
  top: 0px;
}

.initials {
  font-style: normal;
  text-transform: uppercase;
}

.xsLineHeight {
  line-height: 14px;
}
.smLineHeight {
  line-height: 18px;
}
.mdLineHeight {
  line-height: 20px;
}
.lgLineHeight {
  line-height: 22px;
}
.xlLineHeight {
  line-height: 40px;
}

.image {
  object-fit: cover;
}

@keyframes listLoader {
  0% {
    opacity: 0.07;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.07;
  }
}

.loader {
  animation: listLoader 2s linear infinite;
  border-radius: 2px;
}

.lightLoader {
  background: rgba(0, 0, 0, 0.7);
}

.darkLoader {
  background: rgba(255, 255, 255, 0.7);
}

.optionList {
  position: absolute;
  z-index: 1;
  right: 0;
  min-width: 260px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  /* display: none; */
  letter-spacing: -0.03rem;
  font-family: 'Sofia Sans', sans-serif;
}

.option {
  display: block;
  position: relative;
  padding: 4px 10px 4px 24px;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  border-radius: 4px;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

.option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.radiomark,
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 0;
  border-radius: 4px;
  background: rgba(155, 155, 155, 0.6);
}

.radiomark {
  border-radius: 50%;
}

.radiomark:after,
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.option input:checked ~ .radiomark,
.option input:checked ~ .checkmark {
  background-color: var(--deep-purple-5, #8c73ff);
}

.option:hover input ~ .radiomark,
.option:hover input ~ .checkmark {
  background-color: var(--deep-purple-4, #9f8aff);
}

.option input:checked ~ .radiomark:after,
.option input:checked ~ .checkmark:after {
  display: block;
}

.option--unactive input:checked ~ .radiomark,
.option--unactive input:checked ~ .checkmark,
.option--unactive .radiomark,
.option--unactive .checkmark,
.option--unactive,
.option--unactive:hover,
.option--unactive:focus {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent;
}

.option .radiomark:after {
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
}

.option .checkmark:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 13px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.option .checkmark:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 13px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.radiomark:after,
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.09);
  overflow: hidden;
  margin-right: 5px;
}
.avatar img {
  display: block;
  max-width: 100%;
}

.userName {
  position: relative;
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 14px;
}

.wrapper {
  min-width: 180px;
  width: 180px;
}

.list {
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}

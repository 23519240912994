.link {
  display: flex;
  padding: 7px 10px 7px 20px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;
}

.link:hover {
  background: var(--neutralWhite7);
  color: #fff;
}

.link:active {
  background: var(--deepPurple32) !important;
}

.linkActive {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.linkDisabled,
.linkDisabled:hover,
.linkDisabled:active,
.linkDisabled:focus {
  color: #555555;
  background-color: transparent !important;
  cursor: not-allowed;
  opacity: 0.5;
}

.link .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.linkActive .linkName {
  color: var(--neutralWhite100) !important;
}

.linkName {
  color: var(--neutralWhite64) !important;
}

.attribute {
  padding: 2px;
  border: 1px solid #ddd;
  background-color: #d0d0d6;
  border-radius: 3px;
  margin: 3px 2px 3px 0;
  font-size: 11px;
  max-width: 100%;
  flex-wrap: wrap;
  min-width: min-content;
}

.attribute span {
  margin-right: 2px;
  white-space: nowrap;
  font-weight: normal;
  color: #444;
}
.attribute strong {
  font-weight: 700;
}

.attributesContainer {
  margin: 4px 0px;
  display: flex;
  flex-wrap: wrap;
}

.noWrap {
  flex-wrap: nowrap !important;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.noWrap::-webkit-scrollbar {
  display: none;
}

.employeeHeader {
  position: relative;
  min-height: 36px;
  padding: 5px 15px 5px 15px;
  gap: 10px;
}
.employeeHeaderBorder {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.employeeHeaderInner {
  width: 540px;
  align-items: center;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatarSmall {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0;
}
.avatar img {
  display: block;
  max-width: 100%;
}

.dayOffLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  height: 26px;
  background: rgba(215, 173, 3, 0.35);
  border-radius: 4px;
  color: #ab8900;
  font-size: 0.8rem;
}

.employeeWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.dayCardWrapper {
  display: flex;
  margin-left: 15px;
  gap: 10px;
}
.workerWrapperPlaceholder:hover .dayCardWrapper {
  height: auto;
  flex-grow: auto;
}
.colPlaceholder,
.workerWrapperPlaceholder .dayCardWrapper,
.workerWrapperPlaceholder .workerHeader {
  opacity: 0.15;
}

.labelContainer {
  min-height: 42px;
  display: flex;
  padding: 8px 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: #242227;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.20);
  border: 1px solid rgba(255, 255, 255, 0.10);
}

.labelContainer:focus {
  border: 1px solid var(--Deep-Purple-5, #8C73FF);
}

.labelContainerInSearchMode {
  border: 1px solid var(--Deep-Purple-5, #8C73FF);
}

.selectedUser {
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}

.avatar {
  margin: 0;
  background: rgb(0, 207, 145);
}

.avatar img {
  height: 24px;
  width: 24px;
}

.removeSelect:hover {
  cursor: pointer;
}

.addBtn {
  display: flex;
  padding: 4px 8px;
  min-height: 26px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #fff;
  border-radius: 8px;
  background: var(--white-15, rgba(255, 255, 255, 0.15));
}

.addBtn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.searchInput {
  width: 100%;
  min-height: 24px;
  display: flex;
  gap: 2px;
  color: #fff;
  min-width: 100%;
  position: relative;
  flex: 1;
  border-radius: 4px;
  background: transparent;
  align-items: center;
}

.input {
  background: transparent;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  border: none;
  color: #fff;
}

.input:focus {
  outline: none;
  color: #fff;
}

.input:focus::placeholder {
  color: #fff;
  opacity: 1;
}

.menuList {
  position: absolute;
  overflow: auto;
  z-index: 99;
  max-height: 200px;
  left: -10px;
  top: 35px;
  bottom: -182px;
  display: flex;
  width: 220px;
  padding: 10px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: var(--White-100, #FFF);
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.25);
}

.hiddenMenuList {
  display: none;
}

.menuItem {
  display: flex;
  padding: 5px 15px;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  align-self: stretch;
  color: var(--Black-100, #141414);
}

.menuItem:hover {
  background: #F4F4F4;
  cursor: pointer;
}

.checkMark {
  position: relative;
  height: 19px;
  min-height: 19px;
  min-width: 19px;
  width: 19px;
  border: 0;
  border-radius: 3px;
  background: rgba(155, 155, 155, 0.6);
}

.checkMark:hover {
  cursor: pointer;
}

.checkMarkActive {
  background: #765ee3;
}

.checkMarkActive::after {
  position: absolute;
  content: "";
  left: 7px;
  top: 2px;
  width: 6px;
  height: 13px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

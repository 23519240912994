.incomeRow {
  background-color: var(--success25);
}
.expenseRow {
  background-color: var(--danger25);
}
.summaryRow {
  background-color: var(--deepPurple25);
}
.tableSection {
  width: 70%;
}

.chartSection {
  width: 30%;
}

.wrapper {
  height: 100vh;
  max-height: 100vh;
}

.innerWrapper {
  display: flex;
  padding-right: 16px;
  overflow: hidden;
  flex-direction: column;
  flex: 1;
  gap: 32px;
}

.charts {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.tables {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@media (min-width: 1819px) {
  .innerWrapper {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .charts {
    flex-direction: column;
  }
}

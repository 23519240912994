.inputContainer {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;
}

.input {
  border: none;
  outline: none;
  margin-right: 6px;
  flex: 1;
}

.inputIcon {
  cursor: pointer;
}

.textareaWrapper {
  position: relative;
}
.textarea {
  display: flex;
  width: 100%;
  height: 90px;
  line-height: 22px;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #f9f9f9;
  padding: 2px 10px;
  overflow: auto;
  resize: vertical;
}
.textarea:focus {
  border-color: #765ee3;
  outline: 0;
}
.label {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 12px;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  background: linear-gradient(to bottom, #fff 62%, #fff 55%, rgba(255, 255, 255, 0.2) 85%);
  z-index: 2;
}
@-webkit-keyframes autofill {
  to {
    color: #333;
    background: transparent;
  }
}

.textarea:-webkit-autofill {
  -webkit-transition-delay: 99999s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

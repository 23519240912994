.textFieldInput {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 6px;
  width: 100%;
  position: relative;
}

.labelWrapper {
  margin-top: 7px;
}

.defaultInput {
  padding: 0;
  background: none;
  z-index: 2;
  border: none;
  outline: none;
  font-size: 14px;
  font-style: normal;
  width: 100%;
  font-weight: 700;
  line-height: 20px;
}

.defaultInputDark {
  color: var(--neutralWhite100);
}
.defaultInputDark::placeholder {
  color: var(--neutralWhite48);
}

.defaultInputLight::placeholder {
  color: var(--neutralBlack48);
}

.defaultInput:placeholder-shown {
  text-overflow: ellipsis;
}

.light {
  border: 1px solid var(--neutralBlack20);
  color: var(--neutralBlack100);
}
.light:hover {
  border: 1px solid var(--deepPurple200);
}
.light:focus {
  border: 1px solid var(--deepPurple400);
}

.dark {
  border: 1px solid var(--neutralWhite20);
  color: var(--neutralWhite100);
}
.dark:hover {
  border: 1px solid var(--deepPurple400);
}
.dark:focus {
  border: 1px solid var(--deepPurple400);
}

.default {
  height: 38px;
  padding-left: 10px;
  padding-right: 10px;
}
.small {
  height: 28px;
  padding-left: 5px;
  padding-right: 5px;
}

.disabledLight {
  cursor: not-allowed;
  border: 1px solid var(--neutralBlack20) !important;
  color: var(--neutralBlack24);
}
.disabledDark {
  cursor: not-allowed;
  border: 1px solid var(--neutralWhite20) !important;
  color: var(--neutralWhite24);
}

.error {
  border: 1px solid var(--danger500);
}

.readOnlyLight {
  border: 1px solid var(--neutralBlack4);
  color: var(--neutralBlack88);
}
.readOnlyLight::placeholder {
  color: var(--neutralWhite24);
}
.readOnlyDark {
  border: 1px solid var(--neutralWhite4);
  color: var(--neutralWhite100);
}
.readOnlyDark::placeholder {
  color: var(--neutraBlack24);
}

.notAllowed {
  cursor: not-allowed;
}

.label {
  line-height: 1 !important;
  z-index: 1;
  padding: 0px 2px;
  position: absolute;
  top: -6px;
}

.defaultLabel {
  left: 8px;
}

.smallLabel {
  left: 3px;
}

.lightLabel {
  background: var(--neutralWhite100);
}

.darkLabel {
  /* TODO change it to proper color WHEN: milo-front rescue sprint */
  background: #242227;
}

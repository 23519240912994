.round-size-s {
  height: 26px;
  width: 26px;
  min-width: 26px;
}
.round-size-m {
  height: 32px;
  width: 32px;
  min-width: 32px;
}
.button:hover {
  cursor: pointer;
}
.button-s {
  height: auto;
  font-size: 0.9rem;
  justify-content: center;
  padding: 6px;
  width: auto;
}
.button-m {
  font-size: 1.1rem;
  padding: 10px;
}
.button-l {
  font-size: 1.2rem;
  padding: 14px;
}
.btn-primary,
.btn-danger,
.btn-stroke,
.btn-secondary {
  outline: none;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.btn-as-link[disabled],
.btn-primary[disabled],
.btn-danger[disabled],
.btn-stroke[disabled],
.btn-secondary[disabled],
.btn-ready[disabled] {
  opacity: 0.65;
  cursor: not-allowed;
}
.btn-as-link:focus,
.btn-primary:focus,
.btn-danger:focus,
.btn-stroke:focus,
.btn-secondary:focus,
.btn-ready:focus {
  outline: 0;
}
.btn-as-link {
  color: #5ec9d5;
  border-radius: 5px;
}
.btn-as-link:hover {
  outline: 0;
  background: rgba(0, 0, 0, 0.085);
  color: #52b8c3;
  text-decoration: none;
}
.btn-as-link:focus {
  outline: 0;
  background: rgba(0, 0, 0, 0.12);
}
/* button:hover {
  background: rgba(0, 0, 0, 0.07);
}
button:focus {
  background: rgba(0, 0, 0, 0.1);
  outline: 0;
} */
.btn-primary,
.btn-danger,
.btn-stroke,
.btn-secondary,
.btn-ready {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: transparent;
  border: 0;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  line-height: 1;
  cursor: pointer;
}
.btn-primary {
  color: #fff;
  background: #765ee3;
  box-shadow: none;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-primary:hover {
  background: #6853ca;
  color: #fff;
  text-decoration: none;
}
.btn-primary:focus {
  background: #5a48af;
  color: #fff !important;
  text-decoration: none;
}
.btn-danger {
  color: #fff;
  background: #e87076;
  box-shadow: none;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-danger:hover {
  background: #ce6368;
  color: #fff;
  text-decoration: none;
}
.btn-danger:focus {
  background: #ff8b91;
  color: #fff !important;
  text-decoration: none;
}

.btn-stroke,
.btn-secondary {
  color: #444;
}
.btn-secondary:hover {
  background: rgba(0, 0, 0, 0.12);
}
.btn-secondary:focus {
  background: none;
  outline: 0;
}
.btn-stroke:focus {
  background: rgba(0, 0, 0, 0.15);
}

.btn-grey {
  background: #0000000d;
  border: 1px solid #0000000d;
}

.btn-stroke {
  border: 1px solid #dcdcdc;
}
.btn-stroke:hover,
.auto-width:hover {
  background: #ebebeb;
}
.btn-stroke:active {
  background: #dcdcdc;
}
.btn-stroke:focus,
.auto-width:focus,
.btn-stroke:hover,
.auto-width:hover {
  text-decoration: none;
  color: #333;
}
.rounded-size {
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  width: auto;
  border-radius: 30px;
}
.round-size-m,
.round-size-s {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 2px;
  border-radius: 50px;
}
.round-size-m img,
.round-size-s img {
  display: flex;
  max-width: 100%;
}
.btn-primary img {
  filter: contrast(0%) brightness(150%);
}
.btn-danger img {
  filter: contrast(0%) brightness(150%);
}
.btn-ready {
  background: transparent;
  color: #333;
  border-color: #bbbbbb;
  border-radius: 50px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  line-height: 25px;
  display: inline-flex;
  padding: 0 8px;
}
.btn-ready:hover {
  background: rgba(0, 0, 0, 0.03);
  color: #333;
  border-color: #ddd;
}
.btn-ready:hover img {
  filter: grayscale(1);
}
.btn-ready:focus:not([disabled]) {
  background: rgba(94, 201, 213, 0.38);
  color: #333;
  border-color: #ddd;
}
/* .btn-ready:focus {
  background: rgba(94, 201, 213, 0.38);
  color: #333;
  border-color: #ddd;
} */
/* .btn-ready:focus img {
  filter: contrast(0%) brightness(200%);
} */
.btn-ready img {
  filter: contrast(0%) brightness(0%);
  margin-top: -2px;
  margin-right: 2px;
}
.content-width {
  width: fit-content;
  width: -moz-fit-content;
}
.auto-width {
  width: auto;
}
.full-width {
  width: 100%;
}

.square-size-xs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  min-height: 20px;
}

.square-size-s {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
}

.square-size-m {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.transparent-white {
  display: flex;
  min-height: 26px;
  padding: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  color: #fff;
  background: transparent;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
}
.transparent-white:hover {
  background: var(--white-25, rgba(255, 255, 255, 0.25));
}
.transparent-white:focus {
  outline: none;
  box-shadow: none;
  /* border: 1px solid var(--white-15, rgba(255, 255, 255, 0.15)); */
  background: var(--white-35, rgba(255, 255, 255, 0.35));
}

.transparent-teal-green {
  color: #333;
  border: 1px solid var(--black-5, rgba(0, 0, 0, 0.15));
  background: transparent;
}
.transparent-teal-green:focus {
  box-shadow: none;
  outline: none;
}
.transparent-teal-green:hover {
  background: var(--Teal-2, #bbdedb);
}
.btnBgBlack5 {
  color: #333;
  background: var(--black-5, rgba(0, 0, 0, 0.05));
}
.btnBgBlack5:hover {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}
.btnBgBlack5:focus {
  box-shadow: none;
}

.btnBgDeepPurple {
  color: #fff;
  background: var(--deep-purple-5, #8c73ff);
}
.btnBgDeepPurple:hover {
  background: var(--deep-purple-4, #9f8aff);
}
.btnBgDeepPurple img {
  filter: invert(1);
}

.btn-teal-green {
  color: #333;
  background: var(--teal-4, #6de5b9);
  text-transform: uppercase;
  height: 26px;
  display: inline-flex;
  align-items: center;
  position: relative;
  display: flex;
  min-height: 26px;
  padding: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
.btn-teal-green:hover {
  background: var(--teal-9, #82dbba);
}
.btn-teal-green:focus {
  outline: none;
  box-shadow: none;
  background: var(--teal-10, #98d8c0);
}

.transparent-teal-green-2 {
  color: var(--teal-4, #6de5b9);
  border: 1px solid var(--teal-35, rgba(65, 148, 136, 0.35));
  background: transparent;
  text-transform: uppercase;
  height: 26px;
  display: inline-flex;
  align-items: center;
  position: relative;
  display: flex;
  min-height: 26px;
  padding: 1px;
  gap: 6px;
  border-radius: 4px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}
.transparent-teal-green-2:hover {
  background: var(--Teal-2, #bbdedb);
  color: #333;
}

.transparent {
  display: flex;
  padding: 1px;
  color: #fff;
  background: transparent;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
}
.transparent:hover {
  background: var(--white-25, rgba(255, 255, 255, 0.25));
}
.transparent:focus {
  outline: none;
  box-shadow: none;
  background: none;
}

.button-square {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  height: fit-content;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 4px;
}

.button-square {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-bottom: 4px;
}
.button-square img {
  width: 20px;
}
.button-square:hover {
  background: rgba(255, 255, 255, 0.1);
}
.button-square:focus {
  background: rgba(255, 255, 255, 0.3);
}

.transparent-black {
  color: #333;
  background: transparent;
  display: flex;
  min-height: 26px;
  padding: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
}

.transparent-black:hover {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}
.transparent-black:focus {
  border: 1px solid var(--black-5, rgba(0, 0, 0, 0.05));
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}

.btn-create {
  display: flex;
  min-height: 20px;
  padding: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  color: #333;
  border: 1px solid var(--black-5, rgba(255, 255, 255, 0.05));
  background: var(--black-5, rgba(0, 0, 0, 0.05));
  text-transform: uppercase;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
}
.btn-create:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--black-5, rgba(0, 0, 0, 0.05));
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}
.btn-create:hover {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}

.btn-confirm {
  display: flex;
  min-height: 26px;
  padding: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  color: #333;
  border: 1px solid var(--black-5, rgba(255, 255, 255, 0.05));
  background: var(--black-5, rgba(0, 0, 0, 0.05));
  text-transform: uppercase;
}

.btn-confirm[disabled] {
  display: flex;
  min-height: 26px;
  padding: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  border: 1px solid #ddd;
  text-transform: uppercase;
  background: #5cac8f;
  color: #fff;
  fill: #8dd5b3;
}

.btn-confirm:hover {
  background: #6bc8a6;
  border-color: #4d9079;
  fill: #99e9c4;
}

.btn-confirm[disabled]:hover {
  background: #6bc8a6;
  border-color: #4d9079;
  color: #fff;
  fill: #99e9c4;
}

.btn-confirm:focus:not([disabled]) {
  background: #5cac8f;
  border-color: #3b6e5c;
  fill: #8dd5b3;
}

.btn-confirm[disabled]:focus {
  background: #5cac8f;
  border-color: #3b6e5c;
  color: #fff;
  fill: #8dd5b3;
}

.btn-create-transparent {
  width: 32px;
  min-width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  border-radius: 4px;
  background: none;
  border: none;
  position: relative;
}
.btn-create-transparent:hover {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}
.btn-create-transparent {
  width: 26px;
  min-width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  background: none;
  border: none;
}
.btn-create-transparent img {
  display: block;
}

.btn-create-medium {
  height: 30px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  background: none;
  border: none;
  border-radius: 4px;
  position: relative;
}

.btn-create-medium img {
  display: block;
}

.btn-create-medium:hover {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}

.btn-submit {
  position: relative;
  display: inline-flex;
  min-height: 26px;
  height: 26px;
  padding: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  color: #fff;
  background: var(--deep-purple-5, #8c73ff);
  text-transform: uppercase;
}

.btn-submit:hover {
  color: rgb(40, 40, 40);
  background: var(--deep-purple-4, #9f8aff);
}

.btn-submit:focus {
  outline: none;
  box-shadow: none;
  background: var(--deep-purple-3, #b7a8fc);
}

.btn-submit img {
  filter: invert(1);
}

.btn-submit-medium {
  position: relative;
  display: inline-flex;
  min-height: 30px;
  height: 30px;
  padding: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  color: #fff;
  background: var(--deep-purple-5, #8c73ff);
  text-transform: uppercase;
}

.btn-submit-medium:hover {
  color: rgb(40, 40, 40);
  background: var(--deep-purple-4, #9f8aff);
}

.btn-submit-medium:focus {
  outline: none;
  box-shadow: none;
  background: var(--deep-purple-3, #b7a8fc);
}

.btn-submit-medium img {
  filter: invert(1);
}

.btn-checkbox {
  color: #333;
  border: 1px solid var(--black-5, rgba(0, 0, 0, 0.15));
  border-radius: 4px;
  background: transparent;
}
.btn-checkbox[disabled] {
  background: var(--teal-4, #6de5b9);
}
.btn-checkbox:hover {
  background: var(--Teal-2, #bbdedb);
}
.btn-checkbox[disabled]:hover {
  background: var(--teal-4, #8fe1c3);
}
.btn-checkbox:focus:not([disabled]) {
  background: var(--teal-4, #6de5b9);
}
.btn-checkbox[disabled]:focus {
  background: var(--teal-4, #6de5b9);
}

.btnSizeLarge {
  display: inline-flex;
  align-items: center;
  height: 38px;
}
.btnSizeMedium {
  display: inline-flex;
  align-items: center;
  height: 30px;
}
.btnSizeSmall {
  display: inline-flex;
  align-items: center;
  height: 26px;
}

.btnBgWhite5 {
  color: #fff;
  background: var(--white-5, rgba(255, 255, 255, 0.05));
}
.btnBgWhite5:hover {
  background: var(--white-25, rgba(255, 255, 255, 0.25));
  color: #fff;
}
.btnBgWhite5:focus {
  border: 1px solid var(--white-15, rgba(255, 255, 255, 0.15));
  background: var(--white-35, rgba(255, 255, 255, 0.35));
}

.btnBgRed {
  color: #fff;
  background: var(--Red-6, #c3291c);
}
.btnBgRed:hover {
  background: var(--Red-4, #e26b77);
}
.btnBgRed:focus {
  background: var(--Red-3, #e39e9c);
}
.btnBgRed img {
  filter: invert(1);
}

.tinyIcon img {
  width: 10px;
  height: 10px;
}

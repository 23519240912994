.chip {
  display: inline-flex;
  height: 20px;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 4px;
  cursor: pointer;
}

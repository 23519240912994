.input {
  width: 320px;
  margin-bottom: 16px;
}

.smallInput {
  width: 120px;
  margin-bottom: 16px;
}

.tabScroll {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabScroll::-webkit-scrollbar {
  display: none;
}

.emptyIcon {
  width: 130px;
  height: 130px;
}

.stage {
  border: 1px solid var(--neutralBlack16);
  border-radius: 4px;
}

.stageWithRemovedMaterial {
  border: 1px solid var(--warning400);
}

.recipeWrapper {
  width: calc(50% - 2px);
}

.deletedRecipeInner {
  position: absolute;
  right: 0;
  top: 25%;
  background: var(--neutralWhite88);
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
}

.modalContainer {
  min-height: 40vh;
}

.tableContainer {
  max-height: 55vh;
  overflow: scroll;
}

.search input[type='search'] {
  display: flex;
  height: 28px;
  padding: 5px 10px;
  margin-bottom: 15px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: url(~assets/images/search.svg) no-repeat 98% center;
}

.search input[type='search']:focus {
  outline: none;
  border: 1px solid #856afe;
  background: url(~assets/images/search.svg) no-repeat 98% center;
}

.search {
  font-size: 12px;
  line-height: 16px;
}

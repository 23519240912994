.filterChip {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 1px 3px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 2px;
}

.filterCustomer {
  position: relative;
  cursor: pointer;
}

.suggestedDelivery {
  height: 34px;
}

.item {
  position: relative;
  display: inline-flex;
  align-items: center;
  z-index: 6;
}
.btn {
  padding: 4px 6px;
  border-radius: 5px;
}
.btn:hover {
  background: #eee;
}
.arrowIcon {
  transform: rotate(90deg);
}
.label {
  color: #4d4d4d;
}

.optionsList {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 5px 0;
  min-width: 200px;
  max-width: 310px;
  max-height: 300px;
  overflow-y: auto;
  display: none;
  max-height: 300px;
  overflow-y: auto;
}

.top {
  top: auto;
  bottom: 100%;
  right: 0;
  left: auto;
  border-radius: 5px 5px 0px 5px;
}
.optionsItem {
  padding: 8px 10px;
  font-size: 0.95rem;
  color: #343f40;
  width: 100%;
  text-align: left;
}
.optionsItem::first-letter {
  text-transform: capitalize !important;
}
.optionsItem:hover {
  background: #ddd;
  cursor: pointer;
}
.optionsItem:focus {
  background: #0000001a;
}
.optionsItem:global(.active) {
  color: #bbb;
}
@media (max-width: 1279px) {
  .optionsList {
    right: initial;
    left: 0;
  }
}

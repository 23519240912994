.emptySection {
  border: 1px solid var(--warning400);
  border-radius: 4px;
}

.emptySectionUpdated {
  border: 1px solid var(--neutralBlack7);
  border-radius: 4px;
}
.datePickContainer {
  min-height: 44px;
  max-height: 44px;
  height: 44px;
}

.datePick {
  margin-bottom: 33px;
}

.datePickerInput {
  font-size: 14px;
  max-height: 28px;
  font-weight: 700;
  max-width: 120px;
  padding-left: 5px;
}

.timePickerInput {
  max-height: 28px;
}

.datePickerPopup {
  z-index: 22;
}

.formHeight {
  height: 28px;
  font-size: 14px;
  font-weight: 700;
}

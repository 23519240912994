.colorLabel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  border-radius: 4px;
  font-size: 10px;
  letter-spacing: normal;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tableRowPPStages {
  padding-right: 10px;
  padding-left: 20px;
}
.tableRowPPStages:hover {
  background-image: url("assets/images/dragIndicator.svg");
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: 3%;
}


 .stage {
  border: 1px solid #333;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.25);
  min-width: 120px;
  position: relative;
  padding: 2px 5px;
}

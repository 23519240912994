.goToMapBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.goToMapBtn img {
  width: 14px;
  height: 14px;
  min-height: 14px;
  padding: 0;
}
.goToMapBtn button {
  min-height: 14px;
}

.goToMapBtn {
  color: #2b8efa !important;
}
.goToMap a {
  color: #333;
}

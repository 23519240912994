.container label {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.container label:last-child {
  border-bottom: none;
}
.container {
  max-height: 280px;
  overflow: auto;
}

.inputContainer {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;
}

.input {
  border: none;
  outline: none;
  margin-right: 6px;
  flex: 1;
}

.inputIcon {
  cursor: pointer;
}

.modal {
  padding: 20px 15px;
  background: #ffffff !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
  border-radius: 4px !important;
  width: 480px !important;
}
.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;

  color: #000000;
}

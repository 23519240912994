.container {
  gap: 4px;
  background: #0000;
  border: 0;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  padding: 0;
  left: 12px;
  top: 70px;
}

.theme {
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 4px;
}

.theme img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  border: 3px solid transparent;
}

.selected img {
  border: 3px solid var(--deepPurple400);
}

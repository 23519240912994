.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .button {
    width: 48px;
    height: 48px;
  }
}
.boxSpinner {
  width: 0 !important;
  height: 0 !important;
}

.paginationPages {
  min-width: 90px;
  font-size: 12px;
  color: grey;
}

.modal {
  min-height: 25vh;
  min-width: 60vw;
  padding: 5px 15px 15px 15px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.checkedNumberLabel {
  font-size: 12px;
  color: #808080;
}

.columnNames {
  border-top: 1px solid #ddd;
  padding: 5px 0;
}

.separator {
  display: block;
  width: 1px;
  background: #ddd;
  height: 44px;
  margin: 0 10px;
}

.datePickerPopup {
  z-index: 22;
}

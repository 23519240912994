.input {
  width: 320px;
  margin-bottom: 8px;
}

.postCode {
  min-width: 80px;
  width: 80px;
  max-width: 80px;
}
.city {
  min-width: 232px;
  width: 232px;
  max-width: 232px;
}
.countryCode {
  min-width: 180px;
  width: 180px;
  max-width: 180px;
}

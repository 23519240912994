.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 15px;
}

.tableContent {
  padding: 8px 0px;
}

.selectedInfo {
  align-items: center;
  display: flex;
  gap: 4px;
  height: 16px;
  padding: 0px 0px 4px 0px;
}

.responseModalTitle {
  background-color: var(--success500);
  border-radius: 8px 8px 0 0;
}

.successTitle {
  background-color: var(--success500);
  border-radius: 8px 8px 0 0;
}

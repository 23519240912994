.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 0 15px;
}
.header {
  min-height: 70px;
}
.navTab,
.headerLeft {
  height: 100%;
}
.title {
  font-size: 1.65rem;
  font-weight: 700;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
}
.subtitle {
  font-size: 1rem;
  font-weight: 500;
  color: #765ee3;
}
.navTab {
  height: 100%;
  border-bottom: 3px solid transparent;
  margin-right: 10px;
  padding: 2px 0 4px;
  color: #8f969f;
}
.navTabActive {
  border-bottom: 3px solid #01c9d5;
  color: #01c9d5;
}
.navTab:hover {
  color: #333;
  text-decoration: none;
}
.viewTabs {
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-left: 20px;
}
.viewTabs button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  width: 30px;
  border: 0;
}
.viewTabs button:hover,
.viewTabActive:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.viewTabActive:hover img {
  filter: contrast(100%) brightness(100%);
}
.viewTabs button:focus,
.viewTabActive:focus {
  outline: 0;
}
.viewTabActive {
  background: #765ee3 !important;
}
.viewTabActive img {
  filter: contrast(0%) brightness(170%) !important;
}

.avatarBox {
  position: relative;
}
.avatarBoxDev {
  border: 3px solid red;
  border-radius: 50%;
}

.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #ddd;
  margin: 9px 0;
  overflow: hidden;
}

.avatarLarge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 1.3rem;
  background: #ddd;
  margin: 9px 0;
}
.avatar img {
  display: block;
  width: 100%;
}
.dropDown {
  background: #fff;
  box-shadow: 2px 5px 30px 4px rgba(0, 0, 0, 0.1), 1px 0px 6px 2px rgba(0, 0, 0, 0.1),
    -2px 4px 8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 5px 5px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  min-width: fit-content;
  width: 300px;
  padding: 15px 0 10px;
}
.dropDownClicked {
  display: block;
}
.dropDown .userName {
  text-align: center;
  font-size: 1.2rem;
}
.options {
  padding: 10px 1rem;
  font-size: 1rem;
  color: #777;
  text-decoration: none;
  border: 0;
  background: transparent;
  border-radius: 0;
}
.options:hover {
  color: #333;
  text-decoration: none;
  background: #ddd;
}
.options:focus {
  color: #333;
  text-decoration: none;
  background: #ccc;
  outline: 0;
}
.options span {
  margin-left: 15px;
}
.options img {
  width: 20px;
  height: 20px;
}
.btnCircular {
  width: 26px;
  min-width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  border-radius: 50px;
}
.btnCircular img {
  display: block;
  width: auto;
}
.btnCircular:hover {
  background: rgba(0, 0, 0, 0.05);
}
.btnCircular:focus {
  background: rgba(0, 0, 0, 0.1);
  outline: none;
}

.toasterDate {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 5px;
  font-size: 12px;
  display: block;
  text-align: right;
}

.toasterDropdown {
  max-height: 400px;
  overflow-y: auto;
  background: #755ee4;
}
.toasterWrapper {
  padding: 5px 10px 10px;
  border-bottom: 1px solid #4835a0;
}
.emptyList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
}
.emptyList span {
  color: white;
  text-align: center;
  margin-top: 10px;
}

.popoverMenu {
  border: 1px solid #dedede;
  border-radius: 8px;
  color: #fff;
  overflow: hidden;
}
.popoverMenuTop {
  background-color: var(--color-violets-are-blue);
  padding: 40px;
}
.popoverMenuBottom {
  background-color: var(--color-slate-blue);
  padding: 40px;
}
.helpdeskBtn {
  color: #fff;
  border: 1px solid var(--color-black-20);
}

.lineItemContainer {
  padding: 8px 15px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.orderHeader {
  display: flex;
  height: 34px;
  align-items: center;
  padding: 8px 15px 4px 15px;
  gap: 10px;
  border-bottom: 1px solid var(--neutralBlack12);
}

.orderHeaderGeneralInfo {
  display: flex;
  align-items: center;
  width: 240px;
  gap: 8px;
}

.orderHeaderCustomer {
  width: 470px;
}

.orderHeaderAddress {
  width: 200px;
}

.finishedItem {
  border: 1px solid var(--success400);
}

.tableContainer {
  border-bottom: 2px solid var(--neutralBlack12);
}

.tableArea {
  max-height: 55vh;
  overflow: scroll;
}

.closeBtn {
  height: 42px;
}

.card {
  box-shadow: 1px 5px 5px #b5b5b5;
  margin-bottom: 1rem;
}

.was-validated .invalid-feedback {
  display: block;
  color: #ec6c74;
  font-size: 0.75rem;
  margin-top: 0;
}

label {
  margin-bottom: 0;
}

button {
  background-color: unset;
  border: unset;
}
a:hover {
  color: #673ab7;
  text-decoration: none;
}
.tooltip {
  z-index: 2;
}

.stages {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow-x: scroll !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.stages::-webkit-scrollbar {
  display: none;
}

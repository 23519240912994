.priceListSelect {
  width: 320px;
}

.inputSection {
  display: flex;
  gap: 10px;
  padding-left: 28px;
}

.input {
  width: 90px;
}

.date {
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 38px;
  border: 1px solid #ddd;
  margin: 0 0 20px;
  padding: 0 0 0 5px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: listbox;
  width: 100%;
}
.date:focus {
  box-shadow: #765ee3 0px 0px 0px 1px;
  outline: none;
}
.label {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 12px;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  background: #fff;
  z-index: 1;
}
.datePickerPopup {
  z-index: 22;
}

.courierLogo {
  width: 26px;
  height: 26px;
}

.financialWarehouseStatePage {
  background: var(--neutralWhite100);
  border-radius: 4px;
  box-shadow: 0px 6px 16px -6px #0000001a;
}

.emptyListIcon {
  width: 130px;
  height: 130px;
}

.chart {
  min-height: 330px;
}

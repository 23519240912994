.modal {
  margin: 2vh auto;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.group {
  position: relative;
}
.group:before {
  content: "";
  position: absolute;
  z-index: 0;
  width: 5px;
  top: 11px;
  bottom: 5px;
  left: 6.5px;
  background: rgba(0, 0, 0, 0.1);
}

.routePoints {
  position: relative;
  left: -1px;
}

.order {
  position: relative;
  margin: 6px 0;
  margin-left: 13px;
  width: calc(100% - 23px);
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
}

.container {
  height: 100%;
  right: 0;
  top: 0;
  transition: 0.2s ease-in-out;
  z-index: 3;
  padding: 11px 17px;
}

.driverPositionBox {
  border: 1px solid #dde5e9;
  border-radius: 4px;
  padding: 8px;
  color: #333333;
  font-size: 14px;
}

.positionalNumber {
  gap: 4px;
  padding: 0px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  align-items: center;
  justify-content: center;
}

.delimeter {
  margin-left: 6px;
  margin-right: 12px;
}
.delimeter:before {
  width: 4px;
  height: 4px;
  background: #C4C4C4;
  border-radius: 2px;
  content: "";
  position: absolute;
  margin-top: 7px;
}

.deliveryPointStatus {
  border-radius: 4px;
  background-color: #01C9D5;
  padding: 2px 6px 2px 6px;
  margin-right: 4px;
}

.plannedCollectionHours {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2px 6px 2px 6px;
  margin-right: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.plannedStopoverTime {
  color: rgba(51, 51, 51, 0.65);
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 4px;
  font-size: 10px;
  position: relative;
  bottom: 0.1px;
}


.stopoverBox {
  background: #0A0621;
  border-radius: 4px;
  gap: 4px;
  padding: 8px;
  color: #ffffff;
}
.stopoverLabel {
  font-size: 14px;
  padding-top: 5px; 
}
.stopoverPlannedTime {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  gap: 4px;
  padding: 2px 6px 2px 6px;
  color: #808080;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.currentPositionBox {
  background: #856AFE;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  gap: 15px;
  padding: 12px 8px;
  color: #ffffff;
}
.currentPositionTitle {
  color: #808080;
  font-size: 12px;
  margin-bottom: 4px;
}
.currentPositionAddress {
  font-size: 18px;
  margin-bottom: 11px;
}
.currentPositionOrderDetails {
  font-size: 14px;
  margin-bottom: 11px;
}
.currentPositionNames {
  color: rgba(255, 255, 255, 0.65);
}
.currentStopoverTime {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 4px;
  font-size: 10px;
  position: relative;
  bottom: 0.1px;
  color: rgba(255, 255, 255, 0.65);
}

.routeEndPoint {
  margin-top: 5px;
  position: relative;
  margin-left: 13px;
  width: calc(100% - 23px);
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
}
.routeEndPointLabel {
  font-size: 12px;
  color: #808080;
}

.dot {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffffff;
  z-index: 2;
}
.purpleDot {
  border: 5px solid #765ee3;
  margin-top: 10px;
}
.greyDot {
  border: 5px solid #BCC0C1;
  margin-top: 10px;
}
.blueDot {
  border: 5px solid #01C9D5;
  margin-top: 10px;
}
.endRouteDot {
  border: 5px solid #333333;
  margin-top: 7px;
}

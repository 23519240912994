.colorLabel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  height: 20px;
  padding: 0 4px;
  border-radius: 4px;
  font-size: 10px;
  letter-spacing: normal;
  text-align: center;
}

.defaultSize {
  width: 16px;
  height: 16px;
}

.defaultColor {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}

.container {
  position: relative;
  height: 38px;
  cursor: pointer;
}

.dateInput {
  display: block;
  width: 100%;
  border: 1px solid transparent;
  height: 38px;
  border-radius: 5px;
  align-items: center;
  padding-left: 10px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: listbox;
  outline: none;
  position: relative;
}
.dateInput:hover {
  border: 1px solid #ddd;
}
.dateInput:focus {
  border: 1px solid #765ee3;
  outline: none;
}

.dateInputWrapper {
  width: 100%;
  display: block;
}

.calendarIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-56%);
  right: 8px;
}
.disabledIcon {
  cursor: not-allowed;
}
.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-56%);
  right: 8px;
}

.popper {
  z-index: 22;
}
.commonLabel {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 12px;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  background: #fff;
  z-index: 1;
}
.commonInput {
  border: 1px solid #ddd;
}
.commonInput:focus {
  box-shadow: #765ee3 0px 0px 0px 0;
  outline: none;
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

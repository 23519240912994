.header {
  padding: 0 15px;
  border-bottom: 1px solid var(--neutralBlack12, rgba(0, 0, 0, 0.12));
  border-left: 1px solid rgba(0, 0, 0, 0); 
  border-right: 1px solid rgba(0, 0, 0, 0); 
  position: sticky;
  z-index: 2;
  top: 0;
}

.fillWidthColumn {
  padding-right: 0 !important;
}

.ticketHeader {
  align-items: center;
  display: flex;
  gap: 8px;
  height: 24px;
  justify-content: space-between;
  overflow-x: scroll !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ticketHeader::-webkit-scrollbar {
  display: none;
}

.ticketAttributes {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 8px;
  height: 18px;
  overflow-x: scroll !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ticketAttributes::-webkit-scrollbar {
  display: none;
}

.searchInput {
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
}
.searchBarList {
  background: #ffffff;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.15), 0px -1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px 0px 0px;
}

.container {
  width: 100%;
}

@media (max-width: 1535px) {
  .manufacturerButton {
    width: 160px;
    min-width: 60px;
  }
  .manufacturerButton input {
    width: 160px;
  }
  .dateRangeButton {
    width: 180px;
    min-width: 150px;
  }
  .dateRangeButton input {
    width: 150px;
  }
}
@media (min-width: 1620px) {
  .manufacturerButton {
    width: 270px;
    min-width: 250px;
  }
  .manufacturerButton input {
    width: 160px;
  }
  .dateRangeButton {
    width: 270px;
    min-width: 250px;
  }
  .dateRangeButton input {
    width: 150px;
  }
}

.link {
  display: inline-flex;
  color: var(--blue200);
}
.link:hover {
  color: var(--blue200);
  opacity: 0.8;
}

.linkDark {
  max-width: 100%;
  display: inline-flex;
  color: var(--neutralBlack88);
}

.linkDark:hover {
  color: var(--blue200);
  opacity: 0.8;
}

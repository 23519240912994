.filter {
  position: relative;
  padding: 3px 0;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  margin-right: 2px;
  z-index: 5;
}
.filter:hover {
  background: #eee;
}
.filterLabel {
  font-size: 0.7rem;
  font-weight: 400;
  color: #757575;
  white-space: nowrap;
}
.selected {
  font-size: 0.7rem;
  font-weight: 400;
  color: #000;
  white-space: nowrap;
}
.optionsList {
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 5px 0;
  min-width: 200px;
  max-width: 310px;
  max-height: 300px;
  overflow-y: auto;
  display: none;
  max-height: 300px;
  overflow-y: auto;
}
.optionsItem {
  padding: 8px 10px;
  font-size: 0.81rem;
  color: #343f40;
  width: 100%;
  text-align: left;
}

.optionFormatting {
  text-transform: lowercase;
}
.optionFormatting::first-letter {
  text-transform: capitalize !important;
}
.optionsItem:hover {
  background: #ddd;
  cursor: pointer;
}
.optionsItem:focus {
  background: #0000001a;
  color: #bbb;
}
@media (max-width: 1279px) {
  .optionsList {
    right: initial;
    left: 0;
  }
}

.search {
  padding: 0px 5px;
  display: flex;
  align-items: center;
  height: 26px;
  background: url(../../../../assets/images/search.svg) no-repeat 98% center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.search:focus {
  outline: none;
  border: 1px solid #856afe;
}

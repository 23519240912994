.createMenu {
  position: absolute;
  width: 220px;
  z-index: 5;
  background: #fefefe;
  overflow: auto;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  transition: 200ms left;
  box-shadow: 2px 5px 30px 4px rgba(0, 0, 0, 0.1), 1px 0px 6px 2px rgba(0, 0, 0, 0.1),
    -2px 4px 8px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 0 20px 7px;
  display: flex;
}
.createMenuInner {
  width: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.createMenuLinks {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.createMenuLinks h5 {
  padding-left: 8px;
  color: #333;
}
.createMenuLinks a {
  padding: 8px;
  color: #555;
  width: 100%;
  border-radius: 4px 0 0 4px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
}
.createMenuLinks a img {
  margin-right: 10px;
}
.createMenuLinks a:hover,
.createMenuLinks a:focus {
  text-decoration: none;
  outline: 0;
  color: #333;
}
.createMenuLinks a:hover {
  background: #efefef;
}
.createMenuLinks a:focus {
  background: #ededed;
}

.btnCircular {
  border-radius: 50px;
  width: 26px;
  min-width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  border: 0;
  background: transparent;
}

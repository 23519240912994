.size12 {
  width: 50%;
}
.size13 {
  width: 33.33%;
}
.groupLabel {
  font-weight: 500;
  font-size: 0.75rem;
  color: #808080;
  display: flex;
  align-items: flex-start;
}
.groupLabel span {
  margin-left: 10px;
}
.groupLabel img {
  width: 16px;
  height: 16px;
}
.groupData {
  margin-left: 28px;
  font-size: 1rem;
}
.titleLight {
  color: hsla(0, 0%, 100%, 0.7);
}
.valueLight {
  color: #fff;
}

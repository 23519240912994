.label {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  line-height: 18px;
  font-size: 0.81rem;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 1.12px 3px;
  color: #fff;
}

.label-color-primary {
  background: #765ee3;
  color: #fff;
}
.label-color-blue {
  background: #01c9d5;
  color: #fff;
}
.label-color-orange {
  background: orange;
  color: #fff;
}
.label-color-yellow-orange {
  background: #d58d01;
  color: #fff;
}
.label-color-red-orange {
  background: #f7743e;
  color: #fff;
}
.label-color-yellow {
  background: #d5cd01;
  color: #fff;
}
.label-color-pink {
  background: #d50180;
  color: #fff;
}
.label-color-grey {
  background: grey;
  color: #fff;
}
.label-color-red {
  background: #ff6e75;
  color: #fff;
}
.label-color-light-grey {
  background: rgba(0, 0, 0, 0.19);
  color: #fff;
}
.label-color-grey-transparent-10 {
  background: rgba(0, 0, 0, 0.1);
  color: #808080;
}
.label-color-dark-red {
  background: #d50e01;
  color: #fff;
}
.label-color-purple {
  background: #5b3fdd;
  color: #fff;
}
.label-color-blueviolet {
  background: #8240c0;
  color: #fff;
}
.label-color-green {
  background: #01d5a2;
  color: #fff;
}
.label-color-darkblue {
  background: #00008b;
  color: #fff;
}
.label-color-darkcyan {
  background: #008b8b;
  color: #fff;
}
.label-color-midblue {
  background: #007bff;
  color: #fff;
}
.label-color-cadetblue {
  background: #5f9ea0;
  color: #fff;
}
.label-color-burlywood {
  background: #deb887;
  color: #fff;
}
.label-color-transparent {
  background: transparent;
  color: #333;
}
.label-color-white {
  background: #fff;
  color: var(--neutralBlack88);
  border: 1.5px solid var(--neutralBlack7);
}
.label-color-transparent-blue {
  background: #1142ea59;
  color: #fff;
}
.label-color-transparent-orange {
  background: #f45b2c8c;
  color: #fff;
}

.label-color-bright-turquoise-35 {
  background: rgba(0, 227, 240, 0.35);
  color: rgba(0, 144, 153, 1);
}

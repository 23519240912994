.modal {
  padding: 15px;
  max-width: 50vw;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.option {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 4px;
  gap: 4px;
  cursor: pointer;
}

.optionActive {
  border: 2px solid #765ee3;
}

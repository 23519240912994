.container {
  position: fixed;
  left: 240px;
  bottom: 0;
  padding-bottom: 20px;
  z-index: 99999;
}
.snackbar {
  border: 0;
  background-color: #765ee3;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 0;
  transform: translateY(200%);
}
.snackbar.snackbarOpened {
  height: initial;
  min-height: 56px;
  transform: none;
}
.snackbar {
  overflow: hidden;
  min-width: fit-content;
  width: fit-content;
  max-width: 620px;
  cursor: pointer;
  opacity: 0;
}
.snackbar a {
  color: #009cdf;
}
.snackbar a:focus,
.snackbar a:hover {
  color: #58c9f9;
}
.snackbar.snackbarOpened {
  height: initial;
  opacity: 1;
  align-items: center;
}
.eventIcon {
  width: 56px;
  min-width: 56px;
  background: #765ee3;
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
}
.eventIcon img {
  display: block;
  width: 70%;
}
.succeed .eventIcon {
  background: #01c9d5;
}
.failure .eventIcon {
  background: #ff6e75;
}
.warning .eventIcon {
  background: #e04177;
}
.snackbarContent {
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
}
.progressBarText {
  width: 35px;
}
.progressBar {
  font-size: 12px;
  font-weight: 300;
  width: 250px;
  height: 15px;
  border: 1px solid #36286d;
  background-color: #36286d;
  border-radius: 8px;
  overflow: hidden;
}
.progressBarInner {
  transition: width 200ms;
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
}
@media (max-width: 767px) {
  .container {
    left: 0px !important;
    right: 0px;
    width: 100%;
  }
  .container .snackbar {
    min-width: 100%;
  }
  .container [class="snackbar snackbarOpened"] ~ .snackbar.toast {
    margin-top: 20px;
  }
  .container [class="snackbar snackbarOpened"] {
    margin-bottom: 0;
  }
}

.wrapper {
  background: #fff;
  flex: 1;
}

.groupLabel {
  color: #808080;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 4px;
}
.timeInputBox {
  width: 90px;
  max-width: 90px;
  margin-right: 0.5rem;
}
.dateInputBox {
  width: 49%;
  max-width: 200px;
}
.datePickerPopup {
  z-index: 22;
  left: -65px !important;
}
.durationSelect {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 5px;
  height: 34px;
  max-width: fit-content;
}

.durationSelect option {
  color: #000;
}

.durationSelect:hover {
  border-color: #ddd;
}

.input {
  border: 1px solid #ddd;
  width: 100%;
  height: 36px;
  border-radius: 4px;
}
.textarea {
  min-height: 80px;
}
.modalHeader {
  font-size: 18px;
  margin-bottom: 20px;
}

.validationInfo {
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: #d58d01;
}

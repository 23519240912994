.sectionLabel {
  font-size: 1rem;
  color: #738992;
}
.sections {
  min-width: 540px;
  height: 100%;
  min-height: 1px;
  min-width: fit-content;
  min-width: -moz-fit-content;
  overflow-y: auto;
  max-height: calc(100vh - 110px);
}
.card {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1), 0 8px 7px 9px rgba(0, 0, 0, 0.05);
  margin: 15px;
  padding: 25px 15px 20px;
}
.fieldsetLabel {
  width: 100%;
  font-size: 0.81rem;
  color: #8b9396;
  font-size: 0.81rem;
  font-weight: 400;
  line-height: 1.2;
  text-transform: capitalize;
}

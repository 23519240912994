.addStartingPointPaper {
  border: 1px dashed var(--neutralBlack24);
}

.addStartingPointPaper:hover {
  background: var(--neutralBlack7);
  outline: 0;
}

.avatarBox {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
}

.shippingServices {
  max-height: 45vh;
  overflow: auto;
}

.courierLogo {
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  width: 100%;
}

.courierLogoWrapper {
  border: 2px solid var(--neutralBlack16);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin: 2px;
  position: relative;
  overflow: hidden;
  display: flex;  
  align-items: center;
  justify-content: center;
  background: var(--neutralWhite100);
}

.defaultPickupPointWrapper {
  position: relative;
  background-color: var(--neutralWhite100);
  border-radius: 50%;
  border: 2px solid var(--deepPurple300);
}

.addPickupPoint {
  position: relative;
  background-color: var(--neutralWhite100);
  border-radius: 50%;
  z-index: 20;
}

.listCourierLogoWrapper {
  border: 1px solid var(--neutralBlack16);
  border-radius: 50%;
  height: 26px;
  width: 26px;
}

.defaultBadge {
  background-color: var(--deepPurple400);
  border: 2px solid var(--neutralWhite100);
  border-radius: 8px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
}

.addressToConfirm {
  border: 1px solid var(--success400);
  border-radius: 4px;
}

.leftToolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
  gap: 4px;
  width: 50px;
  height: calc(100vh - 64px);
  background: #1a1a1a;
  border-width: 1px 0px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.15);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  scroll-behavior: smooth;
}
.buttonSquare {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonSquare:hover {
  background: rgba(255, 255, 255, 0.1);
}
.buttonSquare:focus {
  background: rgba(255, 255, 255, 0.3);
}

.galleryContainer {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 51px;
  padding: 0px 30px;
  display: flex;
  flex: 1;
}

.gallery {
  position: absolute;
  display: flex;
  align-items: center;
  max-width: calc(100% - 60px);
  padding: 8px 0;
  overflow: auto;
  top: 50%;
  transform: translateY(-50%);
}

.prevBtn {
  left: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.nextBtn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

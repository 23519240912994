.wrapper {
  height: 100vh;
  overflow: hidden;
}
.listGroup {
  position: relative;
}
.listGroup:before {
  content: "";
  position: absolute;
  width: 8px;
  top: 10px;
  bottom: 0;
  left: 4px;
  background: #dde5e9;
  z-index: -2;
}
.item {
  position: relative;
  display: flex;
  margin-left: 20px;
  width: calc(100% - 20px);
}
.listGroup .item:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 4px solid #765ee3;
  background: #ddd;
  position: absolute;
  left: -19px;
  top: 10px;
  z-index: 1;
}
.item .travel-time {
  font-size: 0.75rem;
  color: #777;
  text-align: right;
  font-weight: 500;
}
.itemAddress {
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}
.card {
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07), 0px 2px 6px 2px rgba(0, 0, 0, 0.055);
  border-radius: 4px;
  padding: 8px 15px 12px;
  max-width: 75%;
}
.event {
  margin-bottom: 4px;

  font-size: 0.9rem;
}
.event span {
  display: inline-flex;
  padding: 1px 3px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.07);
  margin: 0 2px;
}
.avatar {
  width: 22px;
  min-width: 22px;
  height: 22px;
  margin: 0;
  margin-right: 5px;
  overflow: hidden;
  background-color: #dde5e9;
  border-radius: 50%;
}
.avatar img {
  display: block;
  max-width: 100%;
}
.name {
  color: #808080;
  font-size: 0.75rem;
}
.eventType {
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.itemTiming {
  min-width: 74px;
  padding-top: 10px;
}
.itemDate {
  font-size: 0.75rem;
}
.itemTime {
  font-size: 0.65rem;
  font-weight: 500;
  color: #808080;
}
.inputLabel {
  color: #738992;
}
.info {
  color: #738992;
}
.smallBtn {
  padding: 8px 10px;
  margin-left: 10px;
}
.greenText {
  color: #00e6c2;
}
.textarea {
  display: block;
  width: 100%;
  background: #eee;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  border: none;
}
.greyCheckbox {
  background-color: #738992;
}
.greyCheckbox:hover {
  cursor: default;
}
.greyCheckboxWrapper:hover .greyCheckbox {
  background-color: #738992;
}

.divider {
  width: 4px;
  height: 4px;
  background: #9a9a9a;
  border-radius: 4px;
  margin: 0 10px;
}

.label {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  height: 20px;
  line-height: 20px;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 0 3px;
  min-width: fit-content;
  color: #fff;
  font-size: 0.75rem;
}
.createdLabel {
  font-size: 12px;
  color: #808080;
}

.orderDate {
  font-size: 0.75rem;
  color: #808080;
  font-style: italic;
}
.main {
  display: grid;
  grid-template-columns: 20% auto 30%;
  gap: 10px;
  margin-top: 30px;
}
.section {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 5px 5px 2px rgb(0 0 0 / 10%), 0 8px 7px 9px rgb(0 0 0 / 5%);
  margin: 15px;
  padding: 25px 15px 20px;
}
.header {
  height: 70px;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
}

.title {
  color: #808080;
  font-size: 0.9rem;
  margin-bottom: 5px;
}
.itemsContainer {
  font-size: 0.95rem;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 20px;
  margin-left: 20px;
  font-weight: bolder;
  color: #272727;
}

.attribute {
  padding: 2px 4px 1px;
  border: 1px solid #ddd;
  background-color: #d0d0d6;
  border-radius: 3px;
  margin: 3px 2px 3px 0;
  font-size: 12px;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: min-content;
}
.attribute span {
  margin-right: 2px;
  white-space: nowrap;
  font-weight: normal;
  color: #444;
}
.attribute strong {
  font-weight: 700;
  white-space: nowrap;
}

.attributesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
}
.dottedBorder {
  border-bottom: 1px dotted #212529;
}
.confirmSection {
  max-height: 110px;
  height: 110px;
}
.orderSignature {
  color: #212529;
  font-size: 13px;
}
.orderSignature:hover {
  color: #01c9d5;
}
.statusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
}
.confirmBtn img {
  filter: brightness(2.5);
  margin-right: 10px;
}

.history {
  max-height: calc(100vh - 100px);
  padding-bottom: 50px;
  overflow: scroll;
}
.indexes {
  max-height: calc(100vh - 250px);
  padding-bottom: 30px;
  overflow: scroll;
}
.confirmedIcon {
  width: 22px;
}

.cloud {
  position: relative;
  background: #323138;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0px 5px 11px 1px rgb(0 0 0 / 24%), -1px 1px 6px 0px rgb(113 82 255 / 64%);
  padding: 10px;
  margin-right: 75px;
  margin-bottom: 8px;
}
.cloud:before {
  content: "";
  position: absolute;
  right: -6px;
  top: calc(50% - 9px);
  display: block;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-width: 0 9px 9px 0;
  border-bottom-color: #323138;
}
.cloud:after {
  content: "";
  position: absolute;
  right: -6px;
  top: 50%;
  display: block;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-width: 9px 9px 0 0;
  border-top-color: #323138;
}

.btnBox {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.btnBox button {
  width: 50px;
  height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  background: radial-gradient(#654ae2, #5b3fdd);
  box-shadow: 0px 9px 23px 3px rgb(127 94 216 / 46%), 0px 12px 20px -6px rgb(166 82 255 / 64%);
}
.btnBox button img {
  filter: contrast(0.5) brightness(2.5);
}
.btnBox button:hover {
  background: darkviolet;
}
.btnBox button:focus {
  background: rgb(122, 4, 173);
  outline: none;
}

.communicatorWrapper {
  position: fixed;
  right: 20px;
  bottom: 12px;
}
.communicatorWrapper,
.communicator {
  width: 30%;
  min-width: 320px;
  max-width: 480px;
}

.availabilityDiode {
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #323138;
  background: #999;
}
.avatarBigger .availabilityDiode {
  bottom: -1px;
  right: -1px;
}
.availabilityDiodeOn {
  background: darkturquoise;
}
.availabilityDiodeWhite {
  border: 2px solid #fff;
}

.helloMessage {
  color: #fff;
  font-size: 0.9rem;
}

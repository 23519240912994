.input {
  border-radius: 4px;
  padding: 0px 10px;
  border: 1px solid #ffffff26;
  color: var(--neutralWhite100) !important;
  font-family: "Sofia Sans", sans-serif !important;
  min-width: 260px !important;
}

.searchBar {
  max-width: 260px !important;
  min-width: 260px !important;
  width: 260px !important;
}

.selectedItem {
  border: 1px solid #ffffff26;
  color: var(--neutralWhite100) !important;
}

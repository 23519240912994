.wrapper {
  position: fixed;
  z-index: 2;
  top: 85px;
  left: 70px;
  background-color: rgb(38 38 38);
  min-width: 300px;
  min-height: 88px;
}

.wrapperForOpenedNavbar {
  left: 310px;
}

.wrapperForClosedNavbar {
  left: 70px;
}

.slideButton {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 28px;
  height: 42px;
  cursor: pointer;
  background-color: transparent;
}

.slideButton div {
  position: relative;
  display: none;
}

.slideButton:hover div {
  display: block;
  opacity: 1;
}

.slideButton div span {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px 3px 6px;
  border-radius: 10px;
  top: -12px;
}

.slider {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-shadow: inset 0 1px 0 0 #ddd, inset 0 -1px 0 0 #ddd;
  background-color: #ffffff !important;
  border: 6px solid #765ee3;
}

.railOuterStyle {
  position: absolute;
  width: 100%;
  height: 42px;
  transform: translate(0%, -50%);
  border-radius: 7px;
  cursor: pointer;
}

.railInnerStyle {
  position: absolute;
  width: 100%;
  height: 14px;
  transform: translate(0%, -50%);
  border-radius: 9px;
  pointer-events: none;
  border: 1px solid #ddd;
}

.tick {
  position: absolute;
  margin-top: 12px;
  width: 1px;
  height: 4px;
  background-color: rgba(200, 200, 200, 0.8);
}

.tickLabel {
  position: absolute;
  margin-top: 20px;
  font-size: 12px;
  text-align: center;
}

.track {
  position: absolute;
  transform: translate(0%, -50%);
  height: 14px;
  z-index: 1;
  border-radius: 9px;
  cursor: pointer;
}

.routesList {
  max-height: 50vh;
  overflow: scroll;
}
.routeRow {
  border-top: 1px solid #ddd;
}
.checkbox {
  margin-right: 5px;
}

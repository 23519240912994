.header {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  z-index: 3;
  min-height: 64px;
  height: 64px;
  background: #1a1a1a;
  color: #fff;
}

.input {
  width: 500px;
}


.fillCheckbox {
  border: 1px solid var(--neutralWhite48) !important;
}

.fillLabel {
  color: var(--neutralWhite48) !important;
}

.fillText {
  letter-spacing: -0.48px;
}

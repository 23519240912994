.overlay {
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}

.overlay h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.noBackground {
  background: none;
}

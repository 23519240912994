.status {
  position: relative;
}
.statusIcon {
  margin-right: 2px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}
.statusIcon img {
  display: block;
  width: 12px;
  filter: brightness(1);
}
.statusPassed .statusIcon {
  background: rgba(94, 201, 213, 0.5);
}
.statusInProgress .statusIcon {
  display: flex;
  background: #5ec9d5;
  opacity: 1;
  width: 22px;
  height: 22px;
}
.statusInProgress .statusIcon img {
  width: 16px;
}
.statusesFlow {
  display: flex;
  align-items: center;
}

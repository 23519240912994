.filters {
  display: flex;
  gap: 16px;
  height: 44px;
  align-items: baseline;
}

.monthsFilter {
  align-items: center;
  display: flex;
  gap: 4px;
  height: 34px;
}

.selectableMonthTag {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.customDates {
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  padding-bottom: 12px;
}

.datePickersContainer {
  align-items: center;
  display: flex;
  gap: 16px;
  padding: 5px 0px 0px 0px;
}

.routeRow {
  border: none !important;
  margin-top: 6px;
  margin-bottom: 6px;
}

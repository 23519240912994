.comment {
  margin-left: 12px;
}
.commentBy {
  color: #8f969f;
  font-size: 0.9rem;
}
.commentDate {
  display: inline-flex;
  font-size: 0.9rem;
  align-items: center;
}
.commentDate span {
  color: #8f969f;
}
.body {
  font-size: 1rem;
  word-break: break-word;
}
.textarea {
  border: 1px solid transparent;
  background: #e1e7e9;
  border-radius: 20px;
  line-height: 28px;
  padding: 2px 10px;
  overflow: hidden;
  font-size: 1rem;
  width: 100%;
  outline: none;
  border: 1px solid #5a3fb5;
}
.popoverMenu {
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 0;
}
.popoverBtn {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 0;
  justify-content: flex-start;
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 15px;
  gap: 10px;
  width: 100%;
  min-width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 12px;
}

.datePickerInput {
  max-height: 28px;
  font-weight: 700;
  max-width: 134px;
}

.datePickerPopup {
  z-index: 22;
}

.tableRow:hover {
  background: #edf0f9;
}
.tableRow:active {
  background: #d9e0f1;
}

.tableHeaderOrderSummary,
.tableRowOrderSummary {
  display: grid;
  grid-template-columns: 15px 2fr 50px 40px 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.tableRowOrder,
.tableHeaderOrder {
  display: grid;
  grid-template-columns: 15px 3fr 50px 50px 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.tableRowOrder .cellFixHeight {
  height: 30px;
  min-height: 30px;
  max-height: 30px;
}

.productName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.correctionReason {
  margin-left: 28px;
}

.correctionReason input {
  height: 22px;
}

.input {
  height: 28px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  margin-left: 22px;
}

.option {
  display: block;
  position: relative;
  padding: 4px 10px 4px 0;
  padding-left: 30px;
  margin-bottom: 0;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.95rem;
  user-select: none;
  border-radius: 4px;
}
.input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.input {
  width: auto;
}
.option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.radioMark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 3px;
  background: rgba(155, 155, 155, 0.6);
}
.radioMark {
  border-radius: 50%;
}
.radioMark:after {
  content: "";
  position: absolute;
  display: none;
}
.option input:checked + div .radioMark {
  background-color: #5a3fb5;
}
.option:hover input + div .radioMark {
  background-color: #97adff;
}
.option input:checked + div .radioMark:after {
  display: block;
}
.option--inactive input:checked + div .radioMark,
.option--inactive .radioMark,
.option--inactive,
.option--inactive:hover,
.option--inactive:focus {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent;
}
.option .radioMark:after {
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
}
.radioMark:after {
  content: "";
  position: absolute;
  display: none;
}
.mainLabel {
  font-size: 1rem;
  color: #738992;
}

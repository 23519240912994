.statusIcon.active {
  background-color: #01c9d5;
}
.statusIcon {
  margin-right: 2px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}
.img {
  width: 14px;
}

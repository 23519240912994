.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  width: 100%;
  min-width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
  align-items: flex-end;
}

.tableHeader,
.tableRow {
  display: grid;
  grid-template-columns: 20px 2fr 160px 1fr 50px 90px 90px 120px 70px 90px 70px 70px 1fr;
  padding: 4px 15px;
  gap: 10px;
}
.tableHeaderBok,
.tableRowBok {
  grid-template-columns: 16px 170px 120px 180px 80px minmax(100px, 1fr) 80px 160px 70px 100px 100px 100px 70px 70px;
}
.tableHeaderBokOrder,
.tableRowBokOrder,
.tableHeaderOrderSummary,
.tableRowOrderSummary,
.tableRowOrder,
.tableHeaderOrder {
  grid-template-columns: 15px 2fr 50px 40px 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.tableHeaderBokOrder,
.tableRowBokOrder {
  grid-template-columns: 15px 1fr 1fr 35px 35px 70px;
}
.productName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tableRow div {
  position: relative;
}


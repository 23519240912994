.wrapper {
  box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.03), 0px 3px 5px 2px rgba(0, 0, 0, 0.06);
  height: 100vh;
  width: 440px;
  max-width: 440px;
  min-width: 440px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.headerWrapper {
  padding: 11px 15px 0;
  background-color: #1a1a1a;
  height: 85px;
  max-height: 85px;
  min-height: 85px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.signature {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
}

.tabBtn {
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.5;
  padding: 5px 0;
  margin-right: 20px;
  position: relative;
}

.tabBtn:hover {
  opacity: 0.8;
}

.tabBtnActive {
  opacity: 1;
  color: #fff;
}

.tabBtnActive:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background: #01c9d5;
  bottom: 0;
  left: 0;
}
.routesLink:hover {
  opacity: 0.8;
  color: #01c9d5;
}
.btnDraft {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.43);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnReady {
  box-sizing: border-box;
  padding: 8px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #01c9d5;
  color: #fff;
  border-color: #01c9d5;
}
.btnReady:hover,
.btnDraft:hover {
  opacity: 0.7;
}

.btnReady img {
  filter: contrast(0%) brightness(200%);
}
.orderUpdateModalContainer {
  width: 30vw;
  max-width: 30vw;
  padding: 15px;
  border-radius: 4px !important;
}
.orderUpdateModalMargin {
  margin: 2vh auto;
}
.orderPaymentUpdateModalMargin {
  margin: 10vh auto;
}
.localizeBtn {
  position: absolute;
  left: 10px;
  top: 10px;
}

.dropdownList > li[disabled]:hover {
  background-color: #fff !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.selected {
  opacity: 0.6;
}

.selects {
  display: flex;
  justify-content: space-between;
}

.background {
  height: calc(100vh - 86px);
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}

.header {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0;
}

.subHeader {
  text-align: center;
  font-weight: 400;
  color: var(--black-6, #808080);
}

.textWrapper {
  margin-bottom: 5rem;
}

.btnContainer {
  padding: 16px 15px;
}

.sectionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 16px 16px 8px 16px;
  align-self: stretch;
}

.stateLabel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  border-radius: 4px;
  letter-spacing: normal;
  text-align: center;
  font-family: "Sofia Sans", sans-serif;
}

.small {
  font-size: 10px;
  min-width: 20px;
  height: 20px;
}

.defaultKind {
  border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
}

.orangeDark {
  background: var(--orange-4, #ee9e4b);
  color: #333;
}

.bg-grey-10 {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}

.grey {
  border: 1px solid var(--black-6, #808080);
  color: var(--black-6, #808080);
}

.green {
  border: 1px solid var(--teal-6, #55bca6);
  color: var(--teal-6, #55bca6);
}

.darkGreen {
  background: var(--teal-4, #6de5b9);
  color: var(--teal-8, #1e4c40);
}

.cyan {
  border: 1px solid var(--cyan-5, #01c9d5);
  color: var(--cyan-5, #01c9d5);
}

.red {
  border: 1px solid var(--red-6, #c3291c);
  color: var(--red-6, #c3291c);
}
.bg-red-2 {
  background: var(--red-2, #f7cfd3);
  color: var(--red-6, #c3291c);
}
.bg-red-4 {
  background: var(--Red-4, #e26b77);
}
.red-4 {
  border: 1px solid var(--Red-4, #e26b77);
  color: var(--Red-4, #e26b77);
  font-weight: 700;
  letter-spacing: -0.03rem;
}
.darkRed {
  border: 1px solid #8a0c03;
  color: #8a0c03;
}
.orange {
  border: 1px solid var(--orange-4, #ee9e4b);
  color: var(--orange-4, #ee9e4b);
}
.deepOrange3 {
  background: var(--Deep-Orange-3, #f08f6d);
  color: var(--Deep-Orange-8, #6d2714);
}

.blue {
  background: var(--Blue-6, #4696ec);
  color: #fff;
}

.lightBlue {
  background: var(--Blue-1, #e6f2fc);
  color: var(--Blue-6, #4696ec);
}

.limeBlack {
  background: var(--lime-5, #d0db59);
  color: #333;
}

.black-6 {
  border: 1px solid var(--black-6, #808080);
  color: var(--black-6, #808080);
}

.teal-6 {
  border: 1px solid var(--teal-6, #55bca6);
  color: var(--teal-6, #55bca6);
  font-weight: 700;
  letter-spacing: -0.03rem;
}

.violetWhite {
  background: var(--deep-purple-5, #8c73ff);
  color: #fff;
}

.indigo {
  background: var(--indigo-10, rgba(57, 101, 255, 0.1));
  color: var(--Indigo-6, #3d55dc);
}

.transparentViolet {
  border: 1px solid var(--deep-purple-5, #8c73ff);
  color: var(--deep-purple-5, #8c73ff);
}

.bg-teal-2 {
  background: var(--Teal-2, #bbdedb);
  color: var(--teal-8, #1e4c40);
}

.bg-black5 {
  background: var(--black-5, rgba(0, 0, 0, 0.05));
}

.darkYellowBlack {
  background: var(--yellow-5, #e4b700);
  color: #333;
}
.deepPurple {
  background: var(--deep-purple-1, #e7e1ff);
  color: var(--deep-purple-5, #8c73ff);
  border: solid 1px transparent;
}

.bg-yellow-2 {
  background: var(--Yellow-2, #f6e8ad);
  color: var(--Yellow-7, #a18200);
}

.bg-yellow-5 {
  background: var(--yellow-5, #e4b700);
  color: #333;
}

.bg-deep-orange-4 {
  background: var(--Deep-Orange-4, #e57458);
  color: var(--Deep-Orange-8, #6d2714);
}

.deepPurple img {
  width: 16px;
}
.defaultOpacity {
  opacity: 0;
}

.lowOpacity {
  opacity: 0.35;
}

.mediumOpacity {
  opacity: 0.5;
}

.highOpacity {
  opacity: 0.75;
}

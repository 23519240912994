.container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.titleWrapper {
  margin: 20px 15px;
}
.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #000000;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0;
  margin-right: 10px;
  background-color: #856afe;
}

.name {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.signature {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 5.5px 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #333333;
}

.header {
  background: #f5f5f5;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer {
  background: #f5f5f5;
  padding: 10px 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.main {
  overflow: auto;
  padding: 20px;
  flex-grow: 1;
}

.content {
  margin-left: 38px;
  max-width: 85%;
}

.date {
  font-family: "Cabin", monospace;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  margin-bottom: 6px;
  color: #808080;
}

.body {
  background: #f6f8f9;
  border-radius: 15px 15px 15px 2px;
  padding: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 6px;
  max-width: 100%;
}

.msgName {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  color: #333333;
}

.item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.msgNameInfo {
  display: flex;
  align-items: center;
}

.reversed {
  display: flex;
  align-items: flex-end;
}

.reversed .msgNameInfo {
  display: flex;
  flex-direction: row-reverse;
}

.reversed .avatar {
  margin-right: 0;
  margin-left: 10px;
}

.reversed .content {
  margin-right: 38px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.reversed .body {
  border-radius: 15px 15px 2px 15px;
  background: #856afe;
  color: #fff;
}

.inputContainer {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;
}

.input {
  border: none;
  outline: none;
  margin-right: 6px;
  flex: 1;
}

.inputIcon {
  cursor: pointer;
}
.textarea {
  resize: none;
  background-color: transparent;
  border-radius: 10px;
  outline: none;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  margin-right: 10px;
  min-height: 41px;
  max-height: 314px;
}
.textarea:focus {
  border: 1px solid #765ee3;
}

.dropdownBtn {
  border-radius: 0 8px 8px 0 !important;
  background: #0000000d;
  border: 1px solid #0000000d;
  border-left: none;
  padding: 8px !important;
  height: 38px !important;
}

.dropdownBtn:hover {
  background: rgba(0, 0, 0, 0.12) !important;
  border-radius: 0 8px 8px 0 !important;
}

.submitBtn {
  display: flex;
  align-items: center;
  padding: 0;
}

.submitBtn button {
  border-radius: 8px 0 0 8px;
  height: 38px !important;
}

.error .date span {
  color: #e87076;
}
.error .body {
  background: rgba(232, 112, 118, 0.35);
}

.errorMsg {
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  padding: 4px;
  color: #ce656b;
  background: rgba(232, 112, 118, 0.35);
  border-radius: 4px;
  margin-bottom: 6px;
}
.errorMsg img {
  margin-right: 6px;
}

.error .errorMsg {
  display: inline-flex;
}

.list {
  text-align: left;
  width: 350px !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15), 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 4px 4px !important;
  padding: 0 !important;
  bottom: 150% !important;
}

.list h6 {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;

  margin-bottom: 10px;
  text-align: left;

  color: #808080;
}

.smsTitle {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 4px;
  color: #333333;
}

.padding {
  padding: 10px 15px;
}
.paddingX {
  padding: 0 15px;
}
.margin {
  padding: 10px 15px;
}

.smsBody {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  color: #808080;
  width: 100%;
  text-overflow: ellipsis;
}

.smsItem {
  cursor: pointer;
}
.smsItem:hover {
  background-color: #ddd;
}

.noContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}
.noContent div {
  font-size: 16px;
  line-height: 19px;

  color: #808080;
}
.noContent img {
  margin-bottom: 10px;
}

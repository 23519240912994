.container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
}

.container button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list {
  max-height: 50vh;
  overflow: auto;
  list-style-type: none;
  padding-left: 0;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 6px 10px rgb(0 0 0 / 25%);
  border-radius: 8px;
}
.list .searchLi {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  padding: 10px;
}
.unsearchableList.open {
  padding-top: 10px;
}
.list.open {
  padding-bottom: 10px;
}

.list li {
  padding: 0 10px;
  width: 100%;
  cursor: default;
}
.list li:first-of-type {
  border-top: 1px solid #ccc;
}

.caret {
  width: 10px;
  object-fit: contain;
  margin-left: 5px;
}

/* .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 700;
} */
.btn {
  /* background: url(~assets/images/18.svg) no-repeat; */
  /* background-position-x: calc(100% - 10px); */
  /* background-position-y: center; */
  transition: box-shadow 200ms;
  border-radius: 5px;
  border: none;
  min-width: 150px;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 1rem;
  min-height: 28px;
  padding-right: 30px;
}
/* .editable */
.btnEditable {
  border: 1px solid transparent;
  background-image: none !important;
}
.btn {
  background: url(~assets/images/18.svg) no-repeat !important;
  background-position-x: calc(100% - 10px) !important;
  background-position-y: center !important;
}
.btn.disabled {
  cursor: default;
}
/* .editable */
.btnEditable:not(.disabled):hover {
  border: 1px solid #ccc !important;
  background: url(~assets/images/18.svg) no-repeat !important;
  background-position-x: calc(100% - 10px) !important;
  background-position-y: center !important;
}
/* .rightBtn:focus {
  box-shadow: #765ee3 0px 0px 0px 1px;
  outline: none;
} */

.input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 0 10px 0 10px;
}

.dark .btn {
  background: var(--color-grey1);
}

.dark .btn > span {
  color: #fff;
}

.dark .caret {
  filter: brightness(4);
}
.dark .btn {
  border: 1px solid rgb(0 0 0 / 20%);
}
.input::-webkit-input-placeholder {
  font-weight: 600;
}

.input::-moz-placeholder {
  font-weight: 600;
}

.input::-ms-input-placeholder {
  font-weight: 600;
}

.input:focus {
  border-color: #40a4ff;
  outline: none;
}

.fakeInput {
  width: 0;
  height: 0;
  border: none;
  padding: 0;
}

.fakeInputContainer {
  width: 0;
  height: 0;
  overflow: hidden;
}
.placeholder {
  color: var(--color-grey3);
}
.badge {
  display: inline-flex;
  padding: 2px 2px 2px 4px;
  border-radius: 5px;
  border: 1px solid #dddddd;
  margin-top: 3px;
  font-size: 0.8rem;
  align-items: center;
  justify-content: space-between;
}
.dark .badge {
  border: 0;
  background: rgb(255 255 255 / 10%);
  color: #fff;
}
.badge img {
  width: 9px;
  height: 9px;
}
.badge button:hover {
  transform: scale(1.2);
}
.badge:not(:last-child) {
  margin-right: 5px;
}
.trimCounter {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: inline-flex;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  color: var(--color-blue);
  border: 1px solid var(--color-blue);
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  margin-left: 23px;
  width: calc(100% - 23px);
  cursor: move;
  text-align: left;
  padding: 8px 5px;
  color: #fff;
  border-radius: 4px;
  background: #f3f4f4;
  border: 1px solid #dddddd;
}

 .dragging {
  border: 1px solid #765ee3;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
 .active {
  background: rgba(133, 106, 253, 0.25);
  border: 1px solid rgba(133, 106, 253, 0.25);
  border-radius: 4px;
}

.wrapper:hover .timeRange {
  visibility: hidden;
}

.address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 6px;
  color: #333333;
  width: 146px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.label {
  color: #808080;
  font-weight: 500;
  white-space: nowrap;
  font-size: 10px;
  line-height: 12px;
}
.icon {
  width: 16px;
  height: 16px;
}

.dragImg {
  width: 35px;
  max-width: 35px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.buttons {
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.23);
}
.dotContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.greyDot {
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 4px solid #ccc;
  z-index: 2;
  background: #333;
  margin-left: 2px;
}

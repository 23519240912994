.inputWrapper {
  position: relative;
  min-height: 44px;
  margin-bottom: 1.75rem;
}
.inputBox {
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
}
.inputBox label {
  margin: 0;
}
.labelFloating {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 12px;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  background: #fff;
  z-index: 1;
}
.input {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0 5px;
  background: transparent;
  cursor: pointer;
  height: 44px;
  width: 100%;
}
.input:focus {
  border-color: #765ee3;
  outline: 0;
}
@-webkit-keyframes autofill {
  to {
    color: #333;
    background: transparent;
  }
}

.input:-webkit-autofill {
  -webkit-transition-delay: 99999s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.card {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--neutralWhite100);
  box-shadow: 0px 6px 16px -6px #0000001a;
  border-radius: 4px;
  padding: 16px;
  height: 350px;
  max-height: 350px;
}

.search {
  width: 500px;
}

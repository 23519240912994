.dashboardScrollableContent {
  height: calc(100vh - 40px);
  overflow: scroll;
  align-content: baseline;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
  margin-bottom: 1rem;
}

.mainTable {
  max-width: 65%;
  width: 65%;
}

.ordersTable {
  max-width: 34%;
  width: 34%;
}

.sectionTitle {
  line-height: 20px;
  letter-spacing: -0.01rem;
  margin-bottom: 0;
}

.allBtn {
  max-height: 26px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 1px;
  width: 100%;
}
.mainContentInner {
  position: relative;
  overflow: scroll;
  display: block;
  width: 100%;
}
@media (min-width: 1200px) {
  .mainContentInner {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-grow: 1;
  }
}

.tableHeader {
  display: grid;
  padding: 4px 15px;
  width: fit-content;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
  justify-content: unset;
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  font-size: 12px;
  gap: 10px;
  width: 100%;
  min-width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.tableRow:hover {
  background: #edf0f9;
}
.tableRow:active /* on click */ {
  background: #d9e0f1;
}
.tableRow {
  display: grid;
  padding: 4px 15px;
  gap: 10px;
}
.tableRow .date {
  font-weight: 500;
  font-size: 12px;
}
.list .tableRow {
  font-size: 12px;
}
.tableRow div {
  position: relative;
}

.list .signature,
.subject,
.tableRow .date {
  font-weight: 500;
  font-size: 12px;
}

.rowCurrent {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 0 1px #8c73ff, 0 0 0 0 var(--Deep-Purple-5, #8c73ff);
  background: var(--deep-purple-10, rgba(134, 107, 255, 0.1));
  -webkit-animation: pulse 1.25s infinite;
  animation: pulse 1.25s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 1px #8c73ff, 0 0 0 0 rgba(140, 115, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 1px #8c73ff, 0 0 0 10px rgba(140, 115, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 1px #8c73ff, 0 0 0 0 rgba(140, 115, 255, 0);
  }
}

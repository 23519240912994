.form {
  padding-top: 20px;
}

.sections {
  overflow: auto;
  padding-bottom: 100px;
  min-width: 540px;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 70px);
  min-height: 1px;
  min-width: fit-content;
  min-width: -moz-fit-content;
}
.localizeBtn {
  position: absolute;
  left: 10px;
  top: 10px;
}

.map-drawer {
  min-width: 515px;
  max-width: 515px;
  background: #f5f4ef;
  overflow: hidden;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  position: absolute;
  max-height: calc(100vh - 110px);
  min-height: calc(100vh - 110px);
  height: 100%;
  right: 0;
  top: 40px;
  transition: 0.2s ease-in-out;
  z-index: 4;
}
@media (max-width: 1260px) {
  .map-drawer {
    top: 80px;
  }
}
.closed {
  right: -530px;
}

@media (max-width: 1535px) {
  .closed {
    right: -500px;
  }
  .map-drawer {
    min-width: 485px;
    max-width: 485px;
  }
}
@media (min-width: 1920px) {
  .closed {
    right: -600px;
  }
  .map-drawer {
    min-width: 585px;
    max-width: 585px;
  }
}
.drawer_inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
}

.drawer_header {
  height: 70px;
  position: sticky;
  top: 0;
  background: #f5f4ef;
  z-index: 2;
}
.customSection {
  max-height: 300px;
  overflow-y: auto;
}
.drawer_sections {
  padding-left: 10px;
  padding-right: 20px;
}

.drawer_bottom,
.drawer_section {
  padding: 15px 0;
}
.drawer_section h6 {
  color: #808080;
  font-size: 0.75rem;
}
.timeInputBox,
.dateInputBox {
  width: 48%;
  max-width: 200px;
}
.input {
  color: #333;
  width: calc(100% + 10px);
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 0 -5px;
  padding: 4px 5px;
  background: transparent;
  cursor: pointer;
  height: 34px;
}

.input:focus {
  border-color: #2101d5;
  outline: 0;
}
.input[type="datetime-local"]::-webkit-clear-button {
  opacity: 0.0001;
}
.input[type="datetime-local"]::-webkit-clear-button {
  opacity: 0.0001;
}

.input[type="datetime-local"]::-moz-clear {
  opacity: 0.0001;
}

.input[type="datetime-local"] {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
.input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  opacity: 0.0001;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.inputLabel {
  font-size: 0.75rem;
  color: #808080;
  background: #f5f4ef;
  display: inline-flex;
  padding: 2px;
  position: relative;
  left: -2px;
  top: 10px;
  z-index: 1;
  width: fit-content;
}

.accountLabel {
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: fit-content;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 3px;
  opacity: 0.7;
  margin-right: 6px;
  margin-bottom: 6px;
}
.accountLabelBox input {
  position: absolute;
  visibility: hidden;
}
.accountLabelBox input:checked ~ .accountLabel {
  opacity: 1;
  box-shadow: 0 0 0 2px #5b3fdd;
}
.searchInput {
  border: 1px solid #ddd;
  min-width: 220px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  padding: 0 27px 0 10px;
  background: transparent;
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
}
.searchInput:hover {
  outline: 0;
  box-shadow: 0 0 1px 1px #ccc;
}
.searchInput:focus {
  outline: 0;
  box-shadow: 0 0 1px 1px #765ee3;
}
.clearBtn {
  background-color: #fff;
}

.timeInputBox,
.dateInputBox {
  width: 48%;
  max-width: 200px;
}

.monthsContainer {
  margin-right: 30px;
}

.month {
  border: 1px solid #808080;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  margin-bottom: 10px;
  max-height: 25px;
}

.month:hover {
  background: rgba(0, 0, 0, 0.12);
}

.activeMonth {
  background: rgba(0, 0, 0, 0.15);
  outline: 0;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 0 15px;
  height: 40px;
  width: 100%;
  min-width: 100%;
  position: relative;
  z-index: 4;
}
.searchOptionsBox {
  height: 32px;
  display: inline-flex;
  align-items: center;
  padding: 0 0 0 20px;
  border-radius: 0 20px 20px 0;
  border: 1px solid #ddd;
  border-left: 0;
  position: relative;
  min-width: 110px;
  font-size: 0.85rem;
  left: -12px;
  justify-content: space-between;
  z-index: 2;
}
.filterGroup {
  display: flex;
  align-items: center;
  z-index: 1;
}

.closeIcon {
  width: 10px;
  height: 15px;
}
.menuIcon {
  margin-bottom: 6px;
  line-height: 10px;
}

@media (max-width: 1260px) {
  .toolbar {
    display: block;
    height: 80px;
    padding: 5px 15px;
  }
  .filterGroup {
    margin-top: 5px;
    justify-content: flex-start;
  }
}
.drawerFiltersCount {
  color: #765ee3;
  font-size: 14px;
  margin-right: 7px;
  font-weight: 700;
}

.optionsList {
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 5px 0;
  min-width: 200px;
  max-width: 310px;
  max-height: 300px;
  overflow-y: auto;
  display: none;
  max-height: 300px;
  overflow-y: auto;
}
.optionsItem {
  padding: 8px 10px;
  font-size: 0.81rem;
  color: #343f40;
  width: 100%;
  text-align: left;
  text-transform: lowercase;
}
.optionsItem::first-letter {
  text-transform: capitalize !important;
}
.optionsItem:hover {
  background: #ddd;
  cursor: pointer;
}
.optionsItem:focus {
  background: #0000001a;
}
.optionsItem:global(.active) {
  color: #bbb;
}
@media (max-width: 1279px) {
  .optionsList {
    right: initial;
    left: 0;
  }
}

.upholsteryStatus {
  width: 175px;
  min-width: 175px;
  overflow: hidden;
}

.statuses-flow-box {
  display: flex;
  align-items: center;
}

.status-dot {
  font-size: 11px;
  background: #c3c3c3;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 13px;
  width: 13px;
  border-radius: 3px;
  color: #333;
  margin-bottom: 1px;
}

.done .status-dot {
  background: #01b0b9;
  color: #fff;
}
.status-dot--total {
  width: fit-content;
  width: -moz-fit-content;
  display: inline-flex;
  margin-right: 3px;
  padding: 0 2px;
}
.total {
  font-size: 11px;
  color: #666;
  line-height: 13px;
  display: inline-flex;
}
.statusesLabel {
  display: flex;
  font-size: 10px;
  color: #808080;
}

.wrapper {
  background-color: black;
  height: 100vh;
  width: calc(100vw + 600px);
  overflow: scroll;
}

.wrapperForClosedNavbar {
  margin-left: 60px;
}

.isPanelOpened {
  margin-right: 600px;
}

.container {
  position: relative;
  transform: scale(0.18);
  transform-origin: top left;
}

.background {
  position: absolute;
  background: #000 url(~assets/images/square4.svg) 300px 300px repeat;
}

.margin {
  position: absolute;
  z-index: 1;
}

.menuToolbar {
  position: fixed;
  top: 64px;
  left: 0;
  height: 100%;
  z-index: 55;
}

.toolPanel {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  background: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.panelHeader {
  height: 43px;
}

.approvalBtn {
  color: #333;
  background: var(--teal-4, #6de5b9);
}
.approvalBtn:hover {
  background: var(--teal-9, #82dbba);
}
.approvalBtn:focus {
  background: var(--teal-10, #98d8c0);
}

.toolPanelBody {
  height: calc(100vh - 205px);
  overflow: scroll;
}

.receptionsTitle {
  letter-spacing: -0.015rem;
  line-height: 18px;
}

.tableHeaderPZ {
  grid-template-columns: 15px 2fr 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 20px;
  font-size: 12px;
  color: #808080;
}

.tableRowPZ {
  grid-template-columns: 15px 2fr 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 700;
}

.tableRowPZ:hover {
  background-image: url("assets/images/dragIndicator.svg");
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: 3%;
}

.inaccessible {
  opacity: 0.7;
}
.inaccessible:hover {
  background: inherit;
  cursor: not-allowed;
}

.stripesBgGrey {
  background-image: linear-gradient(
    45deg,
    #eeeeee 5.56%,
    #ffffff 5.56%,
    #ffffff 50%,
    #eeeeee 50%,
    #eeeeee 55.56%,
    #ffffff 55.56%,
    #ffffff 100%
  );
  background-size: 12.73px 12.73px;
}

.packageIsDragged {
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s, border-radius 0.2s;
}

.modal {
  width: max-content;
  min-width: 590px;
  max-width: 1490px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  max-height: 95vh;
  overflow: scroll;
  margin: 2.5vh auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal::-webkit-scrollbar {
  display: none;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.noResultsOverwrite {
  height: 374px;
  max-height: 374px
}

.redLabel {
  color: var(--red-6, #c3291c); 
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
  margin: 0;
}
.avatarSmall {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}
.avatar img {
  display: block;
  max-width: 100%;
}

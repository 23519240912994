.tabs {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0 10px;
}
.tab {
  width: calc(100% - 10px);
  margin: 0 5px;
  height: 100%;
  padding: 10px 0 5px;
  border-bottom: 6px solid #e1e1e1;
  display: inline-flex;
  color: #999;
  border-radius: 0;
  cursor: default;
}
.tab.active {
  border-color: #765ee3;
  color: #765ee3;
}
.tab.passed {
  border-color: #765ee3;
}

.container {
  height: 100vh;
  width: 100vw;
  background: #0b001b;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  z-index: 99;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image {
  width: 60vw;
  margin-top: -50px;
  margin-bottom: 20px;
}

.title {
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.description {
  color: #76707e;
  margin-bottom: 50px;
}
.imageContainer {
  width: 60vw;
  margin-right: 3%;
  height: 70vh;
}

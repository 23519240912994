.round {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.09);
  overflow: hidden;
}
.round img {
  max-width: 100%;
  height: auto;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.container:not(.disabled):hover .checkMark {
  background: #97adff;
}

.input {
  display: none;
  opacity: 0;
  cursor: pointer;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  min-height: 19px;
  height: 100%;
}

.checkMark {
  position: relative;
  height: 19px;
  min-height: 19px;
  min-width: 19px;
  width: 19px;
  border: 0;
  border-radius: 3px;
  background: rgba(155, 155, 155, 0.6);
}

.checkMark:hover {
  cursor: pointer;
}

.checkMarkActive {
  background: #765ee3;
}

.checkMarkActive::after {
  position: absolute;
  content: "";
  left: 7px;
  top: 2px;
  width: 6px;
  height: 13px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.active {
  top: -20px;
}
.label {
  font-size: 0.9rem;
  margin-left: 8px;
  cursor: pointer;
}
.disabled .checkMark:hover {
  cursor: not-allowed;
}

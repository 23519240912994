.showLoader {
  min-width: 18px;
}

.selectedPlaceholder::placeholder {
  color: var(--neutralWhite100) !important;
}

.searchList {
  position: absolute;
  width: 500px;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 32px 32px -12px #00000040;
  background: var(--neutralWhite100);
  overflow: scroll;
  max-height: 90vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.searchList::-webkit-scrollbar {
  display: none;
}

.tags {
  border-bottom: 1px solid var(--neutralBlack12);
}

.selectedRow {
  background: var(--deepPurple50);
}

.datePickerContainer {
  max-height: 28px;
}
.datePickerInput {
  max-height: 28px;
  font-weight: 700;
  font-size: 14px;
  padding-left: 5px;
}

.datePickerPopup {
  z-index: 22;
}

.input {
  height: 28px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
}

.moreOptions {
  background: rgba(255, 255, 255, 0.15);
}

.tooltip {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px 2px, rgba(0, 0, 0, 0.05) 0px 8px 7px 9px;
  border-radius: 8px !important;
  pointer-events: auto !important;
  opacity: 1 !important;
  padding: 0 !important;
}

.tooltip:hover {
  visibility: visible !important;
}

.tooltip:global(.__react_component_tooltip.place-left)::before {
  top: 82% !important;
}
.tooltip:global(.__react_component_tooltip.place-left)::after {
  top: 82% !important;
}

.tooltipContainer {
  width: 290px;
  height: 80vh;
  overflow: auto;
}

.pdf {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  font-size: 0.81rem;
  color: #343f40;
  width: 100%;
  text-align: left;
  font-size: 14px;
}

.pdf:hover {
  background: #ddd;
  cursor: pointer;
  color: #343f40;
}

.pdf img {
  margin-right: 10px;
}

.label {
  padding: 10px 15px 0 15px;
  margin-bottom: 5px;
  font-size: 16px;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
  cursor: not-allowed;
}

.createOrder {
  padding: 0 15px 10px 15px;
  margin-top: 5px;
}

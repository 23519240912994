.selectedPlaceholder-light::placeholder {
  color: var(--neutralBlack88);
}
.selectedPlaceholder-dark::placeholder {
  color: var(--neutralWhite100);
}

.selected {
  background-color: var(--deepPurple75);
}

.disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.disabled input {
  cursor: not-allowed !important;
}

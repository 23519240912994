.react-datepicker__triangle
.react-datepicker__triangle::before,
.react-datepicker__triangle::after { 
  display: none !important;
}

.popper {
  z-index: 52;
}

.customDateInput {
  width: 164px;
  height: 38px;
}

.customDateInput:hover {
  cursor:  pointer !important;
}

.customDateInput input {
  cursor:  pointer !important;
}

.disabledDateInput input {
  cursor: not-allowed !important;
}

.calendarContainer {
  background-color: var(--neutralWhite100) !important;
  border: 1px solid var(--neutralBlack7) !important;
  border-radius: 8px;
  box-shadow: 0px 32px 32px -12px #0000001F;
  gap: 8px;
  min-height: 338px;
  padding: 10px 20px;
  top: -6px;
  width: 334px;
}

.day {
  align-items: center;
  border-radius: 4px !important;
  display: flex;
  height: 42px !important;
  justify-content: center;
  width: 42px !important;
}

.day:hover {
  background-color: var(--deepPurple75);
}

.selectedDay {
  background-color: var(--deepPurple400) !important;
  color: var(--neutralWhite100) !important;
}

.today {
  border: 2px solid var(--deepPurple400) !important;
  background-color: var(--neutralWhite100);
}

.today:hover {
  border: none !important;
  background-color: var(--deepPurple75) !important;
}

.selectedDayInAnotherMonth {
  background-color: var(--neutralWhite100) !important;
}

.selectedDayInAnotherMonth:hover {
  background-color: var(--deepPurple75) !important;
}

.header {
  background-color: var(--neutralWhite100) !important;
  align-items: center;
  display: flex;
  gap: 10px;
  height: 42px;
  justify-content: center;
  width: 294px !important;
}

.monthPicker {
  border-radius: 4px;
  width: 150px !important;
  cursor: pointer !important;
}

.monthPicker:hover {
  background-color: var(--neutralBlack7);
}

.monthPicker input {
  cursor: pointer;
}

.monthPicker div {
  border: none !important;
  cursor: pointer !important;
}

.new-date-picker :global(.react-datepicker__month-container) {
  background-color: var(--neutralWhite100) !important;
}

.new-date-picker :global(.react-datepicker__day-names) {
  background-color: var(--neutralWhite100);
  height: 42px !important;
  display: inline-flex;
  align-items: center;
}

.new-date-picker :global(.react-datepicker__header) {
  padding: 0 !important;
}

.new-date-picker :global(.react-datepicker__day--outside-month) {
  color: var(--neutralBlack24) !important;
}

.new-date-picker :global(.react-datepicker__month) {
  width: 294px !important;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0;
  margin-right: 0;
}

.new-date-picker :global(.react-datepicker__day) {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 42px !important;
}

.new-date-picker :global(.react-datepicker__day-name) {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  width: 42px !important;
  height: 42px !important;
}

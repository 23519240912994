.client {
  font-size: 14px;
}

.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.signature {
  font-weight: 500;
  font-size: 12px;
}

.date {
  font-weight: 500;
  font-size: 12px;
}

.listAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
  border: 1px solid #ffffff;
}

.listAvatar img {
  display: block;
  max-width: 100%;
}

.avatarSmall {
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
}

.avatar img {
  display: block;
  max-width: 100%;
}

.assignee {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 700;
}

.subject {
  color: #808080;
  font-size: 12px;
  font-weight: 500;
}

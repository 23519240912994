.container {
  min-width: 450px;
  max-width: 450px;
  background: #edf0f3;
  overflow: auto;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
  max-height: 100vh;
  height: 100%;
  right: 0;
  top: 0;
  transition: 0.2s ease-in-out;
  z-index: 3;
  padding: 11px 17px;
}
.closed {
  right: -100%;
}
.closeButton {
  background-color: #fff;
  box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.03), 0px 3px 5px 2px rgba(0, 0, 0, 0.06);
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
}
.closeButton:hover {
  background-color: #ededed;
}
.closeButton:hover {
  background-color: #ddd;
}
.label {
  font-size: 16px;
  margin-left: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.commentIcon {
  height: 17px;
  width: 17px;
}
/* do wyczyszczenia */
.group {
  position: relative;
}
.group:before {
  content: "";
  position: absolute;
  z-index: 0;
  width: 7px;
  top: 11px;
  bottom: 28px;
  left: 4px;
  background: #dde5e9;
}

.order {
  position: relative;
  margin: 6px 0;
  width: 360px !important;
  margin-left: 23px;
  width: calc(100% - 23px);
  text-align: left;
  padding: 14px 12px;
  background-color: #fff;
  border-radius: 4px;
}

.order:before {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 4px solid #765ee3;
  background: #ddd;
  position: absolute;
  left: -54px;
  top: 4px;
}

.order:hover {
  cursor: pointer;
  background: #fcfcfc;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15), 0px 5px 12px rgba(0, 0, 0, 0.15);
}

.order:focus,
.selected {
  box-shadow: 0 0 0 1px #765ee3;
}

.pointWrapper {
  position: relative;
}

.routePoints {
  position: relative;
  left: -1px;
}
.infoContainer {
  font-weight: 700;
}
.counter {
  position: absolute;
  top: 0;
  left: 22px;
  margin-top: 6px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d7d7d7;

  border-radius: 9px;
  height: auto;
  line-height: 17px;
}
.notConfirmed {
  color: #c3291c;
  width: 130px;
}
.confirmed {
  color: #55bca6;
}

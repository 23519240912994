.contentScroll {
  max-height: calc(100vh - 88px);
  overflow: scroll;
}
.filterBarOpened {
  max-height: calc(100vh - 88px - var(--filter-bar-height));
}

.listTable {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: none;
  flex: 1 1 auto;
  min-width: 390px;
  overflow: hidden;
  width: 100%;
  font-family: "Sofia Sans", sans-serif;
}

.listHeader {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01rem;
  font-family: "Sofia Sans", sans-serif;
  color: rgb(33, 37, 41);
  margin-bottom: 0;
}

.summaryAmountDecimal {
  font-size: 20px;
  color: black;
}

.paginationBackground {
  background: transparent;
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}

.tableHeaderWarehouseState,
.tableRowWarehouseState {
  letter-spacing: -0.03rem;
  padding: 4px 15px;
  gap: 10px;
  display: grid;
  grid-template-columns:
    20px 280px 120px 120px minmax(120px, 180px) minmax(120px, 180px)
    minmax(120px, 180px);
  justify-content: flex-start;
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 1px;
  height: calc(100vh - 163px);
  overflow-y: scroll;
}

.list .tableRow {
  font-size: 12px;
}

.tableRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 6px 15px;
  font-size: 12px;
  gap: 10px;
  width: 100%;
  min-width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.tableRow:hover {
  background: #edf0f9;
}
.tableRow:global(.active) {
  background: #d9e0f1;
}
.tableRow:global(.highlighted) {
  background-color: #edf0f9;
}
.tableRow {
  display: grid;
  padding: 4px 15px;
  gap: 10px;
}
.tableRow .date {
  font-weight: 500;
  font-size: 12px;
}
.list .tableRow {
  font-size: 12px;
}
.tableRow div {
  position: relative;
}

.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.link {
  color: var(--blue-5, #2b8efa);
  font-size: 12px;
  font-weight: 700;
}
.link:hover {
  color: var(--blue-5, #2b8efa);
  opacity: 0.8;
}

.search {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 100%;
  padding: 5px 0px;
  align-items: center;
  gap: 10px;
}

.search input[type="search"] {
  display: flex;
  height: 38px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: url(~assets/images/search.svg) no-repeat 98% center;
}

.search input[type="search"]:focus {
  outline: none;
  border: 1px solid #856afe;
  background: url(~assets/images/search.svg) no-repeat 98% center;
}

.search .result,
.date {
  font-size: 12px;
  line-height: 16px;
}

.noResult {
  height: 50vh;
}

.tableHeaderWarehouseAction,
.tableRowWarehouseAction {
  grid-template-columns: 20px 1fr 1fr 1fr 1fr;
  justify-content: flex-start;
  font-size: 12px;
  letter-spacing: -0.03rem;
  padding: 4px 15px;
  gap: 10px;
  display: grid;
}

.date {
  font-weight: 500;
  font-size: 12px;
}

.rightPanelPositioning {
  position: fixed;
  top: 88px;
}

.linkWidth {
  width: fit-content;
}

.rightPanelVisibility {
  z-index: var(--trading-documents-right-panel-z-index);
}

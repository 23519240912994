/* Pagination =============== */
.pagination {
  background: var(--white-95, rgba(255, 255, 255, 0.95));
  position: absolute;
  bottom: 0;
  width: 100%;
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  height: 50px;
}

.relativePagination {
  background: var(--white-95, rgba(255, 255, 255, 0.95));
  bottom: 0;
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}

.paginationPages {
  min-width: 90px;
  font-size: 12px;
  color: grey;
}

.paginationPages strong {
  color: #333;
}

/* Pagination =============== */
.btnBgTransparentTextBlack {
  color: #333;
  background: transparent;
}

.btnBgTransparentTextBlack:hover {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}

.btnSizeSX {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
}

.btnBase {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
}

.btnBaseSmall {
  padding: 4px 8px;
  gap: 4px;
  font-size: 10px;
  line-height: 12px;
}

.btnBaseMedium {
  padding: 6px 12px;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
}

.btnBaseLarge {
  padding: 8px 16px;
  gap: 6px;
  font-size: 16px;
  line-height: 20px;
}

.btnBase img {
  display: flex;
  width: 16px;
  height: 16px;
}

.modal {
  min-height: 30vh;
  padding: 15px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.searchProductContainer {
  margin-bottom: 10px;
}

.searchProductInput {
  border-radius: 4px;
  padding: 0px 10px;
}

.attributesHeader {
  margin-bottom: 10px;
  color: #808080;
  font-size: 17px;
  margin-top: 10px;
}

.attributesContainer {
  width: 100%;
  overflow-wrap: break-word;
}

.attributeLabel {
  font-size: 0.81rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  height: 24px;
  line-height: 20px;
  padding: 0 3px;
  margin-right: 3px;
  margin-bottom: 3px;
  white-space: nowrap;
}

.filtersBtn {
  border-radius: 4px;
}

.noPadding {
  padding: 0;
}

.selectedProductsList {
  flex-wrap: wrap;
}

.selectedProductsItem {
  border-right: 1px solid #808080;
  padding: 0px 10px 0px 10px;
}

.renderedProducts {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  align-items: center;
}

.attributesContainer {
  margin: 4px 0px;
  display: flex;
  flex-wrap: wrap;
}

.attribute {
  padding: 2px 4px 1px;
  border: 1px solid #ddd;
  background-color: #d0d0d6;
  border-radius: 3px;
  margin: 3px 2px 3px 0;
  font-size: 10px;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: min-content;
}

.attribute span {
  margin-right: 2px;
  white-space: nowrap;
  font-weight: normal;
  color: #444;
}
.attribute strong {
  font-weight: 700;
  white-space: nowrap;
}

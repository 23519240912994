.item {
  position: relative;
  display: flex;
  margin-left: 20px;
  width: calc(100% - 20px);
}
.item:last-of-type:before {
  content: "";
  position: absolute;
  width: 10px;
  top: 10px;
  bottom: 0;
  left: -17px;
  background: #edf1f3;
  z-index: -1;
}
.item:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 4px solid #765ee3;
  background: #ddd;
  position: absolute;
  left: -19px;
  top: 10px;
  z-index: 1;
}
.item .travel-time {
  font-size: 0.75rem;
  color: #777;
  text-align: right;
  font-weight: 500;
}
.itemAddress {
  max-width: -webkit-fill-available;
  max-width: -moz-available;
}
.card {
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07), 0px 2px 6px 2px rgba(0, 0, 0, 0.055);
  border-radius: 4px;
  padding: 8px 15px 12px;
}
.event {
  margin-bottom: 4px;
}
.event span {
  display: inline-flex;
  padding: 1px 3px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.07);
  margin: 0 2px;
}
.avatar {
  width: 22px;
  min-width: 22px;
  height: 22px;
  margin: 0;
  margin-right: 10px;
  overflow: hidden;
  background-color: #dde5e9;
  border-radius: 50%;
}
.avatar img {
  display: block;
  max-width: 100%;
}
.name {
  color: #808080;
  font-size: 0.9rem;
}
.eventType {
  border-radius: 50%;
  width: 34px;
  min-width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.itemTiming {
  min-width: 74px;
  padding-top: 10px;
}
.itemDate {
  font-size: 0.81rem;
}
.itemTime {
  font-size: 0.75rem;
  font-weight: 500;
  color: #808080;
}
@media (min-width: 1281px) {
  .card {
    max-width: 480px;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-right: 8px;
  border-radius: 50%;
  padding: 6px;
}

.iconContainer img {
  width: 16px;
  height: 16px;
  filter: contrast(0%) brightness(200%);
}

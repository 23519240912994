.clickedClassName {
  position: relative;
}
.clickedClassName::after {
  position: absolute;
  content: "";
  right: 7px;
  top: 10px;
  width: 6px;
  height: 13px;
  border: solid #765ee3;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

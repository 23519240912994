.dropdown {
  background: #fff;
  box-shadow: 2px 5px 30px 4px rgba(0, 0, 0, 0.1), 1px 0px 6px 2px rgba(0, 0, 0, 0.1),
    -2px 4px 8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 5px 5px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  min-width: fit-content;
  width: 300px;
  padding: 15px 0 10px;
}
.avatarLarge {
  width: 56px;
  min-width: 56px;
  height: 56px;
}
.avatar {
  cursor: pointer;
}
.option {
  padding: 10px 1rem;
  font-size: 1rem;
  color: #777;
  text-decoration: none;
  border: 0;
  background: transparent;
  border-radius: 0;
}
.option:hover {
  color: #333;
  text-decoration: none;
  background: #ddd;
}
.option:focus {
  color: #333;
  text-decoration: none;
  background: #ccc;
  outline: 0;
}
.option span {
  margin-left: 15px;
}
.option img {
  width: 20px;
  height: 20px;
}

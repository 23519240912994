.modalContainer {
  width: max-content;
  min-width: 1391px;
  max-width: 1490px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  max-height: 95vh;
  overflow: scroll;
  margin: 2.5vh auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modalContainer::-webkit-scrollbar {
  display: none;
}

.modalTitle {
  margin-bottom: 0;
}

.filterBox {
  position: relative;
}

.inputSmall {
  max-width: 110px;
  display: flex;
  height: 28px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 14px;
  background: none;
}

.inputSmall:hover {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--deep-purple-4, #9f8aff);
}
.inputSmall:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--deep-purple-5, #8c73ff);
}

.modalContainer .inputSmall {
  max-width: unset;
  width: 130px;
}

.inputLabel {
  display: flex;
  padding: 0px 2px;
  align-items: center;
  position: absolute !important;
  left: 4px;
  top: -7px;
  background: #242227;
  color: var(--gray-3, #828282);
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  height: 12px;
}

.inputOnLightBg {
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  color: #333;
}

.inputLabelLight,
.inputOnLightBg ~ .inputLabel {
  background: #fff;
}

.labelText {
  font-size: 12px;
  line-height: 12px;
  color: #808080;
}

.search {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 100%;
  padding: 5px 0px;
  align-items: center;
  gap: 10px;
}
.search input[type='search'] {
  display: flex;
  height: 38px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: url(~assets/images/search.svg) no-repeat 98% center;
}
.search input[type='search']:focus {
  outline: none;
  border: 1px solid #856afe;
  background: url(~assets/images/search.svg) no-repeat 98% center;
}


/* RoutePositionsList */

.routeHeader,
.nightStop {
  position: relative;
  display: flex;
  align-items: center;
}
.routeHeader span,
.nightStop span {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: #fff;
  padding: 5px 10px 5px 4px;
  color: var(--black-6, #808080);
}
.nightStop img {
  width: 16px;
}
.routeHeader:before,
.nightStop:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.15);
}

.listTable {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: none;
  flex: 1 1 auto;
  min-width: 390px;
  overflow: hidden;
  width: 100%;
}

.deliveryDate {
  color: rgb(33, 37, 41);
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.015rem;
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  padding: 4px 15px;
  display: grid;
}

.tableHeaderBorder {
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}

.tableHeaderTracking,
.tableRowTracking {
  grid-template-columns: 20px minmax(180px, 220px) 110px 100px 110px 80px 60px 100px 80px 80px 80px 60px 100px 30px 80px;
  padding: 6px 15px 6px 30px;
}

.bgNone {
  background: none !important;
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 1px;
  height: calc(100vh - 163px);
  font-size: 12px;
}

.listOverflowScroll {
  overflow-y: scroll;
}

/* RoutePosition */

.tableRowDefaultBackground {
  background: #ffffff;
}
.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  width: 100%;
  min-width: 100%;
  height: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.tableRow:hover {
  background: #edf0f9;
}
.tableRow:active /* on click */ {
  background: #d9e0f1;
}
.tableRow {
  display: grid;
  grid-template-columns: 20px minmax(180px, 220px) 110px 100px 110px 80px 60px 100px 80px 80px 80px 60px 100px 30px 80px;
  padding: 6px 15px 6px 30px;
  gap: 10px;
}
.tableRow .date {
  font-weight: 500;
  font-size: 12px;
}
.list .tableRow {
  font-size: 12px;
}
.modalContainer .list {
  height: unset;
}
.tableRow div {
  position: relative;
}

/* position is delivered */
.tableRowTracking .stateLabel {
  font-size: 12px;
  letter-spacing: inherit;
}
.tableRowTracking {
  position: relative;
}
.tableRowTracking:after {
  content: '';
  position: absolute;
  top: 0;
  left: 14px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  height: 100%;
  width: 1px;
}
.tableRowTracking:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  border-radius: 10px;
  height: 14px;
  width: 14px;
  background: #ddd url(~assets/images/point.svg) no-repeat center;
  background-size: contain;
  opacity: 0.5;
}
.tableRowTracking.rowVisited:before {
  background: var(--teal-4, #6de5b9) url(~assets/images/checkSmall.svg) no-repeat
    center;
  opacity: 1;
}
.tableRowTracking.rowCurrent:before {
  left: 7px;
  height: 16px;
  width: 16px;
  box-shadow: 0 0 0 1px #8c73ff, 0 0 0 0 var(--Deep-Purple-5, #8c73ff);
  background: var(--Deep-Purple-4, #9f8aff)
    url(~assets/images/radioButtonChecked.svg) no-repeat center;
  opacity: 0.8;
  -webkit-animation: pulse 1.25s infinite;
  animation: pulse 1.25s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 1px #8c73ff, 0 0 0 0 rgba(140, 115, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 1px #8c73ff, 0 0 0 10px rgba(140, 115, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 1px #8c73ff, 0 0 0 0 rgba(140, 115, 255, 0);
  }
}

.rowCurrent {
  position: relative;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #8c73ff, 0 0 0 0 var(--Deep-Purple-5, #8c73ff);
  background: var(--deep-purple-10, rgba(134, 107, 255, 0.1));
  -webkit-animation: pulse 1.25s infinite;
  animation: pulse 1.25s infinite;
}

.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.listDisabled {
  opacity: 0.6;
  filter: grayscale(1);
}

.btnDisabled {
  opacity: 0.6;
  filter: grayscale(1);
  cursor: not-allowed;
}

.realDeliveryLabel {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.48px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 1px;
  width: 100%;
  height: 100%;
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}
.mainContentInner {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.date {
  color: #808080;
  font-size: 12px;
  line-height: 16px;
}

.productName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.editedRow {
  background: var(--Blue-1, #e6f2fc);
}

.signature {
  font-weight: 500;
  font-size: 12px;
  min-width: 95px;
}

.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tooltip {
  display: none;
  position: absolute !important;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  min-width: 320px;
  text-align: center;
  justify-content: center;
  word-wrap: break-word;
  font-size: 15px;
  max-width: 350px;
  padding: 4px;
  background: #1a1a1a;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  text-transform: none !important;
  z-index: 95;
}
.overflowingMessage:hover {
  cursor: help;
}
.overflowingMessage:hover .tooltip {
  display: flex;
}

.noWrap {
  white-space: nowrap;
}

.icon {
  width: 14px;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}

.listAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
  border: 1px solid #ffffff;
}

.listAvatar img {
  display: block;
  max-width: 100%;
}

.avatarSmall {
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
}

.avatarMedium {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}

.avatar img {
  display: block;
  max-width: 100%;
}

.avatarPlaceholder {
  padding: 2px;
  border: 1px dashed #bbb;
  background: transparent;
}
.avatarPlaceholder img {
  opacity: 0.5;
}

.date {
  font-weight: 500;
  font-size: 12px;
}

.fixedSingleRouteRow {
  height: 37px;
  user-select: text;
}

.callCard .phone {
  z-index: 2;
  background: var(--Teal-4, #6de5b9);
  animation: phone 1s var(--animation-curve) infinite alternate-reverse;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 0.001px #151515 solid;
}
.callCard .phone,
.circle1,
.circle2 {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: var(--Teal-4, #6de5b9);
  position: absolute;
  animation: scaling 2s var(--animation-curve) infinite;
}
.callCard .phone img {
  width: 9px;
}
@keyframes phone {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.5);
  }
}
.circle1 {
  animation-delay: 0s;
}
.circle2 {
  animation-delay: 1s;
}

/* singleRoute */

.callStatus {
  height: 38px;
  width: 100%;
  padding: 0px 5px;
  padding: 0px 5px 0 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15) transparent;
  background: transparent;
  overflow: hidden;
}

.callStatusSmall {
  height: 28px;
  font-size: 14px;
  border: 1px solid transparent;
  width: max-content;
}

.callStatus:hover {
  border: 1px rgba(0, 0, 0, 0.15) solid;
}

.callStatusIcon {
  margin-left: 5px;
  position: absolute;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
}

.optionList {
  position: absolute !important;
  z-index: 1;
  top: calc(100% + 4px);
  right: 0;
  min-width: max-content;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}

.optionRow {
  cursor: pointer;
  max-height: 34px;
  padding-bottom: 2px;
}
.optionRow img {
  width: 18px;
}
.optionRow:hover {
  background: rgba(0, 0, 0, 0.05);
}
.firstItemHintboxPosition {
  bottom: -130%;
}
.optionSelected {
  background: var(--deep-purple-10, rgba(134, 107, 255, 0.1));
}

/* expectedDelivery */
.negativeCollectionHours {
  display: flex;
  align-items: center;
}

.callInProgress {
  background-color: #6de5b9 !important;
  color: #333;
  outline: none;
}
.callInProgress div {
  background-color: #6de5b9;
}
.callInProgress:hover {
  border: none;
  outline: none;
}

.routeTrackingBtn {
  color: rgb(51, 51, 51);
}

.range {
  letter-spacing: -0.03rem;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.deliveryStatusLabel {
  letter-spacing: -0.03rem;
  font-weight: 700;
}

.callNowBtn {
  white-space: nowrap;
}

.checkIcon {
  opacity: 0;
  position: absolute;
  display: inline-block;
  transform: rotate(43deg);
  margin-left: 10px;
  margin-bottom: 5px;
  height: 14px;
  width: 8px;
  border-bottom: 4px solid #765ee3;
  border-right: 4px solid #765ee3;
}

.successCheckAnimation {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-name: appearSuccess;
}

@keyframes appearSuccess {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.deleteIcon {
  width: 20px;
  height: 20px;
}

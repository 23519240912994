.slotsCounter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slotsCounter strong {
  font-size: 1.1rem;
}
.slotsCounter strong + span {
  font-size: 0.75rem;
  color: #856afe;
}
.slotsCounterLabel {
  display: block;
  width: max-content;
  font-size: 0.75rem;
  color: #808080;
}
.sourcesContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.sourcesContainer::-webkit-scrollbar {
  display: none;
}
.sourceGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  gap: 2px;
  width: 70px;
  height: 20px;
  padding: 0 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  cursor: pointer;
}
.sourceGroupL {
  width: auto;
  padding: 4px;
  font-size: 0.85rem;
}
.color {
  box-sizing: border-box;
  min-width: 8px;
  width: 8px;
  height: 8px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 2px;
}

.btn:hover {
  background: rgba(0, 0, 0, 0.1);
}
.round {
  border-radius: 50px !important;
}

/* TODO waiting for design */
.btn[disabled] {
  cursor: not-allowed;
}

.btn:focus {
  outline: 0;
}

.button-l {
  font-size: 16px;
  padding: 9.5px 10px;
  border-radius: 8px;
}

.button-m {
  font-size: 14px;
  padding: 5.5px 10px;
  border-radius: 8px;
}

.button-s {
  font-size: 12px;
  padding: 3.5px 5px;
  border-radius: 4px;
}

.secondary-grey {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
}
.secondary-grey:hover {
  background: rgba(0, 0, 0, 0.1);
}
.secondary-grey:active {
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.secondary-grey[disabled] {
  background: rgba(0, 0, 0, 0.05);
  opacity: 0.5;
  border: 1px solid transparent;
}

/*--------------- Primary --------------- */
.primary {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
}
.primary:hover {
  background: rgba(0, 0, 0, 0.1);
}
.primary:active {
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.primary[disabled] {
  background: rgba(0, 0, 0, 0.05);
  opacity: 0.5;
  border: 1px solid transparent;
}

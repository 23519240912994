.card {
  position: relative;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
  display: flex;
  min-width: 516px;
  width: 516px;
  max-width: 516px;
  min-height: 200px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 4px;
  background: var(--neutralWhite100, #fff);
  box-shadow: 0px 6px 16px -6px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  padding: 16px 15px 8px 15px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  align-self: stretch;
}

.footer {
  display: flex;
  padding: 16px 15px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.select {
  width: 130px;
  max-width: 130px;
  min-width: 130px;
}

.tableWrapper {
  width: 100%;
  display: flex;
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
  max-height: 260px;
}

.dropdownList {
  min-width: 130px;
  width: 130px;
  max-width: 130px;
}

.modalForm {
  max-height: 75vh;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
}

.chipsSelector {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0px 0px 0px 32px;
  gap: 4px;
  margin-bottom: 8px;
}

.link {
  height: 22px;
  max-height: 22px;
  min-height: 22px;
  padding-left: 45px;
  display: flex;
  align-items: center;
}

.border {
  width: 1px;
  height: 100%;
  background-color: var(--neutralWhite16);
  margin-right: 12px;
}

.linkActive .border {
  background-color: var(--neutralWhite64);
}

.link:hover {
  background: var(--neutralWhite7);
  color: #fff;
}

.link:active {
  background: var(--deepPurple32) !important;
}

.linkActive {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.linkActive .sectionName {
  color: var(--neutralWhite100) !important;
}

.sectionName {
  color: var(--neutralWhite64) !important;
}

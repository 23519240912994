.basicColumn {
  border-radius: 4px;
  border: 1px solid var(--neutralBlack7);
  height: calc(100vh - 107px);
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.basicColumn::-webkit-scrollbar {
  display: none;
}

.columnDraggedOver {
  border: 1px solid var(--deepPurple400) !important;
}

.draggedOverBorder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

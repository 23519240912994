.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.input:checked ~ .radiomark {
  /* background: #765ee3;
  color: #fff; */
  border: 2px solid #765ee3 !important;
}
.input:checked ~ img {
  /* background: #765ee3;
  color: #fff; */
  border: 2px solid #765ee3 !important;
}

.radiomark {
  display: inline-flex;
  width: fit-content;
  width: -moz-fit-content;
  font-size: 0.81rem;
  height: 28px;
  line-height: 25px;
  padding: 0 3px;
  background: #eee;
  align-items: center;
}
.radiomark,
.label img {
  border-radius: 4px;
  white-space: nowrap;
}
.label img {
  width: 21px;
  height: 21px;
  max-height: 21px;
  max-width: 21px;
  margin-right: 5px;
}

.fieldsetLabel {
  width: 100%;
  font-size: 0.81rem;
  color: #8b9396;
  font-size: 0.81rem;
  padding-top: 10px;
  margin-bottom: 15px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: capitalize;
  border-top: 1px solid #eee;
}
.label {
  position: relative;
  font-size: 0.81rem;
  padding: 0;
  width: fit-content;
  margin-bottom: 4px;
}
.disabled {
  opacity: 0.4;
}

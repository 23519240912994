.historyStatus {
  background-color: var(--grey50);
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  bottom: -6px;
  right: -6px;
}

.markdown p {
  margin-bottom: 0px;
  line-height: 16px;
}

.markdown p code {
  color: var(--deepPurple400);
  font-style: italic;
  margin-left: 1px;
  margin-right: 1px;
}

.bar {
  background: var(--neutralBlack7);
  position: relative;
  height: 8px;
  border-radius: 8px;
  width: 100%;
}

.progress {
  position: absolute;
  background: linear-gradient(90deg, #075049 0%, #23DEEA 100%);
  max-width: 100% !important;
  border-radius: 8px;
  height: 8px;
}

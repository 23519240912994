.card {
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07), 0px 2px 6px 2px rgba(0, 0, 0, 0.055);
  border-radius: 4px;
  padding: 10px 16px;
  margin-bottom: 0.25rem;
  border-top: 4px solid transparent;
}
.header {
  display: flex;
  align-items: center;
}
.order {
  flex-direction: row;
  align-items: center;
}
.signature {
  font-size: 0.95rem;
  color: #6f7782;
  white-space: nowrap;
}
.client,
.address {
  margin-top: 4px;
  font-weight: 500;
}
.orderDate,
.address,
.client {
  font-size: 0.81rem;
  white-space: nowrap;
  font-style: normal;
}
.client {
  color: #666;
}
.address {
  color: #111;
}
.label {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  height: 20px;
  line-height: 18px;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 0 3px;
  min-width: fit-content;
  color: #eee;
  font-size: 0.75rem;
}
.leftDaysLabel {
  position: absolute;
  width: 10px;
  height: 20px;
  border-radius: 0 4px 4px 0;
  left: -16px;
}
.timeCounter {
  height: 20px;
  margin-top: 4px;
}
.iconsHeader {
  color: #888;
  font-size: 0.81rem;
}
.assignee {
  width: 26px;
  min-width: 26px;
}
.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #ddd;
  margin: 9px 0;
  overflow: hidden;
}
.avatar img {
  display: block;
  max-width: 100%;
}

.readyMark {
  display: inline-flex;
  position: relative;
  padding: 0;
  padding-left: 30px;
  margin-bottom: 2px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  width: 20px;
  height: 20px;
}

.readyMark input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.readyMarkCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 3px solid #9a9a9a;
  border-radius: 50%;
}

.readyMarkCheckmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 7px;
  top: -4px;
  width: 7px;
  height: 15px;
  border: solid #9a9a9a;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.readyMark input:checked ~ .readyMarkCheckmark:after {
  border-color: #01c9d5;
}
.readyMark input:checked ~ .readyMarkCheckmark {
  border-color: #01c9d5;
}

@media (max-width: 1279px) {
  .orderDelivery {
    max-width: 100%;
  }
  .statuses,
  .timeCounter {
    margin-left: 0;
    margin-top: 4px;
  }
}
@media (min-width: 1280px) {
  .statuses,
  .timeCounter {
    margin-left: 0;
  }
}
@media (min-width: 1280px) and (max-width: 1535px) {
  .orderDelivery {
    max-width: 260px;
  }
  .address {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
@media (min-width: 1280px) and (max-width: 1365px) {
  .address,
  .client {
    font-size: 0.85rem;
  }
  .label {
    font-size: 0.75rem;
  }
}
@media (min-width: 1536px) {
  .orderDelivery {
    max-width: 100%;
  }
  .address,
  .client {
    font-size: 0.85rem;
  }
}
@media (min-width: 1920px) {
  .address,
  .client {
    font-size: 0.95rem;
  }
  .label {
    font-size: 0.81rem;
  }
}

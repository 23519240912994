.listWrapper {
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
  height: 100%;
}
.list {
  width: -webkit-fill-available;
  width: -moz-available;
  min-width: 540px;
}
.tableBody {
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 110px);
  min-height: 1px;
  min-width: fit-content;
  min-width: -moz-fit-content;
  padding-bottom: 48px;
}
.tableRow {
  display: flex;
  align-items: center;
  background: transparent;
  border-bottom: 1px solid #ccc;
  border-left: 4px solid transparent;
  padding: 8px 15px;
  position: relative;
}

.tableRow:global(.highlighted) {
  background-color: #a1a1a11c;
}
.tableRow:hover {
  background: rgba(118, 94, 227, 0.2);
  cursor: pointer;
}
.tableRow:global(.active) {
  background: rgba(94, 124, 213, 0.3);
}

.compactView .tableRow {
  padding: 5px 15px;
}
.compactView .tableCell {
  display: flex;
  align-items: center;
}
.tableCell a {
  color: #333;
}
.tableCell a:hover {
  color: #01c9d5;
  text-decoration: none;
}
.tableCell a:focus {
  color: #52b8c3;
}
/* Media max-width must be the same with media in toolbar component*/
@media (max-width: 1260px) {
  .tableRow {
    display: block;
  }
  .tableBody {
    max-height: calc(100vh - 147px);
  }
}
@media (max-width: 991px) {
  .list {
    min-width: 450px;
  }
}

.emptyService {
  background-color: var(--neutralWhite100);
  border: 1px solid var(--neutralBlack12);
  height: 380px;
}
.emptyService:hover {
  background-color: var(--neutralWhite64);
}

.service {
  background-color: var(--neutralWhite100);
  box-shadow: 0px 6px 16px -6px #0000001A;
  height: 380px;
}
.service:hover {
  background-color: var(--neutralWhite64);
}

.serviceName {
  margin-bottom: 8px;
}

.opacity {
  opacity: 0.6;
}

.copyContainerButton {
  width: fit-content;
  margin-top: 8px;
}


@keyframes appearSuccess {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.successCheckAnimation {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-name: appearSuccess;
}

.checkIcon {
  opacity: 0;
  position: absolute;
  top: -5px;
  right: -2px;
  display: inline-block;
  transform: rotate(43deg);
  height: 14px;
  width: 8px;
  border-bottom: 4px solid #765ee3;
  border-right: 4px solid #765ee3;
}

.container {
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  align-items: center;
}
.title {
  font-size: 12px;
}

.error {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #d11f64;
}

.chip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin: 4px 0;
}
.chipContent {
  display: flex;
  align-items: center;
  max-width: calc(100% - 38px);
  position: relative;
}
.previewBox,
.chipImage {
  width: 92px;
  height: 92px;
  min-width: 92px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  margin-right: 5px;
  background: #fff;
  padding: 4px;
}
.previewBox {
  border-style: dashed;
  background: #eee;
}
.chipImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}
.chipName {
  font-size: 0.9rem;
  position: absolute;
  left: 50%;
  top: -25px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  padding: 0 6px 2px;
  color: #fff;
  display: none;
}
.chipName:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-width: 5px 5px 5px 5px;
  right: calc(50% - 5px);
  top: 100%;
  border-top-color: rgba(0, 0, 0, 0.75);
  position: absolute;
}
.chipContent:hover .chipName {
  display: block;
}
.deleteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 28px;
  max-width: 28px;
  min-height: 28px;
  min-width: 28px;
  padding: 0;
  border-radius: 50%;
  margin-right: 4px;
}
.deleteBtn:hover {
  background: rgba(155, 155, 155, 0.15) !important;
}

.inputField {
  position: relative;
  width: fit-content;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 28px;
  font-size: 1.4rem;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  font-weight: 700;
  overflow: hidden;
}

.inputField input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 10;
  display: flex;
  width: calc(100% + 10px);
  align-items: center;
  border-radius: 5px;
  border: 1px solid transparent;
  margin: 0 -5px;
  padding: 0 5px;
  background: transparent;
  cursor: pointer;
}
.inputField span {
  margin-left: 4px;
}
.inputField:hover {
  background: rgba(155, 155, 155, 0.15);
}
.uploadBtn {
  color: #01c9d5;
  white-space: nowrap;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  border: 0;
  padding: 0 6px;
}

.label {
  font-size: 12px;
  color: #808080;
  background: #fff;
  display: inline-flex;
  padding: 2px;
  position: absolute;
  left: 5px;
  top: -10px;
  z-index: 1;
  width: fit-content;
  width: moz-fit-content;
}
.selectBox {
  position: relative;
}
.selectWrapper {
  margin-bottom: 1.5rem;
}

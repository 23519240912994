.stockStatus {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  gap: 8px;
}

.inStock {
  width: 60px;
}

.veryLowStock {
  background-color: var(--danger50);
}

.activeUnit {
  background-color: var(--deepPurple75);
}

.lastOpenedUnit {
  background-color: var(--blue25);
}

.container {
  position: relative;
  height: 38px;
  cursor: pointer;
  width: 80px;
  display: flex;
  align-items: center;
}

.timePicker {
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 5px 5px;
  width: 80px;
  height: 38px;
  background: transparent;
  margin-bottom: 0.25rem;
}
.timePicker:hover {
  border-color: #ddd;
}
.timePicker:focus {
  border: 1px solid #765ee3;
  outline: 0;
}

.commonTimePicker {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 5px 5px;
  width: 80px;
  height: 28px;
  background: transparent;
}
.commonTimePicker:hover {
  border-color: #ddd;
}
.commonTimePicker:focus {
  border: 1px solid #765ee3;
  outline: 0;
}

.commonLabel {
  position: absolute;
  top: -11px;
  left: 4px;
  font-size: 11px;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  background: #fff;
  z-index: 1;
}

.clockIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-56%);
  right: 8px;
  filter: contrast(0.5) brightness(1.95);
}
.disabledIcon {
  cursor: not-allowed;
}
.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-56%);
  right: 8px;
}

.clockIcon {
  cursor: initial;
  position: absolute;
  top: 50%;
  transform: translateY(-56%);
  right: 8px;
}
.popper {
  z-index: 6;
}

.listWrapper {
  overflow: auto;
  height: 50vh;
  display: grid;
  grid-template-columns: 1fr 485px;
}
.listGroup {
  position: relative;
}
.listGroup:before {
  content: "";
  position: absolute;
  z-index: 0;
  width: 8px;
  top: 10px;
  bottom: 0;
  left: 4px;
  background: #dde5e9;
  z-index: -2;
}

.filter {
  position: relative;
  padding: 3px 0;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  margin-right: 2px;
  z-index: 5;
}
.filter:hover {
  background: #eee;
}
.filterLabel {
  font-size: 0.7rem;
  font-weight: 400;
  color: #757575;
  white-space: nowrap;
}
.selected {
  font-size: 0.7rem;
  font-weight: 400;
  color: #000;
  white-space: nowrap;
}
.optionsList {
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 5px 0;
  overflow: auto;
  max-height: 500px;
  display: none;
  width: 280px;
}
.optionsItem {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  font-size: 0.81rem;
  color: #343f40;
  width: 100%;
  text-align: left;
}
.optionsItem::first-letter {
  text-transform: capitalize !important;
}
.optionsItem:hover {
  background: #ddd;
  cursor: pointer;
}
.optionsItem:focus {
  background: #0000001a;
}
.optionsItem:global(.active) {
  color: #bbb;
}
@media (max-width: 1279px) {
  .optionsList {
    right: initial;
    left: 0;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
  justify-content: center;
  border-radius: 50%;
  padding: 5px;
}

.iconContainer img {
  width: 18px;
  height: 18px;
  filter: contrast(0%) brightness(200%);
}

.rightSide {
  border-left: 1px solid #ddd;
}
.rightSideItem {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.leftBorder {
  border-left: 1px solid #ddd;
}

.statistics {
  display: flex;
  height: 166px;
  border-bottom: 1px dashed var(--neutralBlack24);
  padding: 16px 16px 24px 16px;
  gap: 16px;
  width: 100%;
}

.statLine {
  height: 34px;
  border-bottom: 1px solid var(--neutralBlack16);
}

.statsLabel {
  width: 200px;
}

.noBtnInStatLine {
  widtH: 50px;
}

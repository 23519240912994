.sectionEmpty {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.sectionEmpty .icon {
  opacity: 0.5;
}
.sectionEmptyTitle {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: normal;
}

.notificationAction {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
}

.date {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #808080;
}

.senderName {
  max-width: 40px;
}

.fieldsetLabel {
  font-size: 1.4rem;
  font-weight: 600;
  color: #666;
  width: 100%;
  margin-top: 8px;
}

.optionColor input {
  position: absolute;
  opacity: 0;
}

.optionColor .colorBox {
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 4px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.065);
}

.optionColor input:focus ~ .colorBox {
  box-shadow: 0px 0px 0px 2px #3aa8bd, 0px 2px 2px 2px rgba(0, 0, 0, 0.09);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.optionColor input:checked ~ .colorBox {
  border: 4px solid rgba(0, 0, 0, 0.2);
}
.optionColor input:checked ~ .colorBox:hover,
.optionColor input:focus ~ .colorBox:hover {
  transition: all 0.2s ease-in-out;
}

.lineItemContainer {
  padding: 8px 15px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.lineItemTitle {
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 10px;
}

.lineItemSection {
  padding: 8px 0;
}

.lineItemHeader {
  display: flex;
  align-items: center;
  height: 38px;
  border-bottom: 1px solid var(--neutralBlack12);
  gap: 10px;
  padding: 8px 15px 4px 15px;
}

.lineItemHeaderGeneral {
  display: flex;
  align-items: center;
  width: 340px;
  gap: 10px;
}

.lineItemHeaderBuyer {
  width: 305px;
}

.tableContainer {
  max-height: 55vh;
  overflow: scroll;
  border-bottom: 2px solid var(--neutralBlack12);
}

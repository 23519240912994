.modalContainer {
  max-width: max-content;
  width: 480px;
  min-width: 480px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  margin-top: 5vh;
}

.inputBox {
  position: relative;
}

.input {
  margin-bottom: 1rem;
}
.commentGroup {
  border-bottom: 1px solid #ddd;
}
.icon {
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin: 0;
}

/* VisibilityCommentForm */
.commentVisibilityField {
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  margin-left: 10px;
}
.commentVisibilityField > div.checkboxField {
  display: flex;
  width: 100%;
  height: 36px;
  line-height: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 1px solid transparent;
  background: #e1e7e9;
  border-color: #5a3fb5;
  padding: 2px 10px;
  font-size: 1rem;
}
.commentVisibilityField textarea {
  display: flex;
  width: 100%;
  height: 36px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid transparent;
  background: #e1e7e9;
  padding: 2px 10px;
  font-size: 1rem;
}
.commentVisibilityField button {
  position: absolute;
  right: 5px;
  bottom: 5px;
  line-height: 24px;
  background: #01c9d5;
  display: inline-flex;
  border-radius: 25px;
  border: 1px solid transparent;
}
.commentVisibilityField button:hover {
  background: #765ee3;
  border-color: #765ee3;
}
.commentVisibilityField textarea:focus {
  outline: none;
  border-radius: 20px 20px 0 0;
}

.commentField {
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  margin-left: 10px;
}
.commentField textarea {
  display: flex;
  width: 100%;
  height: 36px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid transparent;
  background: #e1e7e9;
  padding: 2px 10px;
  font-size: 1rem;
}
.commentField button {
  position: absolute;
  right: 5px;
  bottom: 5px;
  line-height: 30px;
  line-height: 24px;
  background: #01c9d5;
  display: inline-flex;
  border-radius: 25px;
  border: 1px solid transparent;
}
.commentField button:hover {
  background: #765ee3;
  border-color: #765ee3;
}
.commentField textarea:focus {
  outline: none;
  border-color: #5a3fb5;
}
.commentGroup {
  border-bottom: 1px solid #ddd;
}
.commentGroup .icon {
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin: 0;
}
.commentGroup .icon img {
  width: 100%;
}

.followersGroup {
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.btnAddUser,
.followerAvatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
}
.followerAvatar img {
  border: 2px solid #f6f8f9;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background: #ddd;
}
.btnAddUser {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  border: 2px solid #f6f8f9;
  width: 32px;
  min-width: 32px;
  height: 32px;
  left: -5px;
  background: #765ee3;
}
.btnAddUser:hover {
  background: #5a3fb5;
}
.btnAddUser:focus {
  background: #5238a7;
  outline: 0;
}
.btnAddUser img {
  display: block;
  width: auto;
}
.followerAvatar + .btnAddUser {
  left: -10px;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatarSmall {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}
.avatar img {
  display: block;
  max-width: 100%;
}

.assignee {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 700;
}

.optionList {
  position: absolute !important;
  z-index: 1;
  top: calc(100% + 12px);
  right: -40px;
  min-width: max-content;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  /* display: none; */
}

.optionList .search {
  width: 100%;
  min-width: 100%;
}
.optionRow a {
  color: #222;
}
.optionRow img {
  width: 18px;
  min-width: 18px;
}
.optionRow:hover {
  background: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
/* POPUP STYLES */

.tooltip {
  pointer-events: auto !important;
  opacity: 1 !important;
  padding: 0 !important;
}

.tooltip:hover {
  visibility: visible !important;
}

.tooltip:global(.__react_component_tooltip.place-left)::before {
  display: none;
}
.tooltip:global(.__react_component_tooltip.place-left)::after {
  display: none;
}

.tooltipContainer {
  width: 160px;
}

.disabledLink {
  opacity: 0.6;
}

.avatarBox {
  position: relative;
  display: flex;
  align-items: center;
  width: 125px;
  max-width: 125px;
  overflow: hidden;
}

.invoiceSpacing {
  letter-spacing: -0.03rem;
}

.highOpacity {
  opacity: 0.75;
}

.confirmationBtn {
  max-height: 20px !important;
}

.optionsMenu {
  max-height: max-content;
}

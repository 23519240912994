.miniature {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px;
}
.miniature img {
  display: block;
  width: 100%;
}
.indexName {
  font-size: 1.35rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.indexCode {
  font-size: 0.7rem;
  padding: 0px 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #757575;
  margin-right: 10px;
}
.producer {
  font-size: 0.7rem;
  color: #757575;
}
.unit,
.label {
  font-size: 0.8rem;
  color: #757575;
}
.unit {
  text-transform: uppercase;
}
.table {
  border-radius: 5px;
  margin: 0 0 15px;
}
.table h5 {
  font-size: 0.9rem;
}
.tableRow {
  display: grid;
  gap: 10px;
}

.tableBody,
.tableRow {
  grid-template-areas: "a a a a a";
  grid-auto-columns: minmax(max-content, 2fr) minmax(150px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr);
}
.tableRow {
  padding: 5px 1rem;
  border-top: 1px solid #ddd;
}
.tableCell {
  display: flex;
  align-items: center;
}
.totalQuantity {
  font-size: 2rem;
}
.subTotalQuantity {
  padding-top: 0.7rem;
  text-align: right;
}
.subTotalQuantity strong {
  font-size: 1.3rem;
}
.stateImg img {
  transform: rotateY(180deg);
  height: 20px;
}
.attribute {
  padding: 2px 4px 1px;
  border: 1px solid #ddd;
  background-color: #d0d0d6;
  border-radius: 3px;
  margin: 3px 2px 3px 0;
  font-size: 12px;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: min-content;
}
.attribute span {
  margin-right: 2px;
  white-space: nowrap;
  font-weight: normal;
  color: #444;
}
.attribute strong {
  font-weight: 700;
  white-space: nowrap;
}

.container {
  min-height: 300px;
}

.employeeKindLabel {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 2px 0px 4px;
  font-size: 0.7rem;
  line-height: 0.75rem;
  min-width: 32px;
  height: 16px;
  border-radius: 4px;
  text-transform: uppercase;
}

.upholstererLabel {
  background: #856AFE59;
  color: #604CBB;
}

.seamstressLabel {
  background: #D7AD0359;
  color: #AB8900;
}

.cutterLabel {
  background: #E8707659;
  color: #C95D62;
}

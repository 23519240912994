.searchBoxField {
  position: relative;
  z-index: 1;
}
.searchBoxField input {
  border: 1px solid #ddd;
  min-width: 220px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  padding: 0 27px 0 10px;
  background: transparent;
  width: 100%;
  border-radius: 20px;
}
.searchBoxField input:hover {
  outline: 0;
  box-shadow: 0 0 1px 1px #ccc;
}
.searchBoxField input:focus {
  outline: 0;
  box-shadow: 0 0 1px 1px #765ee3;
}
.resetBtn {
  position: absolute;
  right: 3px;
  top: 3px;
}

.newLayoutSearch {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 100%;
  border-radius: 4px;
  padding: 5px 0px;
  align-items: center;
  gap: 10px;
}
.newLayoutSearch input[type="search"] {
  display: flex;
  height: 38px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: url("assets/images/search.svg") no-repeat 98% center;
}
.newLayoutSearch input[type="search"]:focus {
  outline: none;
  border: 1px solid #856afe;
  background: url("assets/images/search.svg") no-repeat 98% center;
}

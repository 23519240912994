.contentBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 15px;
}

.contentTable {
  height: fit-content;
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 4px;
}

.sellerItem {
  display: flex;
  gap: 8px;
  padding-left: 4px;
}

.taxIdDisplay {
  font-style: italic;
  line-height: 12px;
}

.buttonSection {
  align-items: center;
  display: flex;
  height: 70px;
  gap: 10px;
  padding: 16px 15px;
}

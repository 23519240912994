.modalContainer {
  max-width: max-content;
  width: 480px;
  min-width: 480px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  margin-top: 5vh;
  min-height: 242px;
}
.customList {
  min-height: 250px;
}

.avatar {
  min-width: 24px;
  width: 24px;
  max-width: 24px;
  min-height: 24px;
  height: 24px;
  max-height: 24px;
}

.container {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 1px;
  height: 1px;
}

.item {
  width: 50px;
  height: 30px;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  border: 0;
  border-bottom: 2px solid #ddd;
}
.isActive {
  border-bottom: 2px solid #6853ca;
}

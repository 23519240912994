.filterBar {
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 4px 0px #00000026;
  background-color: var(--neutralWhite100);
  padding: 4px 8px;
  margin-right: 12px;
  gap: 4px;
}

.input {
  max-width: 60px;
}

.navigateOption {
  padding-top: 2px;
}

.uploadBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px dashed var(--black-35, rgba(0, 0, 0, 0.35));
  text-align: center;
  font-weight: 500;
}
.uploadBox img {
  display: block;
  width: 42px;
  opacity: 0.35;
}

.uploadBtn {
  color: var(--deep-purple-5, #8c73ff);
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 2px;
  min-height: auto;
  padding: 4px;
  background: rgba(246, 246, 246, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.85rem;
}

/* ManufacturingItem */
.itemInnerBox {
  width: calc(100% - 30px);
}
.itemStatusWaiting {
  border-color: #e87076;
}
.itemStatusDone {
  border-color: #01c9d5;
}
.itemNameBox {
  position: relative;
}
.itemName {
  width: 150px;
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deliveryGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  gap: 2px;
  width: 70px;
  height: 20px;
  padding: 0 4px;
  font-size: 0.75rem;
  font-size: 0.75rem;
  white-space: nowrap;
}
.color {
  box-sizing: border-box;
  min-width: 8px;
  width: 8px;
  height: 8px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 2px;
}
.bgViolet {
  background: #b284ef;
  color: #fff;
}
.sourceName {
  overflow: hidden;
  white-space: nowrap;
}
.priorityPlanLabel,
.statusLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 2px;
  font-size: 10px;
}
.priorityPlanA {
  background: #e87076;
  color: #fff;
}
.priorityPlanB {
  background: #d57e01;
  color: #fff;
}
.priorityPlanC {
  background: #c7d501;
  color: #fff;
}
.attributesContainer {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 4px 0;
}
.attribute {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1px 3px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 2px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: normal;
  white-space: nowrap;
}
.stageStatusLabel {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 2px 0px 4px;
  font-size: 0.7rem;
  line-height: 0.75rem;
  min-width: 32px;
  height: 16px;
  border-radius: 4px;
  text-transform: uppercase;
}
.moreStagesCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 2px;
  gap: 10px;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 50%;
  background: #d0c7fa;
  border: 1px solid #ffffff;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #856afe;
  flex: none;
  flex-grow: 0;
  z-index: 4;
}

/* EmptyManufacturingItem */
.itemPlaceholder {
  border: 1px dashed rgba(0, 0, 0, 0.3);
  color: #808080;
  background: transparent;
}
.itemSlot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 10px;
  letter-spacing: normal;
}
.itemSlotSmall,
.itemSlotMedium {
  background: #ddd;
  margin-right: 5px;
}
.itemSlotMedium {
  width: 70px;
}

.packageIsDragged {
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s, border-radius 0.2s;
}

.packagesGroupPlaceholderDrag {
  width: 98%;
  padding: 5px 10px;
  text-align: center;
  border: 1.5px dashed rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #808080;
  margin-bottom: 0.25rem;
  scale: 1.02;
  height: 60px;
  margin-left: 0.25rem;
  transition: transform 0.2s, height 0.2s;
}

.commentsSection {
  width: 707px;
  color: #212529;
  -webkit-tap-highlight-color: none;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  font-family: "Sofia Sans", sans-serif;
  position: fixed;
  bottom: -3px;
  letter-spacing: -0.03rem;
  font-smooth: never;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: antialiased !important;
  z-index: 50 !important;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.commentsSectionShown {
  height: 220px;
}
.commentsListWrapper {
  -webkit-transform: translateY(130px);
  transform: translateY(130px);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  transition: all 0.1s ease;
  position: absolute;
  bottom: 67px;
  width: 100%;
  background: #fff;
  overflow-x: hidden;
}
.wrapperFocused .commentsListWrapper {
  transform: translateY(calc(130px - (90px - 57px) - 42px));
}
.commentsSectionShown .commentsListWrapper {
  bottom: 57px;
}
.inputBox {
  display: flex;
  gap: 4px;
  position: relative;
  width: 100% !important;
  box-sizing: inherit;
  height: 41px;
  overflow: hidden;
}

.commentSection *,
.commentSection *:before,
.commentSection *:after {
  box-sizing: inherit !important;
}
.inputBox textarea {
  white-space: pre-wrap;
  overflow: hidden;
  resize: none;
}
.inputBox textarea {
  height: 99% !important;
}
.formWrapper {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
}
.focused .inputBox {
  height: 90px;
}
.formWrapper form {
  width: 100%;
}
.focused.formWrapper {
  height: 128px;
  bottom: 0;
}

.commentsSmallWrap {
  height: 42px;
  position: relative;
}
.commentsSmallWrap h4 {
  line-height: 18px !important;
}
.commentsListWrapperShown {
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.uppercase {
  text-transform: uppercase;
}
.SectionHeader h4 {
  color: #808080;
  margin-bottom: 10px;
}

h4 {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.015rem;
}
.commentsList {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 120px;
  overflow: scroll;
  scroll-padding: 20px;
}

.body18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 125% */
}
.body16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}
.body14 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.body12 {
  font-size: 12px;
  line-height: 14px;
}
.body10 {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */
  letter-spacing: normal;
}
.font400 {
  font-weight: 400;
}

.btnSizeLarge {
  display: inline-flex;
  align-items: center;
  height: 38px;
}
.btnSizeMedium {
  display: inline-flex;
  align-items: center;
  height: 30px;
}
.btnSizeSmall {
  display: inline-flex;
  align-items: center;
  height: 26px;
}
.btnSizeSX {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
}

.btnBase {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
}
.btnBase:focus {
  box-shadow: none !important;
  border: none !important;
}
.btnBaseSmall {
  padding: 4px 8px;
  gap: 4px;
  font-size: 10px;
  line-height: 12px;
}
.btnBaseMedium {
  padding: 6px 12px;
  gap: 4px;
  font-size: 12px;
  line-height: 16px;
}
.btnBaseLarge {
  padding: 8px 16px;
  gap: 6px;
  font-size: 16px;
  line-height: 20px;
}
.btnBase img {
  display: flex;
  width: 16px;
  height: 16px;
}

.btnBgTransparentTextPurple {
  color: #9f8aff;
  background: transparent;
}
.btnBgTransparentTextPurple:hover {
  color: #9f8aff;
  background: var(--deep-purple-25, rgba(134, 107, 255, 0.25));
}
.btnBgTransparentTextPurple:focus {
  color: #9f8aff;
  border: 1px solid var(--deep-purple-15, rgba(134, 107, 255, 0.15));
  background: var(--deep-purple-35, rgba(134, 107, 255, 0.35));
}

.btnBgDeepPurple {
  color: #fff;
  background: var(--deep-purple-5, #8c73ff);
}
.btnBgDeepPurple:hover {
  background: var(--deep-purple-4, #9f8aff);
}

.btnBgDeepPurple img {
  filter: invert(1);
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatarSmall {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}
.avatarMedium {
  height: 34px;
  min-height: 34px;
  width: 34px;
  min-width: 34px;
}
.avatar img {
  display: block;
  max-width: 100%;
}
.onClickRotate {
  transform: rotate(180deg);
}

.rightPanelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.rightPanelBody {
  height: calc(100vh - 130px);
  overflow: scroll;
}
.rightPanelSectionHeader h4 {
  color: #808080;
  margin-bottom: 10px;
}
.sectionEmpty {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.sectionEmpty .icon {
  opacity: 0.5;
}
.rightPanel .signature {
  font-size: 24px;
}
.rightPanelSection .labelText {
  width: 140px;
  min-width: 140px;
  font-weight: 600;
}
.tableHeaderOrderSummary,
.tableRowOrderSummary,
.tableRowOrder,
.tableHeaderOrder {
  grid-template-columns: 15px 2fr 50px 40px 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.tableRowOrder .cellFixHeight {
  height: 30px;
  min-height: 30px;
  max-height: 30px;
}
.adjustmentLink,
.adjustmentNo,
.reminderNo,
.action,
.paymentNo,
.productName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.tableRowPayments,
.tableHeaderPayments {
  grid-template-columns: 15px 30px 1fr 1fr 50px 50px 50px 1fr 24px 60px;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.tableHeaderPayments {
  align-items: flex-end;
}
.tableRowPaymentsReturn {
  background-image: linear-gradient(
    45deg,
    #fbf4d6 5.56%,
    #ffffff 5.56%,
    #ffffff 50%,
    #fbf4d6 50%,
    #fbf4d6 55.56%,
    #ffffff 55.56%,
    #ffffff 100%
  );
  background-size: 12.73px 12.73px;
}
.tableRowShipment,
.tableHeaderShipment {
  grid-template-columns: 15px 1.5fr 1fr 1fr 80px 80px;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.tableRowReminder,
.tableHeaderReminder {
  grid-template-columns: 15px 2fr 80px 40px 100px 80px;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.tableRowAdjustment,
.tableHeaderAdjustment {
  grid-template-columns: 15px 1fr 2fr 80px 80px 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.select {
  position: relative;
}
.select select {
  height: 38px;
  padding: 0px 5px;
  border-radius: 4px;
  border: 1px solid transparent;
  overflow: hidden;
}
.select select:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.select select:focus {
  outline: none;
}
.selectSmall select {
  height: 28px;
  font-size: 14px;
}
.select.hasAvatar .avatar {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}
.select.hasAvatar select {
  padding-left: 30px;
}

.tableRowPaymentsForm:hover {
  background: none;
}

.commentsSection .rightPanelSectionHeader h4 {
  margin: 0;
}
.commentsList {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  max-height: 120px;
  overflow: scroll;
}
.onClickRotate {
  transform: rotate(180deg);
}
/* Pagination =============== */
.pagination {
  background: var(--white-95, rgba(255, 255, 255, 0.95));
  position: absolute;
  bottom: 0;
  width: 100%;
}
.paginationPages {
  min-width: 90px;
  font-size: 12px;
  color: grey;
}
.paginationPages strong {
  color: #333;
}
.textgreyAB {
  color: rgba(0, 0, 0, 0.35);
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btnBgTransparentTextBlack svg {
  fill: black;
  background: transparent;
}
.btnBgTransparentTextBlack:hover {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}
.dropdown {
  position: absolute;
  left: -35px;
  font-size: 13px;
  background: transparent;
  display: none;
  background: rgba(134, 107, 255, 0.05);
  overflow: hidden;
  transition: 0.5s;
}

.dropdown.visible {
  display: block;
}
.dropdown img {
  width: 15px;
  height: 15px;
}
.nonfocusableLight:focus {
  box-shadow: none;
  border: none;
}
.nonfocusable:focus {
  border: none;
  background: none;
  box-shadow: none;
}

.avatarCover {
  margin-right: -4px;
}

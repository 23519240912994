.container {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 4px 4px;
  max-height: 51px;
  min-height: 51px;
  height: 51px;
  position: absolute;
  top: 11px;
  left: 9px;
  right: 9px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 8px;
}

.group {
  margin-right: 15px;
}

.label {
  font-size: 10px;
  color: #808080;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.label span {
  margin-left: 6px;
}
.label img {
  width: 15px;
  height: 15px;
}
.value {
  margin-left: 21px;
  font-size: 14px;
  /* color: #000; */
  white-space: nowrap;
  font-weight: 700;
}
.exceeded {
  color: #dc3545;
}
.hidden {
  display: none;
}
.icon {
  width: 16px;
  height: 16px;
}
.trimBtn {
  transform: rotate(180deg);
  display: flex;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  max-height: 20px;
  min-height: 20px;
}
.trimBtn:hover {
  opacity: 0.8;
  cursor: pointer;
}
.open {
  transform: rotate(0);
}

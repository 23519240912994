.opacityCover {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 3;
  background: #fff url(~assets/images/slash.png) repeat;
  opacity: 0.4;
}

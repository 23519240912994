.title {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #808080;
  width: 140px;
}

.container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
}

.bankAccount img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
}

.bankAccountDetails {
  white-space: nowrap;
}

.address {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 65%;
  white-space: nowrap;
}
.section {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05);
  max-width: 1280px;
  height: 100%;
  flex: 1;
}

.row {
  padding: 8px 25px 8px 15px;
  display: grid;
  grid-template-areas: "a a a a a a";
  grid-auto-columns: minmax(0, 20px) minmax(0, 1fr) minmax(0, 120px) minmax(0, 120px)
    minmax(0, 150px) minmax(0, 30px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
  column-gap: 10px;
  align-items: center;
}

.header {
  font-size: 12px;
  line-height: 15px;
  color: #696969;
}

.searchBarContainer {
  background: rgba(237, 240, 243, 0.5);
  width: 100%;
  padding: 15px;
}

.searchInput {
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

.searchBarList {
  width: 60%;
  background: #ffffff;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.15), 0px -1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px 0px 0px;
}

.summary {
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
}

.summary > div {
  width: 420px;
}

.summaryAmount {
  padding: 20px 15px;
  width: 100%;
  background: #e87076;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balancedSummary {
  background: #01c9d5;
}
.balancedSummaryOver {
  background: #d58d01;
}
.summaryPrice {
  padding: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalAmountNumber {
  color: #fff;
}

.totalAmountDecimal {
  color: rgba(255, 255, 255, 0.75);
}

.addBtn {
  background: rgba(0, 0, 0, 0.05);
  margin-left: 10px;
  border-radius: 4px;
  height: 22px;
  width: 24px;
}

.isOrderAdded {
  cursor: initial;
}
.isOrderAdded:hover {
  background-color: #fff;
}

.isOrderAdded * {
  color: #808080 !important;
}
.item {
  padding: 0;
}

.return {
  opacity: 1;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled * {
  cursor: not-allowed;
}

.disabled::after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.stripes {
  position: relative;
  background: #fff url(~assets/images/slash.png) repeat;
  opacity: 1;
}
.cover {
  opacity: 0;
}
.wrapper {
  transition: opacity 200ms;
}

.separator {
  display: inline-flex;
  width: 4px;
  min-width: 4px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 4px;
  margin: 3px 8px;
}

.btnContainer {
  padding: 16px 15px;
}
.smallButton {
  min-width: 120px;
  width: 120px;
  max-width: 120px;
}

.dropdownList {
  min-width: 120px;
  width: 120px;
  max-width: 120px;
}

.labelInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.labelRadiomark:hover {
  opacity: 0.8;
}
.labelRadiomark img {
  margin-right: 6px;
  height: 18px;
  width: 18px;
}
.labelRadiomark {
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 4px;
  font-size: 10px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  background: #ffffff1a;
  color: #fff;
  position: static;
}

.checked {
  background: #8c73ff;
  color: #fff;
}
.labelRadiomarkIcon {
  height: 28px;
  padding: 0 8px;
}
.labelOption {
  position: relative;
  cursor: pointer;
  margin-bottom: 4px;
}

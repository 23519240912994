.modal {
  min-height: 30vh;
  padding: 15px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.checkedNumberLabel {
  font-size: 12px;
  color: #808080;
}

.editedRow {
  background: var(--Blue-1, #e6f2fc);
}

.signature {
  font-weight: 500;
  font-size: 12px;
  min-width: 95px;
}

.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fixedRouteRow {
  min-height: 37px;
  height: 37px;
}

.priority {
  height: 38px;
  width: 100%;
  padding: 0px 5px;
  padding: 0px 5px 0 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15) transparent;
  background: transparent;
  overflow: hidden;
}

.prioritySmall {
  height: 28px;
  font-size: 14px;
  border: 1px solid transparent;
  width: max-content;
}

.priority:hover {
  border: 1px rgba(0, 0, 0, 0.15) solid;
}

.priority.hasMarker {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%); 
}

.optionList {
  position: absolute !important;
  z-index: 1;
  top: calc(100% + 4px);
  right: 0;
  min-width: max-content;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}

.tag {
  position: relative;
  cursor: pointer;
  font-size: 14px;
}

.tagInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.tagFill .tagInput:checked ~ .tagSelected {
  background: var(--deep-purple-1, #e7e1ff);
  border-color: transparent;
}
.tagFill .tagInput:checked ~ .tagSelected .checkMark {
  display: inline-flex;
}

.tagSelected {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 5px;
  font-size: 12px;
}

.priorityOrder {
  height: 38px;
}

.pageContainer {
  display: flex;
  height: 100vh;
  background: #f5f5f5;
}
.container {
  width: 500px;
  margin: 0 auto;
  padding: 0px 50px;
  position: relative;
}
.imageBox {
  width: 33%;
  max-width: 700px;
  overflow: hidden;
  position: relative;
}
.imageBox,
.formBox {
  height: 100%;
  min-height: fit-content;
}
.formBox {
  width: 67%;
  position: relative;
  z-index: 4;
}
.nav {
  top: 0;
}
.footer,
.nav {
  position: fixed;
  z-index: 2;
  width: 100%;
}
.imageBox:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(39, 39, 39, 0.55);
}
.imageBox img {
  height: 100%;
  width: auto;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  top: 0;
  min-height: 600px;
}
.footer {
  bottom: 0;
}
.footer img {
  display: block;
  height: 26px;
  margin-top: 4px;
}
.footer span {
  color: #ddd;
  font-size: 0.81rem;
}
.formBox h1 {
  font-weight: 700;
  font-size: 2.2rem;
  margin: 0 0 5px;
}
.formBox h5 {
  text-transform: uppercase;
  font-size: 1rem;
  color: #808080;
}
.header {
  font-size: 1.4rem;
  color: #666;
}
.inputBox {
  margin-bottom: 15px;
  position: relative;
}
.inputBox label {
  font-size: 12px;
  color: #666;
  background: #f6f8f9;
  padding: 0 2px;
  margin-left: 8px;
  position: relative;
  top: 10px;
}
.input {
  display: flex;
  width: calc(100% + 10px);
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 0;
  padding: 0 8px;
  background: transparent;
  cursor: pointer;
  height: 48px;
  font-size: 1rem;
}
.input:focus {
  border-color: #765ee3;
  outline: 0;
}
.link {
  color: #765ee3;
  font-size: 14px;
}
.link:hover {
  color: #6853ca;
  text-decoration: none;
}
.link:focus {
  color: #5a48af;
}

@-webkit-keyframes autofill {
  to {
    color: #333;
    background: transparent;
  }
}

.input:-webkit-autofill {
  -webkit-transition-delay: 99999s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.input::-webkit-input-placeholder {
  font-size: 1rem;
}
.input:-moz-placeholder {
  font-size: 1rem;
}
.input::-moz-placeholder {
  font-size: 1rem;
}
.input:-ms-input-placeholder {
  font-size: 1rem;
}

.pageContainer a {
  font-size: 0.81rem;
}

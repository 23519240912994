.inputWrapper {
  position: relative;
  width: 100%;
}
.inputBox {
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
}
.inputBox label {
  margin: 0;
}
.labelFloating {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 12px;
  color: #808080;
  padding: 2px;
  display: inline-flex;
  background: #fff;
  z-index: 1;
}
.editable {
  display: flex;
  width: calc(100% + 10px);
  align-items: center;
  border-radius: 5px;
  border: 1px solid #0000;
  margin: 0 -5px;
  padding: 0 5px;
  background: #0000;
  cursor: pointer;
}
.editable.focused,
.editable:not([disabled]):focus {
  padding: 5px;
  border-color: #765ee3;
  outline: 0;
  /* margin: 5px -5px; */
}

.common {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0 5px;
  background: transparent;
  cursor: pointer;
  height: 44px;
  width: 100%;
}
.common.focused,
.common:not([disabled]):focus {
  border-color: #765ee3;
  outline: 0;
}

/* Chrome, Safari, Edge, Opera */
.editable[type="number"]:not(:focus)::-webkit-outer-spin-button,
.editable[type="number"]:not(:focus)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  background-color: red;
}

/* Firefox */
.common[type="number"]:not(:focus) {
  -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
.common[type="number"]:not(:focus)::-webkit-outer-spin-button,
.common[type="number"]:not(:focus)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  background-color: red;
}

/* Firefox */
.common[type="number"]:not(:focus) {
  -moz-appearance: textfield;
}

.input:focus {
  border-color: #765ee3;
  outline: 0;
}
@keyframes autofill {
  to {
    color: #333;
    background: transparent;
  }
}

.input:-webkit-autofill {
  transition-delay: 99999s;
  -webkit-transition-delay: 99999s;
  animation-name: autofill;
  -webkit-animation-name: autofill;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
.lightVariant {
  color: #fff;
}

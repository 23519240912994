.body {
  margin: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  overflow: hidden;
}
.drawerBody {
  margin-top: 0;
}
.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.content {
  padding: 15px;
  background-color: #f6f8f9;
}
.formContainer {
  height: 100%;
  background-color: #f6f8f9;
  padding: 15px;
  margin-top: 70px;
}
.mapContent {
  padding: 15px;
  background-color: #f6f8f9;
  margin-top: 0;
}
.header {
  height: 70px;
  width: 100%;
  margin-bottom: 5px;
}
.drawerHeader {
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
}
.drawerBottom {
  height: 120px;
  width: 100%;
  margin-top: 10px;
  position: fixed;
  bottom: 0;
}
.options {
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
}
.optionsShort {
  width: calc(5% - 5px);
  min-width: 78px;
  margin-right: 5px;
}
.optionsMedium {
  width: calc(10% - 5px);
  min-width: 112px;
  margin-right: 5px;
}
.optionsLong {
  min-width: 148px;
  width: 15%;
}
.content {
  height: 100%;
  background-color: #f6f8f9;
}
.blocks {
  width: 440px;
  min-width: 380px;
  height: 100%;
  margin-right: 15px;
}
.rows {
  width: 100%;
  height: 100%;
  margin-right: 5px;
}
.sections {
  width: calc(65% - 15px);
  height: 100%;
  margin-right: 15px;
}
.drawerSections {
  width: 100%;
  height: 100%;
}
.strip,
.section,
.block,
.row {
  border-bottom: 5px solid #f6f8f9;
  width: 100%;
}
.block1 {
  height: 10vh;
}
.block2 {
  height: 20vh;
}
.block3 {
  height: 70vh;
  margin: 0;
}
.strip {
  width: 60px;
  height: 100vh;
}
.section1 {
  height: 300px;
}
.section2 {
  height: 165px;
}
.section3 {
  height: 220px;
}
.section4 {
  height: 270px;
}
.row {
  height: 65px;
  border-bottom: 5px solid #f6f8f9;
  width: 100%;
}
.panel {
  height: 100vh;
  width: 30%;
  min-width: 300px;
  max-width: 400px;
}
.drawer {
  height: 100vh;
  width: 515px;
  min-width: 515px;
}
@media (max-width: 1535px) {
  .drawer {
    min-width: 485px;
    max-width: 485px;
  }
}
@media (min-width: 1920px) {
  .drawer {
    min-width: 585px;
    max-width: 585px;
  }
}
.item {
  border-radius: 5px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eeeeee),
    color-stop(18%, #e1e1e1),
    color-stop(33%, #eeeeee)
  );
  background: linear-gradient(to right, #eeeeee 8%, #e1e1e1 18%, #eeeeee 33%);
  background-size: 200% 50px;
  -webkit-animation: preloadAnimation 2s infinite;
  animation: preloadAnimation 2s infinite;
}
.item.strip {
  border-radius: 5px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eeeeee),
    color-stop(18%, #e1e1e1),
    color-stop(33%, #eeeeee)
  );
  background: linear-gradient(to right, #eeeeee 8%, #e1e1e1 18%, #eeeeee 33%);
  background-size: 800% 250px;
  -webkit-animation: preloadAnimation 2s infinite;
  animation: preloadAnimation 2s infinite;
}

@-webkit-keyframes preloadAnimation {
  from {
    background-position: -280px 0;
  }
  to {
    background-position: 300px 0;
  }
}

@keyframes preloadAnimation {
  from {
    background-position: -280px 0;
  }
  to {
    background-position: 300px 0;
  }
}

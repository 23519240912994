.field {
  cursor: pointer;
  background-color: rgb(38 38 38);
  z-index: 1;
  position: absolute;
  border-width: 2px;
  border-style: dashed;
}

.field:hover {
  border: 20px solid var(--Deep-Purple-5, #8c73ff);
}

.wave {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.waveItem {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0%;
  position: absolute;
  opacity: 1;
}

.waveTopToBottom {
  transform: translateY(-120%);
  animation: waveTopToBottom 1200ms linear infinite;
}
@keyframes waveTopToBottom {
  0% {
    transform: translateY(-120%);
  }
  100% {
    transform: translateY(120%);
  }
}
.waveBottomToTop {
  transform: translateY(120%);
  animation: waveBottomToTop 1200ms linear infinite;
}
@keyframes waveBottomToTop {
  0% {
    transform: translateY(120%);
  }
  100% {
    transform: translateY(-120%);
  }
}

.waveLeftToRight {
  transform: translateX(-120%);
  animation: waveLeftToRight 1200ms linear infinite;
}
@keyframes waveLeftToRight {
  0% {
    transform: translateX(-120%);
  }
  100% {
    transform: translateX(120%);
  }
}

.waveRightToLeft {
  transform: translateX(120%);
  animation: waveRightToLeft 1200ms linear infinite;
}
@keyframes waveRightToLeft {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(-120%);
  }
}

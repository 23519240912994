.group-label {
  font-weight: 500;
  font-size: 0.75rem;
}

.group-label span {
  margin-left: 10px;
}
.group-label img {
  filter: contrast(0%) brightness(100%);
}
.inputBox {
  margin-left: 21px;
}
.input {
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 5px 5px;
}

.input:hover {
  border-color: #ddd;
}
.input:focus {
  border: 1px solid #765ee3;
  outline: 0;
}
.datePickerPopup {
  left: -65px !important;
}

.container {
  position: relative;
  display: inline-flex;
  cursor: pointer;
}

.checkContainer {
  position: relative;
}

.light-unchecked {
  border: 1px solid var(--neutralBlack20);
  border-radius: 4px;
  cursor: pointer;
}
.light-unchecked:hover {
  border: 1px solid var(--deepPurple200);
  box-shadow: 0px 0px 0px 4px #e2dcff;
}
.light-unchecked:active {
  border: 1px solid var(--deepPurple200);
  box-shadow: 0px 0px 0px 4px #b7a8fc !important;
}

.dark-unchecked {
  border: 1px solid var(--neutralWhite20);
  border-radius: 4px;
  cursor: pointer;
}
.dark-unchecked:hover {
  border: 1px solid var(--deepPurple400);
  box-shadow: 0px 0px 0px 4px #7357ff40;
}
.dark-unchecked:active {
  border: 1px solid var(--deepPurple400);
  box-shadow: 0px 0px 0px 4px #7357ff52 !important;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.light-checkedOrIndeterminate {
  background: var(--deepPurple500);
  border-radius: 4px;
  cursor: pointer;
}
.light-checkedOrIndeterminate:hover {
  box-shadow: 0px 0px 0px 4px #e2dcff, 0px 2px 4px 0px #110c221f;
}
.light-checkedOrIndeterminate:active {
  box-shadow: 0px 0px 0px 4px #b7a8fc, 0px 2px 4px 0px #110c221f;
}

.dark-checkedOrIndeterminate:is(.disabled) {
  background: var(--deepPurple200) !important;
  opacity: 1;
}
.dark-checkedOrIndeterminate {
  background: var(--deepPurple500);
  border-radius: 4px;
  cursor: pointer;
}
.dark-checkedOrIndeterminate:hover {
  box-shadow: 0px 0px 0px 4px #7357ff40, 0px 2px 4px 0px #110c221f;
}
.dark-checkedOrIndeterminate:active {
  box-shadow: 0px 0px 0px 4px #7357ff52, 0px 2px 4px 0px #110c221f;
}

.sx-check::after {
  position: absolute;
  content: "";
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  left: 4.88px;
  top: 2.63px;
  height: 7.98px;
  width: 4.61px;
  transform: rotate(45deg);
}
.sm-check::after {
  position: absolute;
  content: "";
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  width: 6.48px;
  height: 10.61px;
  left: 7px;
  top: 3.5px;
  transform: rotate(45deg);
}
.md-check::after {
  position: absolute;
  content: "";
  border-width: 0 3px 3px 0;
  border-radius: 1px;
  width: 7.48px;
  height: 12.31px;
  left: 9.17px;
  top: 4.64px;
  transform: rotate(45deg);
}

.sx-dash::after {
  position: absolute;
  content: "";
  border-radius: 1px;
  width: 6px;
  height: 1.5px;
  top: 6.25px;
  left: 4px;
}
.sm-dash::after {
  position: absolute;
  content: "";
  border-radius: 1px;
  width: 10px;
  height: 2px;
  top: 9px;
  left: 5px;
}
.md-dash::after {
  position: absolute;
  content: "";
  border-radius: 1px;
  width: 12px;
  height: 2px;
  top: 11px;
  left: 6px;
}

.fill::after {
  border: solid var(--neutralWhite100);
  border-width: 0 2px 2px 0;
}

.input {
  display: none;
  opacity: 0;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  min-height: 16px;
  height: 100%;
}

.helperText {
  font-style: italic;
}

.sx {
  height: 14px;
  width: 14px;
}

.sm {
  height: 20px;
  width: 20px;
}

.md {
  height: 24px;
  width: 24px;
}

.text {
  display: flex;
  flex-direction: column;
}

.sidebar {
  overflow-y: auto;
  position: sticky;
  top: 0;
}

.content {
  flex: 1;
  overflow-y: auto;
}

.sectionMenuHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 8px 0px 8px 12px;
  height: 34px;
}

.sidebarSubsectionWrapper {
  padding-left: 45px;
  cursor: pointer;
}

.sidebarSubsection {
  border-left: 1px solid var(--neutralBlack16);
  padding: 4px 0px 4px 12px;
}

.activeSidebarSubsection {
  border-left: 1px solid var(--deepPurple400);
  background-color: var(--deepPurple12);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.contentHeader {
  position: sticky;
  top: 0;
  z-index: 20;
  padding-bottom: 10px;
  background-color: var(--neutralWhite100);
  box-shadow: 0 2px 2px rgba(214, 214, 214, 0.2);
}

.subsection {
  position: relative;
  padding-bottom: 10px;
  scroll-margin-top: 50px;
}

.spacer {
  height: 70vh;
}

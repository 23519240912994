.row {
  border-top: 1px solid var(--neutralBlack16);
  border-bottom: 1px solid var(--neutralBlack16);
}

.productName {
  position: absolute;
  top: 0;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  height: 27px;
}

.productName div {
  display: inline;
  line-height: 14px;
}

.progressContainer {
  background-color: var(--neutralBlack16);
  min-height: 26px;
  min-width: 26px;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: linear-gradient(90deg, #0001 33%, #0005 50%, #0001 66%) #f2f2f2;
  background-size: 300% 100%;
  animation: l1 1s infinite linear;
}

@keyframes l1 {
  0% {
    background-position: right;
  }
}

.routeBar {
  padding: 4px 15px;
  border-bottom: 1px solid var(--neutralBlack12);
  background-color: var(--neutralWhite100);
}

.search {
  width: 300px;
}

.text {
  min-width: 62px;
}

.border {
  background-color: var(--neutralBlack16);
  width: 1px;
  height: 100%;
  position: absolute;
  right: 8px;
  top: 0;
}

.borderHeader {
  right: 7px;
  top: -4px;
  height: calc(100% + 8px);
}

.separator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -2px;
}

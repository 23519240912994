.dropFileArea {
  min-height: 112px;
  height: 112px;
  border: 1px dashed var(--neutralBlack12);
  border-radius: 4px;
}

.dropFileAreaDraggingOver {
  background-color: var(--info25);
  border: 1px dashed var(--info500);
}

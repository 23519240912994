.overlay {
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}

.overlay h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.noBackground {
  background: none;
}

.content {
  border-radius: 8px;
  background: var(--neutralWhite100);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  margin-top: 5vh;
}

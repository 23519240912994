.addProductIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
}
.addProductIcon img {
  display: block;
  max-width: 100%;
}

.axisText text {
  font-family: "Sofia Sans", sans-serif !important;
  fill: var(--grey700);
  font-weight: 400;
  font-size: 11px;
}

.axisText line {
  display: none;
}

.tab {
  margin-right: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  color: #808080;
  font-size: 14px;
}
.borderActive {
  border-bottom: 1px solid #01c9d5;
  color: #01c9d5;
}

.indexCode {
  display: inline-block;
  font-size: 0.7rem;
  padding: 0px 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #757575;
  margin-right: 10px;
}
.detailsContainer {
  min-height: 300px;
}
.package {
  border-bottom: 1px dotted #757575;
  padding: 15px 0;
}

.modalItemsContainer {
  padding: 15px 0;
  display: grid;
  grid-gap: 10px;
  grid-template-areas: "a a a a a";
  align-items: center;
  grid-auto-columns: minmax(0, 1fr) minmax(0, 75px) minmax(0, 200px) minmax(0, 140px)
    minmax(0, 170px);
  border-bottom: 1px dotted #757575;
}
.completeImg {
  width: 20px;
  height: 20px;
}
.modalWrapper {
  min-height: 100px;
}

.btnReady {
  background-color: #01c9d5;
  color: #fff;
  border-color: #01c9d5;
}
.btnReady img {
  filter: contrast(0%) brightness(200%);
}
.documentSignature {
  margin-right: 10px;
  color: #212529;
  font-size: 16px;
}
.documentSignature:hover {
  color: #01c9d5;
}

.slots {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  gap: 4px;
  overflow-x: scroll;
  width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  height: 36px;
}
.slots::-webkit-scrollbar {
  display: none;
}
.slot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 10px;
  letter-spacing: normal;
}
.slotPlaceholder {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.marginRightSlot {
  margin-left: 12px;
}

.addSlotBtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 2px;
  width: 16px;
  height: 16px;
  margin: 0 6px;
}
.addSlotBtn img {
  display: block;
  max-width: 20px;
}

.attributes {
  gap: 2px;
  display: flex;
  width: 100%;
  align-items: center;
}

.attributesWithCounter {
  max-width: calc(100% - 20px);
}

.tooltipAttributes {
  gap: 2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.inputBox {
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  margin-left: -5px;
}
.button {
  border: 1px solid transparent !important;
  background-image: none !important;
}
.button:hover {
  border: 1px solid #ccc !important;
  background: url(~assets/images/18.svg) no-repeat !important;
  background-position-x: calc(100% - 10px) !important;
  background-position-y: center !important;
}
.disabled {
  border: 1px solid transparent !important;
  background-image: none !important;
}
.disabled:hover {
  cursor: default;
  border: none;
}

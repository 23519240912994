.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.fill .input:checked ~ .radiomark {
  background: #765ee3;
  color: #fff;
}
.frame .input:checked ~ .radiomark {
  border: 1px solid #765ee3;
}

.newInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.fill .newInput:checked ~ .radiomark {
  background: rgb(231, 225, 255);
  color: rgb(33, 37, 41);
  border: 1px solid rgb(231, 225, 255);
}
.frame .newInput:checked ~ .radiomark {
  border: 1px solid rgb(231, 225, 255);
}

.frame .radiomark {
  border: 1px solid transparent;
  padding: 1px 5px;
}

.radiomark {
  z-index: 1;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  background: #ddd;
  height: inherit;
  position: static;
}
.fieldsetLabel {
  color: #999;
  font-size: 0.81rem;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.2;
}
.label {
  position: relative;
  cursor: pointer;
  font-size: 0.81rem;
  margin-bottom: 4px;
}
.disabled {
  opacity: 0.4;
  cursor: default;
}

.address {
  margin-right: 20px;
}
.leftSeparated {
  padding-left: 20px;
  border-left: 1px solid #ddd;
}
.instruction {
  font-size: 0.9rem;
  color: #808080;
}
.instruction strong {
  color: #333;
}
.label {
  color: #808080;
  font-size: 0.81rem;
}
.localizedAddress {
  border-radius: 4px;
  border: 1px solid rgb(1, 201, 213);
  background: rgb(126, 230, 236);
}
.bgGreen {
  background: rgb(1, 201, 213) !important;
}
.bgGreen:hover {
  background: rgb(2, 218, 230) !important;
}
.bgGreen:hover {
  background: rgb(2, 170, 179) !important;
}

.messageDeliveryStatus {
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 10px;
}
.messageListWrapper {
  max-height: 320px;
  overflow: scroll;
  background: var(--black-3, rgba(0, 0, 0, 0.03));
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cloud {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
}
.cloudClient {
  background: var(--black-5, rgba(0, 0, 0, 0.05));
}
.cloudClient:last-of-type,
.cloudClient:nth-of-type(n) {
  border-radius: 2px 15px 15px 2px;
}
.cloudClient:first-of-type,
.cloudClient:only-of-type {
  border-radius: 15px 15px 15px 2px;
}
.cloudMilo {
  background: var(--Deep-Purple-1, #e7e1ff);
  color: var(--Deep-Purple-5, #8c73ff);
  text-align: right;
}
.cloudMilo:last-of-type,
.cloudMilo:nth-of-type(n) {
  border-radius: 15px 2px 2px 15px;
}
.cloudMilo:first-of-type,
.cloudMilo:only-of-type {
  border-radius: 15px 15px 2px 15px;
}

.errorMsg {
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  padding: 4px;
  color: #ce656b;
  background: rgba(232, 112, 118, 0.35);
  border-radius: 4px;
  margin-bottom: 6px;
}
.errorMsg img {
  margin-right: 6px;
}

.error .errorMsg {
  display: inline-flex;
}

.customerConfirmation {
  background: var(--success100);
  color: var(--success700) !important;
}

.radioContainer {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  gap: 8px;
}

.radioBase-sm {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.radioBase-md {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.radioBase-light-not-checked {
  position: relative;
  background: transparent;
  border: 1px solid var(--neutralBlack20);
}

.radioBase-light-not-checked:hover:is(:not(.disabled)) {
  border: 1px solid var(--deepPurple200);
  box-shadow: 0px 0px 0px 4px #E2DCFF;
}
.radioBase-light-not-checked:active:is(:not(.disabled)) {
  border: 1px solid var(--deepPurple200);
  box-shadow: 0px 0px 0px 4px #B7A8FC;
}

.radioBase-light-checked {
  position: relative;
  background: var(--deepPurple500);
}
.radioBase-light-checked:hover:is(:not(.disabled)) {
  border: 1px solid var(--deepPurple500);
  box-shadow: 0px 0px 0px 4px #E2DCFF, 0px 2px 4px 0px #110C221F;
}
.radioBase-light-checked:active:is(:not(.disabled)) {
  border: 1px solid var(--deepPurple500);
  box-shadow: 0px 0px 0px 4px #E2DCFF, 0px 2px 4px 0px #110C221F;
}

.radioBase-dark-not-checked {
  position: relative;
  background: transparent;
  border: 1px solid var(--neutralWhite20);
}
.radioBase-dark-not-checked:hover:is(:not(.disabled)) {
  border: 1px solid var(--deepPurple200);
  box-shadow: 0px 0px 0px 4px #7357FF40;
}
.radioBase-dark-not-checked:active:is(:not(.disabled)) {
  border: 1px solid var(--deepPurple200);
  box-shadow: 0px 0px 0px 4px #7357FF52;
}

.radioBase-dark-checked {
  position: relative;
  background: var(--deepPurple500);
  border: 1px solid var(--deepPurple500);
}
.radioBase-dark-checked:is(.disabled) {
  border: 1px solid var(--deepPurple200);
  background: var(--deepPurple200);
  opacity: 1;
}
.radioBase-dark-checked:hover:is(:not(.disabled)) {
  border: 1px solid var(--deepPurple500);
  box-shadow: 0px 0px 0px 4px #7357FF40, 0px 2px 4px 0px #110C221F;
}
.radioBase-dark-checked:active:is(:not(.disabled)) {
  border: 1px solid var(--deepPurple500);
  box-shadow: 0px 0px 0px 4px #7357FF52, 0px 2px 4px 0px #110C221F;
}

.checkedRadioInnerDot-sm {
  background: var(--neutralWhite100);
  border-radius: 50%;
  height: 8px;
  position: absolute;
  width: 8px;
  left: 30%;
  top: 30%;
}

.checkedRadioInnerDot-md {
  background: var(--neutralWhite100);
  border-radius: 50%;
  height: 10px;
  position: absolute;
  width: 10px;
  left: 29%;
  top: 29%;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  position: relative;
}

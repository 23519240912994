.headingPrimary {
  margin: 0 auto;
  font-size: 1.6rem;
  color: #333;
  margin-top: 30px;
  text-align: center;
}
.headingSecondary {
  font-size: 1rem;
  color: #333;
  margin: 0;
  text-align: center;
}
.paragraph {
  font-size: 0.9rem;
  color: #808080;
  margin: 0;
  text-align: center;
}

.importantParagraph {
  font-size: 20px;
  margin: 0;
  text-align: center;
}

.bodyInner {
  display: flex;
  height: 100%;
}
.main {
  width: -webkit-fill-available;
  width: -moz-available;
  overflow: hidden;
}
.contentWrapper {
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
  height: 100%;
}
.dateFrame {
  background: #323138;
  border-radius: 5px;
  padding: 20px;
  margin: 20px auto;
}
.value {
  margin-left: 22px;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 400;
}
.value em {
  font-style: normal;
  color: #ddd;
  width: 100%;
  display: block;
  font-size: 0.9rem;
}
.label {
  color: #808080;
  font-weight: 500;
  font-size: 0.75rem;
}
.link {
  color: #01c9d5;
}
.labelIcon {
  width: 20px;
  margin-right: 5px;
}
.labelIcon img {
  filter: contrast(0) brightness(1);
}
.confirmButton {
  margin-bottom: 10px;
}
.confirmButton img {
  margin-right: 10px;
  filter: contrast(0.5) brightness(2.5);
}
.bigIcon {
  width: 180px;
  height: 180px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .value {
    font-size: 1.2rem;
  }
}
.delivery {
  font-weight: 400;
  font-size: 1rem;
}

.deliveryLabel {
  font-weight: 500;
  font-size: 1.2rem;
  padding-bottom: 8px;
}
.deliveryName {
  font-size: 1.8rem;
  font-weight: 700;
}
.bigLabel {
  font-weight: 900;
  font-size: 1.45em;
}
.desc,
.item,
.amount {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 1.15rem;
}
.item {
  margin-bottom: 0;
}

.desc > div {
  margin-bottom: 14px;
}

.desc b {
  font-weight: bold;
  color: #e87076;
  font-size: 18px;
}

.rightPanelSectionHeader h4 {
  color: #808080;
  margin-bottom: 10px;
}

.sectionEmpty {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  letter-spacing: -0.03rem;
  color: #212529;
  font-family: "Sofia Sans", sans-serif;
}
.sectionEmptyTitle {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: normal;
}
.sectionEmpty .icon {
  opacity: 0.5;
}

.btn {
  position: relative;
  display: flex;
  min-height: 26px;
  padding: 1px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  width: 100%;
  min-width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.tableRow:hover {
  background: #edf0f9;
}
.tableRow:active /* on click */ {
  background: #d9e0f1;
}
.tableRow {
  display: grid;
  grid-template-columns: 2fr 120px 1fr 90px 90px 120px 70px 90px 70px 70px 1fr;
  padding: 4px 15px;
  gap: 10px;
}
.tableRow .date {
  font-weight: 500;
  font-size: 12px;
}
.list .tableRow {
  font-size: 12px;
}
.tableRow div {
  position: relative;
}
.reminderNo,
.action,
.paymentNo,
.productName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.date {
  color: #808080;
}
.search .result,
.date {
  font-size: 12px;
  line-height: 16px;
}
.list .signature,
.subject,
.tableRow .date {
  font-weight: 500;
  font-size: 12px;
}

.tableRowAdjustment,
.tableHeaderAdjustment {
  grid-template-columns: 15px 1fr 2fr 80px 80px 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}
.tableHeader,
.tableRow {
  padding: 4px 15px;
  gap: 10px;
  display: grid;
  grid-template-columns: 2fr 120px 1fr 90px 90px 120px 70px 90px 70px 70px 1fr;
  padding: 4px 15px;
  gap: 10px;
}
.tableRowReminder,
.tableHeaderReminder {
  grid-template-columns: 15px 2fr 80px 40px 100px 80px;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.btnSizeSmall {
  display: inline-flex;
  align-items: center;
  height: 26px;
}
.btnBgBlack5 {
  color: #333;
  background: var(--black-5, rgba(0, 0, 0, 0.05));
}
.btnBgBlack5:hover {
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}
.btnBgBlack5:focus {
  border: 1px solid var(--black-5, rgba(0, 0, 0, 0.05));
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}
.uppercase {
  text-transform: uppercase;
}

.btnBase img {
  display: flex;
  width: 16px;
  height: 16px;
}
h5,
h6 {
  margin: 0;
}
h5 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: normal;
}

.modalForm {
  height: fit-content;
  max-height: 75vh;
  overflow: scroll;
}

.section {
  padding: 15px 15px 0px 15px;
}

.errorMessage {
  color: var(--danger400);
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 0px 15px 0px 0px;
}

.searchItem {
  height: 50px;
  border-radius: 8px;
}

.searchBar input {
  border-radius: 4px !important;
}

.commonAttributes {
  background: #e3ddff;
}

.statuses {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.status {
  background: #333333;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  margin-right: 10px;
}

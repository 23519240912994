.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  color: #fff;
  margin: 9px 0;
  overflow: hidden;
  font-size: 0.8rem;
  font-weight: bold;
  background: rgb(162 162 162 / 30%) url(~assets/images/3.svg) no-repeat center;
  backdrop-filter: contrast(0) brightness(1.85);
  flex-shrink: 0;
}
.avatar img {
  display: block;
  max-width: 100%;
}
.initialsAvatar {
  background: none;
}

.avatarSize {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.employeesList {
  max-height: 50vh;
  overflow: scroll;
}
.employeeRow {
  border-top: 1px solid #ddd;
}
.assignedEmployeeRow {
  background-color: rgb(249, 247, 247);
  border-top: 1px solid #ddd;
}
.assignedEmployeeRow:hover {
  cursor: not-allowed;
}
.checkbox {
  margin-right: 5px;
}
.checked {
  margin-right: 15px;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatarSmall {
  height: 26px;
  min-height: 26px;
  width: 26px;
  min-width: 26px;
  margin: 0;
}
.avatar img {
  display: block;
  max-width: 100%;
}

.checkedNumberLabel {
  font-size: 12px;
  color: #808080;
}

.discountInput {
  width: 90px;
}

.daysInput {
  width: 140px;
}

.addDeliveryBtn {
  position: absolute;
  top: -5px;
  right: 0;
}

.addressCard {
  min-width: 240px;
  border: 1px solid var(--neutralBlack12);
  border-radius: 4px;
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.defaultAddressCard {
  border: 1px solid var(--success400);
}

.defaultCheckbox {
  border-top: 1px solid var(--neutralBlack12);
}

.cardDetails {
  width: 180px;
}

.addressToConfirm {
  border: 1px solid var(--success400);
  border-radius: 4px;
}

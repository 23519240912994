.pingLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  gap: 0.25rem;
}

.diode {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 6px;
}

.onTrack {
  color: var(--Teal-6, #55bca6);
  background: var(--teal-10, rgba(65, 148, 136, 0.1));
}
.onTrack .diode {
  background: var(--Teal-6, #55bca6);
  box-shadow: 0 0 0 0 #55bca6;
  -webkit-animation: greenDioda 2s infinite;
  animation: greenDioda 2s infinite;
}

.atRisk {
  color: var(--Yellow-6, #dcaf3c);
  background: var(--yellow-10, rgba(246, 198, 2, 0.1));
}
.atRisk .diode {
  background: var(--Yellow-6, #dcaf3c);
  box-shadow: 0 0 0 0 #dcaf3c;
  -webkit-animation: yellowDioda 2s infinite;
  animation: yellowDioda 2s infinite;
}

.offTrack {
  color: var(--Red-7, #c23f38);
  background: var(--red-10, rgba(225, 82, 65, 0.1));
}
.offTrack .diode {
  background: var(--Red-7, #c23f38);
  box-shadow: 0 0 0 0 #c23f38;
  -webkit-animation: redDioda 2s infinite;
  animation: redDioda 2s infinite;
}

.lastEdited {
  background: var(--indigo-10, rgba(57, 101, 255, 0.1));
  color: var(--Indigo-6, #3d55dc);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-height: 24px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  gap: 0.25rem;
}
.lastEdited .diode {
  background: var(--Indigo-6, #3d55dc);
  box-shadow: 0 0 0 0 #3d55dc;
  -webkit-animation: indigoDioda 0.75s infinite;
  animation: indigoDioda 0.75s infinite;
}

.bg-black5 {
  background: var(--black-5, rgba(0, 0, 0, 0.05));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-height: 24px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  gap: 0.25rem;
}
.bg-black5 .diode {
  background: var(--black-35, rgba(0, 0, 0, 0.35));
}

@keyframes greenDioda {
  0% {
    box-shadow: 0 0 0 0 rgba(85, 188, 166, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(85, 188, 166, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(85, 188, 166, 0);
  }
}
@keyframes yellowDioda {
  0% {
    box-shadow: 0 0 0 0 rgba(220, 175, 60, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(220, 175, 60, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(220, 175, 60, 0);
  }
}
@keyframes redDioda {
  0% {
    box-shadow: 0 0 0 0 rgba(194, 63, 56, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(194, 63, 56, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(194, 63, 56, 0);
  }
}
@keyframes indigoDioda {
  0% {
    box-shadow: 0 0 0 0 rgba(61, 85, 220, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(61, 85, 220, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(61, 85, 220, 0);
  }
}

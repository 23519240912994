.pulse {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transform-origin: center;
  animation: pulse-animation 1.5s infinite;
  border-radius: 50%;
}

@keyframes pulse-animation {
  0% {
    transform: scale(0.1);
    opacity: 0.7;
  }

  80% { 
  opacity: 0.05;
 }

  100% {
    transform: scale(1.4);
    opacity: 0.03;
  }
}

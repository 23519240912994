.search {
  position: relative;
  display: flex;
  width: 600px;
  min-width: 450px;
  padding: 5px 0px;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1500px) {
  .search {
    min-width: 300px;
  }
}
.search input[type="search"] {
  display: flex;
  height: 38px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: url(../../../assets/images/search.svg) no-repeat 98% center;
}
.search input[type="search"]:focus {
  outline: none;
  border: 1px solid #856afe;
  background: url(../../../assets/images/search.svg) no-repeat 98% center;
}
.searchList {
  position: absolute;
  top: calc(100% - 8px);
  left: 0;
  width: 100%;
  max-height: 460px;
  overflow: auto;
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.heightWithoutData {
  height: 100px;
}

.searchListLoaderWithFilters {
  min-height: 15vh;
}

.searchListLoaderWithoutFilters {
  min-height: 10vh;
}

.resetBtn {
  position: absolute;
  right: 30px;
}

.tag {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}
.tagInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.checkMark {
  display: none;
}
.tagSelected {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 5px;
  font-size: 12px;
}
.tagFill {
  border-color: transparent;
}
.tagFill {
  display: inline-flex;
}

.tagFillActive {
  background: var(--deep-purple-1, #e7e1ff);
}

.tagChecked {
  background: var(--deep-purple-1, #e7e1ff);
  border-color: transparent;
  display: inline-flex;
  border-radius: 4px;
}

.checkMarkActive {
  display: inline-flex;
}

.searchResult {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.searchResult:hover {
  background: var(--black-5, rgba(0, 0, 0, 0.05));
}
.searchResultActive {
  background: var(--black-5, rgba(0, 0, 0, 0.05));
}
.filterChip {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 1px 3px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 2px;
}
.filterChip img {
  display: block;
  height: 14px;
  width: 14px;
  min-width: 14px;
}
.labelText {
  font-size: 12px;
  line-height: 12px;
  color: #808080;
}
.date {
  color: #808080;
}
.search .result,
.date {
  font-size: 12px;
  line-height: 16px;
}

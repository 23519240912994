.previousDayContainer {
  background: #E8707659;
  color: #C95D62;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #E8707659;
}

.previousDayActive {
  background: #cd010b59;
  border: 2px solid #C95D62;
}

.previousDayInPlan {
  background: #cd010b59;
  border: 2px solid #C95D62;
  cursor: not-allowed;
}

.nextDayContainer {
  background: #856AFE59;
  color: #604CBB;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #856AFE59;
}

.nextDayActive {
  background: #2702cf59;
  border: 2px solid #604CBB;
}

.nextDayInPlan {
  background: #2702cf59;
  border: 2px solid #604CBB;
  cursor: not-allowed;
}

.tableWrapper {
  height: 72vh;
  min-height: 72vh;
  max-height: 72vh;
}
.filterWrapper {
  display: flex;
  align-items: center;
  padding: 4px 15px 8px;
  gap: 16px;
}

.select {
  width: 154px;
  max-width: 154px;
  min-width: 154px;
}

.dropdownList {
  width: 154px;
  max-width: 154px;
  min-width: 154px;
}

.input {
  font-size: 14px;
  font-family: "Sofia Sans", sans-serif;
  font-weight: 700;
  height: 28px !important;
}
.wrapper {
  width: 224px;
  max-width: 224px;
  min-width: 224px;
}

.attribute {
  padding: 0px 2px;
  border: 1px solid var(--neutralBlack12);
  background-color: var(--neutralWhite100);
  border-radius: 2px;
  line-height: 14px;
  max-width: 100%;
  max-height: 14px;
  gap: 4px;
  min-width: min-content;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.attributesContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  max-height: 34px;
  overflow: hidden;
}

.moreDataCounter {
  position: relative;
  transform: translateY(-5%);
}
